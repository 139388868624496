import axios from "axios";
require("dotenv").config();

const apiEndPoint = `${process.env.REACT_APP_API_URL}/autogate`;

export async function getBoatStatus(boatId) {
  try {
    const response = await axios.get(`${apiEndPoint}/status/${boatId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}
