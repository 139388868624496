import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ModalDialogDelete from "../../geral/ModalDialogDelete";

class UsersRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectPage: false,
      modalDelete: false,
      showMe: true
    };
  }

  handleClose = id => {
    this.setState({ showMe: false });
    this.props.handleDelete(id);
  };

  handleChildUnmount = () => {
    this.setState({
      modalDelete: false
    });
  };

  processDelete = (id, title) => {
    this.setState({
      modalDelete: (
        <ModalDialogDelete
          name={title}
          idRow={id}
          unmountMe={this.handleChildUnmount}
          deleteMe={() => this.handleClose(id)}
        />
      )
    });
  };

  handleEdit = id => {
    this.setState({
      redirectPage: <Redirect to={`/utilities/users/${id}`} />
    });
  };

  render() {
    const { id, firstName, lastName, emailAddress, status } = this.props.data;

    if (this.state.showMe === false) {
      return false;
    }
    return (
      <tr key={id}>
        {this.state.redirectPage}
        {this.state.modalDelete}
        <td className="">{firstName}</td>
        <td className="">{lastName}</td>
        <td className="">{emailAddress}</td>
        <td className="">{status === 1 ? "Active" : "Blocked"}</td>

        <td className="">
          <i
            className="icon ion-edit tx-20 pointer"
            onClick={() => this.handleEdit(id)}
          />
          <i
            className="icon ion-close tx-20 mg-l-20 pointer"
            onClick={() => this.processDelete(id, `${firstName} ${lastName}`)}
          />
        </td>
      </tr>
    );
  }
}

export default UsersRow;
