import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div className="d-flex align-items-center justify-content-between mg-b-30 pd-x-30 pd-t-30">
        <footer className="br-footer">
          {/*<div className="footer-left">
            <div className="mg-b-2">
              Copyright &copy; 2019. Boat Sync. All Rights Reserved.
            </div>
    </div>*/}
        </footer>
      </div>
    );
  }
}

export default Footer;
