import React, { Component } from "react";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";

import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { setTimeout } from "timers";

const axios = require("axios");
require("dotenv").config();

class SortImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AlertMessage: "",
      selectedFiles: null,
      uploadButton: "",
      rowImages: "",
      mylist: ""
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.updateitem = this.updateitem.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }

  updateitem(list) {
    this.setState({ mylist: list });
  }

  componentDidMount() {
    this.handleRowImages();
  }

  handleRowImages = () => {
    var imgRows = [];
    var self = this;
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}boat-upload/${self.props.idBoat}/${this.props.sortfield}`
    }).then(function(response) {
      if (response.data.success === false) {
        self.setState({
          rowImages: <p className="m-3 text-danger">No images!</p>
        });
        return false;
      }
      for (let i = 0; i < response.data.data.results.length; i++) {
        imgRows.push(
          <img
            srcSet={`${process.env.REACT_APP_PUBLIC_FOLDER}/images/${response.data.data.results[i].boatId}/${response.data.data.results[i].thumbName}`}
            alt={response.data.data.results[i].thumbName}
            width="150px"
            id={response.data.data.results[i].id}
          />
        );
      }

      self.setState({
        rowImages: (
          <SortableComponent item={imgRows} updateitem={self.updateitem} />
        )
      });
    });
  };

  saveNewOrder = () => {
    var self = this;
    var data = [this.props.sortfield, this.state.mylist];

    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}boat-upload/sort/${self.props.idBoat}`,
      data: data
    }).then(response => {
      self.setState({
        AlertMessage: (
          <AlertMessageBootstrap
            messageType="success"
            message="Data successfully updated!"
            unmountMe={this.handleCloseAlert}
          />
        )
      });
    });
  };

  render() {
    return (
      <div style={{ width: "98%" }}>
        <h4 className="mt-3">{this.props.title}</h4>
        <div className="border border-info uploadFileRow ">
          {this.state.rowImages}

          <hr />
          <button
            type="button"
            className="btn btn-primary ml-3 mb-3"
            onClick={() => this.saveNewOrder()}
          >
            Save
          </button>
          {this.state.AlertMessage}
        </div>
      </div>
    );
  }
}

class SortableComponent extends Component {
  state = {
    items: this.props.item
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex)
    }));
    setTimeout(() => {
      var newSort = [];
      for (let i = 0; i < this.state.items.length; i++) {
        newSort.push(this.state.items[i].props.id);
      }
      setTimeout(() => {
        // console.log("newSort", newSort);
        this.props.updateitem(newSort);
      }, 50);
    }, 50);
  };
  render() {
    return (
      <div>
        <SortableList
          axis="xy"
          items={this.state.items}
          onSortEnd={this.onSortEnd}
        />
      </div>
    );
  }
}

const SortableList = SortableContainer(({ items }) => {
  return (
    <ul id="sort">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </ul>
  );
});

const SortableItem = SortableElement(({ value }) => {
  return <li>{value}</li>;
});
export default SortImages;
