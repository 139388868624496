import React, { Component } from "react";
import GensetRow from "./GensetRow";

const axios = require("axios");
require("dotenv").config();

class Genset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapse: "",
      AlertMessage: false,
      idTable: 0,
      gensetRow: "",
      keyCount: 0,
    };
  }

  handleData = (a) => {
    if (!isNaN(this.props.idBoat)) {
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatGenset/${this.props.idBoat}`,
      })
        .then(function (response) {
          if (response.data.success !== false) {
            var gensetRow = [];

            for (let i = 0; i < response.data.data.results.length; i++) {
              gensetRow.push(
                <GensetRow
                  idBoat={self.props.idBoat}
                  rowId={response.data.data.results[i].id}
                  key={self.state.keyCount++}
                  tooltipData={self.props.tooltipData}
                  updateTooltip={self.props.updateTooltip}
                />
              );
            }

            if (a === "new") {
              gensetRow.push(
                <GensetRow
                  idBoat={self.props.idBoat}
                  rowId="0"
                  key={self.state.keyCount++}
                  tooltipData={self.props.tooltipData}
                  updateTooltip={self.props.updateTooltip}
                />
              );
            }

            self.setState({
              showCollapse: "show",
              gensetRow: gensetRow,
            });
          } else {
            if (a === "new") {
              self.setState({
                gensetRow: (
                  <GensetRow
                    idBoat={self.props.idBoat}
                    rowId="0"
                    key={self.state.keyCount++}
                    tooltipData={self.props.tooltipData}
                    updateTooltip={self.props.updateTooltip}
                  />
                ),
              });
            }
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    }
  };

  componentDidMount() {
    this.handleData();
  }

  render() {
    if (this.props.idBoat === "new") {
      return null;
    }

    return (
      <div className="accordion mb-4 mt-4" id="accordion">
        <div className="card">
          <div className="card-header" id={`headingGenset`}>
            <h2 className="mb-0">
              <button
                className="btn btn-secondary w-100"
                type="button"
                data-toggle="collapse"
                data-target={`#collapseGenset`}
                aria-expanded="true"
                aria-controls="collapseGenset"
              >
                Genset
              </button>
            </h2>
          </div>

          <div
            id="collapseGenset"
            className={`collapse ${this.state.showCollapse}`}
            aria-labelledby="headingGenset"
            data-parent="#accordion"
          >
            <div className="card-body mt-4">
              {this.state.gensetRow}
              <input
                type="submit"
                className="btn btn-secundary border border-success mt-2 ml-2"
                value="Add New Genset"
                onClick={() => this.handleData("new")}
              ></input>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Genset;
