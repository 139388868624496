import React, { Component } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import AutoGateStatus from "../../../integration/autoGateStatus";

class Integrations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoGate: this.props.autoGate,
      tradeABoat: this.props.tradeABoat,
      boatId: this.props.boatId,
    };
  }

  handleAutoGate = () => {};
  handleTradeABoat = () => {};

  render() {
    return (
      <div className="card mt-3">
        <div className="card-header">Integrations</div>
        <div className="card-body">
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={
                    !isNaN(this.props.autoGate) && this.props.autoGate === 0
                      ? false
                      : true
                  }
                  color="primary"
                  onChange={() => this.props.onChange("autoGate")}
                />
              }
              label="Link to BoatSales / BoatPoint"
              className="w-100"
            />
            <AutoGateStatus boatId={this.props.boatId} />
            <FormControlLabel
              control={
                <Switch
                  checked={
                    !isNaN(this.props.luxmarine) && this.props.luxmarine === 0
                      ? false
                      : true
                  }
                  color="primary"
                  onChange={() => this.props.onChange("luxmarine")}
                />
              }
              label="Link to LuxMarine"
              className="w-100"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={
                    !isNaN(this.props.tradeABoat) && this.props.tradeABoat === 0
                      ? false
                      : true
                  }
                  color="primary"
                  onChange={() => this.props.onChange("tradeABoat")}
                />
              }
              label="Link to Trade a Boat"
              className="w-100"
            />
          </FormGroup>
        </div>
      </div>
    );
  }
}

export default Integrations;
