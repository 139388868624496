import React, { useState, useEffect } from "react";

const TextHelper = ({ data, fieldId, updateTooltip }) => {
  const [tooltip, setTooltip] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editValue, setEditValue] = useState();
  const [editable, setEditable] = useState(true);

  useEffect(() => {
    if (data && data[0] && data[0].tooltip) {
      setEditValue(data[0].tooltip);
    }
  }, [data]);

  useEffect(() => {
    localStorage.getItem("userEmail") === "boatadmin@boatsync.com.au"
      ? setEditable(true)
      : setEditable(false);
  }, []);

  return (
    <>
      <span style={{ marginLeft: "2px" }}>
        {data && data[0] && data[0].tooltip && (
          <>
            <i
              class="icon ion-information-circled"
              onMouseOver={() => setTooltip(true)}
              onMouseOut={() => setTooltip(false)}
            ></i>
            {tooltip && <p className="tooltiptextnew">{data[0].tooltip}</p>}
          </>
        )}
        {editable && (
          <i class="icon ion-more ml-2" onClick={() => setEdit(!edit)}></i>
        )}
        {edit && (
          <>
            <input
              type="text"
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              style={{
                display: "block",
                backgroundColor: "#6f6d98",
                color: "white",
                width: "100%",
              }}
            />
            <button
              className="mt-1 w-100"
              onClick={() => {
                updateTooltip(fieldId, editValue);
                setEdit(!edit);
              }}
            >
              Save
            </button>
          </>
        )}
      </span>
    </>
  );
};

export default TextHelper;
