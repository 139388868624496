import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ModalDialogDelete from "../../geral/ModalDialogDelete";

class LocationRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectPage: false,
      modalDelete: false,
      showMe: true
    };
  }

  handleClose = id => {
    this.setState({ showMe: false });
    this.props.handleDelete(id);
  };

  handleChildUnmount = () => {
    this.setState({
      modalDelete: false
    });
  };

  processDelete = (id, title) => {
    this.setState({
      modalDelete: (
        <ModalDialogDelete
          name={title}
          idRow={id}
          unmountMe={this.handleChildUnmount}
          deleteMe={() => this.handleClose(id)}
        />
      )
    });
  };

  handleEdit = id => {
    this.setState({
      redirectPage: <Redirect to={`/utilities/locations/${id}`} />
    });
  };

  render() {
    const {
      id,
      firstName,
      lastName,
      state,
      city,
      title,
      postcode,
      suburb,
      addressLine1,
      addressLine2,
      defaultAddress
    } = this.props.data;

    if (this.state.showMe === false) {
      return false;
    }
    return (
      <tr key={id}>
        {this.state.redirectPage}
        {this.state.modalDelete}
        <td className="">{id}</td>
        <td className="">{title}</td>
        <td className="">
          {firstName} {lastName}
        </td>
        <td className="">
          {addressLine1} {addressLine2 ? <br /> : ""} {addressLine2}
          <br />
          {`${state} - ${city}`}
          <br />
          {`${postcode} - ${suburb}`}
        </td>
        <td>{defaultAddress ? "Default" : ""}</td>
        <td className="">
          <i
            className="icon ion-edit tx-20 pointer"
            onClick={() => this.handleEdit(id)}
          />
          <i
            className="icon ion-close tx-20 mg-l-20 pointer"
            onClick={() => this.processDelete(id, title)}
          />
        </td>
      </tr>
    );
  }
}

export default LocationRow;
