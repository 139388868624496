import React, { Component } from "react";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";
import _ from "lodash";
import TextHelper from "../../utilities/textHelper";

const axios = require("axios");
require("dotenv").config();

class AdditionalEquipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapse: "",
      AlertMessage: false,
      idTable: 0,
      // Fields
      waterMaker: "",
      airconditioning: "",
      radio: "",
      iceMaker: "",
      deckGear: "",
      washingMachineDryer: "",
      deckWashPump: "",
      cockpitShower: "",
      teakDecks: "",
      cockpitBBQ: "",
      carpetOtherFlooring: "",
      cableMaster: "",
      coffeeMaker: "",
      oilChangeSystem: "",
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }
  handleChangeSelect = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  componentDidMount() {
    if (this.props.idBoat !== "new") this.handleData();
  }

  handleData = () => {
    if (!isNaN(this.props.idBoat)) {
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatAdditionalEquipment/${this.props.idBoat}`,
      })
        .then(function (response) {
          if (response.data.success !== false) {
            const {
              waterMaker,
              airconditioning,
              radio,
              iceMaker,
              deckGear,
              washingMachineDryer,
              deckWashPump,
              cockpitShower,
              teakDecks,
              cockpitBBQ,
              carpetOtherFlooring,
              cableMaster,
              coffeeMaker,
              oilChangeSystem,
              id,
            } = response.data.data.results[0];

            self.setState({
              waterMaker: waterMaker,
              airconditioning: airconditioning,
              radio: radio,
              iceMaker: iceMaker,
              deckGear: deckGear,
              washingMachineDryer: washingMachineDryer,
              deckWashPump: deckWashPump,
              cockpitShower: cockpitShower,
              teakDecks: teakDecks,
              cockpitBBQ: cockpitBBQ,
              carpetOtherFlooring: carpetOtherFlooring,
              cableMaster: cableMaster,
              coffeeMaker: coffeeMaker,
              oilChangeSystem: oilChangeSystem,
              idTable: id,
              showCollapse: "show",
            });
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    }
  };

  submitForm = () => {
    if (this.state.idTable !== 0) {
      this.updateData();
    } else {
      this.insertData();
    }
  };

  updateData = () => {
    var self = this;
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatAdditionalEquipment/${this.props.idBoat}`,
      data: {
        waterMaker: this.state.waterMaker,
        airconditioning: this.state.airconditioning,
        radio: this.state.radio,
        iceMaker: this.state.iceMaker,
        deckGear: this.state.deckGear,
        washingMachineDryer: this.state.washingMachineDryer,
        deckWashPump: this.state.deckWashPump,
        cockpitShower: this.state.cockpitShower,
        teakDecks: this.state.teakDecks,
        cockpitBBQ: this.state.cockpitBBQ,
        carpetOtherFlooring: this.state.carpetOtherFlooring,
        cableMaster: this.state.cableMaster,
        coffeeMaker: this.state.coffeeMaker,
        oilChangeSystem: this.state.oilChangeSystem,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        self.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Erro!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  insertData = () => {
    //console.log("AddMe");
    //console.log(this.state);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatAdditionalEquipment/${this.props.idBoat}`,
      data: {
        waterMaker: this.state.waterMaker,
        airconditioning: this.state.airconditioning,
        radio: this.state.radio,
        iceMaker: this.state.iceMaker,
        deckGear: this.state.deckGear,
        washingMachineDryer: this.state.washingMachineDryer,
        deckWashPump: this.state.deckWashPump,
        cockpitShower: this.state.cockpitShower,
        teakDecks: this.state.teakDecks,
        cockpitBBQ: this.state.cockpitBBQ,
        carpetOtherFlooring: this.state.carpetOtherFlooring,
        cableMaster: this.state.cableMaster,
        coffeeMaker: this.state.coffeeMaker,
        oilChangeSystem: this.state.oilChangeSystem,
        boatId: this.props.idBoat,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success, id } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }

        this.setState({
          idTable: id,
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Error!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  render() {
    if (this.props.idBoat === "new") {
      return null;
    }

    return (
      <div className="accordion mb-4 mt-4" id="accordion">
        <div className="card">
          <div className="card-header" id={`headingAEquipment`}>
            <h2 className="mb-0">
              <button
                className="btn btn-secondary w-100"
                type="button"
                data-toggle="collapse"
                data-target={`#collapseAEquipment`}
                aria-expanded="true"
                aria-controls="collapseAEquipment"
              >
                Additional Equipment
              </button>
            </h2>
          </div>

          <div
            id="collapseAEquipment"
            className={`collapse ${this.state.showCollapse}`}
            aria-labelledby="headingAEquipment"
            data-parent="#accordion"
          >
            <div className="card-body mt-4">
              <div className="row mg-b-25">
                {/* Air Conditioning # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Air Conditioning
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "addequip.aircond") {
                            return o;
                          }
                        })}
                        fieldId="addequip.aircond"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="airconditioning"
                      className="w-100"
                      maxLength="500"
                      value={this.state.airconditioning}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Ice Maker # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Ice Maker
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "addequip.icemaker") {
                            return o;
                          }
                        })}
                        fieldId="addequip.icemaker"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="iceMaker"
                      className="w-100"
                      maxLength="500"
                      value={this.state.iceMaker}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/*Washing Machine / Dryer# */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Washing Machine / Dryer
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "addequip.washingmachine") {
                            return o;
                          }
                        })}
                        fieldId="addequip.washingmachine"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="washingMachineDryer"
                      className="w-100"
                      maxLength="500"
                      value={this.state.washingMachineDryer}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Deck Wash Pump # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Deck Wash Pump
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "addequip.deckwashpump") {
                            return o;
                          }
                        })}
                        fieldId="addequip.deckwashpump"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="deckWashPump"
                      className="w-100"
                      maxLength="500"
                      value={this.state.deckWashPump}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Cockpit Shower# */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Cockpit Shower
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "addequip.cockpitshower") {
                            return o;
                          }
                        })}
                        fieldId="addequip.cockpitshower"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="cockpitShower"
                      className="w-100"
                      maxLength="500"
                      value={this.state.cockpitShower}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Teak Decks# */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Teak Decks
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "addequip.teakdecks") {
                            return o;
                          }
                        })}
                        fieldId="addequip.teakdecks"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="teakDecks"
                      className="w-100"
                      maxLength="500"
                      value={this.state.teakDecks}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Cockpit BBQ # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Cockpit BBQ
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "addequip.cockpitbbq") {
                            return o;
                          }
                        })}
                        fieldId="addequip.cockpitbbq"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="cockpitBBQ"
                      className="w-100"
                      maxLength="500"
                      value={this.state.cockpitBBQ}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Carpet Other Flooring # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Carpet Other Flooring
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "addequip.carpet") {
                            return o;
                          }
                        })}
                        fieldId="addequip.carpet"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="carpetOtherFlooring"
                      className="w-100"
                      maxLength="500"
                      value={this.state.carpetOtherFlooring}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Water Maker # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Water Maker
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "addequip.watermaker") {
                            return o;
                          }
                        })}
                        fieldId="addequip.watermaker"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="waterMaker"
                      className="w-100"
                      maxLength="500"
                      value={this.state.waterMaker}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Cable Master # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Cable Master
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "addequip.cablemaster") {
                            return o;
                          }
                        })}
                        fieldId="addequip.cablemaster"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="cableMaster"
                      className="w-100"
                      maxLength="500"
                      value={this.state.cableMaster}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Coffee Maker # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Coffee Maker
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "addequip.coffeemaker") {
                            return o;
                          }
                        })}
                        fieldId="addequip.coffeemaker"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="coffeeMaker"
                      className="w-100"
                      maxLength="500"
                      value={this.state.coffeeMaker}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Oil Change System # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Oil Change System
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "addequip.oilchangesys") {
                            return o;
                          }
                        })}
                        fieldId="addequip.oilchangesys"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="oilChangeSystem"
                      className="w-100"
                      maxLength="500"
                      value={this.state.oilChangeSystem}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Radio # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Radio
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "addequip.radio") {
                            return o;
                          }
                        })}
                        fieldId="addequip.radio"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="radio"
                      className="w-100"
                      maxLength="500"
                      value={this.state.radio}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Deck Gear# */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Deck Gear
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "addequip.deckgear") {
                            return o;
                          }
                        })}
                        fieldId="addequip.deckgear"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="deckGear"
                      className="w-100"
                      value={this.state.deckGear}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <input
                  type="submit"
                  className="btn btn-primary mb-4"
                  formAction="submit"
                  value="Submit Additional Equipment"
                  onClick={this.submitForm.bind(this)}
                />
                {this.state.AlertMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdditionalEquipment;
