import React, { Component } from "react";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";
import _ from "lodash";
import TextHelper from "../../utilities/textHelper";

const axios = require("axios");
require("dotenv").config();

class Eletronics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapse: "",
      AlertMessage: false,
      idTable: 0,
      // Fields
      navigation: "",
      steeringCompass: "",
      chartingSystem: "",
      monitors: "",
      autoPilot: "",
      sounders: "",
      radar: "",
      vhfRadio: "",
      electEngineCtrls: "",
      satelliteDome: "",
      gpsSystem: "",
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }
  handleChangeSelect = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  componentDidMount() {
    if (this.props.idBoat !== "new") this.handleData();
  }

  handleData = () => {
    if (!isNaN(this.props.idBoat)) {
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatElectronics/${this.props.idBoat}`,
      })
        .then(function (response) {
          if (response.data.success !== false) {
            const {
              navigation,
              steeringCompass,
              chartingSystem,
              monitors,
              autoPilot,
              sounders,
              radar,
              vhfRadio,
              electEngineCtrls,
              satelliteDome,
              gpsSystem,
              id,
            } = response.data.data.results[0];

            self.setState({
              navigation: navigation,
              steeringCompass: steeringCompass,
              chartingSystem: chartingSystem,
              monitors: monitors,
              autoPilot: autoPilot,
              sounders: sounders,
              radar: radar,
              vhfRadio: vhfRadio,
              electEngineCtrls: electEngineCtrls,
              satelliteDome: satelliteDome,
              gpsSystem: gpsSystem,
              idTable: id,
              showCollapse: "show",
            });
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    }
  };

  submitForm = () => {
    if (this.state.idTable !== 0) {
      this.updateData();
    } else {
      this.insertData();
    }
  };

  updateData = () => {
    var self = this;
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatElectronics/${this.props.idBoat}`,
      data: {
        navigation: this.state.navigation,
        steeringCompass: this.state.steeringCompass,
        chartingSystem: this.state.chartingSystem,
        monitors: this.state.monitors,
        autoPilot: this.state.autoPilot,
        sounders: this.state.sounders,
        radar: this.state.radar,
        vhfRadio: this.state.vhfRadio,
        electEngineCtrls: this.state.electEngineCtrls,
        satelliteDome: this.state.satelliteDome,
        gpsSystem: this.state.gpsSystem,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        self.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Erro!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  insertData = () => {
    //console.log("AddMe");
    //console.log(this.state);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatElectronics/${this.props.idBoat}`,
      data: {
        navigation: this.state.navigation,
        steeringCompass: this.state.steeringCompass,
        chartingSystem: this.state.chartingSystem,
        monitors: this.state.monitors,
        autoPilot: this.state.autoPilot,
        sounders: this.state.sounders,
        radar: this.state.radar,
        vhfRadio: this.state.vhfRadio,
        electEngineCtrls: this.state.electEngineCtrls,
        satelliteDome: this.state.satelliteDome,
        gpsSystem: this.state.gpsSystem,
        boatId: this.props.idBoat,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success, id } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }

        this.setState({
          idTable: id,
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Error!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  render() {
    if (this.props.idBoat === "new") {
      return null;
    }

    return (
      <div className="accordion mb-4 mt-4" id="accordion">
        <div className="card">
          <div className="card-header" id={`headingElectronics`}>
            <h2 className="mb-0">
              <button
                className="btn btn-secondary w-100"
                type="button"
                data-toggle="collapse"
                data-target={`#collapseElectronics`}
                aria-expanded="true"
                aria-controls="collapseElectronics"
              >
                Electronics / Navigation
              </button>
            </h2>
          </div>

          <div
            id="collapseElectronics"
            className={`collapse ${this.state.showCollapse}`}
            aria-labelledby="headingElectronics"
            data-parent="#accordion"
          >
            <div className="card-body mt-4">
              <div className="row mg-b-25">
                {/* Steering Compass # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Steering Compass
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "electronics.steeringcompass") {
                            return o;
                          }
                        })}
                        fieldId="electronics.steeringcompass"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="steeringCompass"
                      className="w-100"
                      maxLength="255"
                      value={this.state.steeringCompass}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* GPS System # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      GPS System
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "electronics.gpssystem") {
                            return o;
                          }
                        })}
                        fieldId="electronics.gpssystem"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="gpsSystem"
                      className="w-100"
                      maxLength="255"
                      value={this.state.gpsSystem}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/*Charting System# */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Charting System
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "electronics.chartingsystem") {
                            return o;
                          }
                        })}
                        fieldId="electronics.chartingsystem"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="chartingSystem"
                      className="w-100"
                      maxLength="255"
                      value={this.state.chartingSystem}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Monitors # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Monitors
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "electronics.monitors") {
                            return o;
                          }
                        })}
                        fieldId="electronics.monitors"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="monitors"
                      className="w-100"
                      maxLength="255"
                      value={this.state.monitors}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Autopilot # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Autopilot
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "electronics.autopilot") {
                            return o;
                          }
                        })}
                        fieldId="electronics.autopilot"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="autoPilot"
                      className="w-100"
                      maxLength="255"
                      value={this.state.autoPilot}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Sounders # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Sounders
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "electronics.souders") {
                            return o;
                          }
                        })}
                        fieldId="electronics.souders"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="sounders"
                      className="w-100"
                      maxLength="255"
                      value={this.state.sounders}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Radar # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Radar
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "electronics.radar") {
                            return o;
                          }
                        })}
                        fieldId="electronics.radar"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="radar"
                      className="w-100"
                      maxLength="255"
                      value={this.state.radar}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* VHF Radio # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      VHF Radio
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "electronics.vhfradio") {
                            return o;
                          }
                        })}
                        fieldId="electronics.vhfradio"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="vhfRadio"
                      className="w-100"
                      maxLength="255"
                      value={this.state.vhfRadio}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Engine Controls # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Engine Controls
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "electronics.enginecontrols") {
                            return o;
                          }
                        })}
                        fieldId="electronics.enginecontrols"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="electEngineCtrls"
                      className="w-100"
                      maxLength="255"
                      value={this.state.electEngineCtrls}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Satellite Dome # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Satellite Dome
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "electronics.satellitedome") {
                            return o;
                          }
                        })}
                        fieldId="electronics.satellitedome"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="satelliteDome"
                      className="w-100"
                      maxLength="255"
                      value={this.state.satelliteDome}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Navigation# */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Navigation
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "electronics.navigation") {
                            return o;
                          }
                        })}
                        fieldId="electronics.navigation"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="navigation"
                      className="w-100"
                      maxLength="255"
                      value={this.state.navigation}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <input
                  type="submit"
                  className="btn btn-primary mb-4"
                  formAction="submit"
                  value="Submit Engineering"
                  onClick={this.submitForm.bind(this)}
                />
                {this.state.AlertMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Eletronics;
