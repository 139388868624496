import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";

const axios = require("axios");

require("dotenv").config();

class LocationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AlertMessage: false,
      handleError: false,
      showData: false,
      redirectPage: false,
      //dataFields
      title: "",
      firstName: "",
      lastName: "",
      mobileNumber: "",
      phoneNumber: "",
      faxNumber: "",
      emailAddress: "",
      postcode: "",
      addressLine1: "",
      addressLine2: "",
      suburb: "",
      state: "",
      city: "",
      defaultAddress: ""
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
  }

  updateData = () => {
    const { id } = this.props.match.params;
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}dealerAddress/${localStorage.userDealerId}/${id}`,
      data: {
        title: this.state.title,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        mobileNumber: this.state.mobileNumber,
        phoneNumber: this.state.phoneNumber,
        faxNumber: this.state.faxNumber,
        emailAddress: this.state.emailAddress,
        country: this.state.country,
        postcode: this.state.postcode,
        addressLine1: this.state.addressLine1,
        addressLine2: this.state.addressLine2,
        suburb: this.state.suburb,
        state: this.state.state,
        city: this.state.city,
        defaultAddress: this.state.defaultAddress === true ? 1 : 0
      }
    })
      .then(response => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          )
        });
      })
      .catch(function(response) {
        console.log(response);
        const { msg } = response.data;
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message={msg}
              unmountMe={this.handleCloseAlert}
            />
          )
        });
      });
  };

  insertData = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}dealerAddress/${localStorage.userDealerId}`,
      data: {
        title: this.state.title,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        mobileNumber: this.state.mobileNumber,
        phoneNumber: this.state.phoneNumber,
        faxNumber: this.state.faxNumber,
        emailAddress: this.state.emailAddress,
        country: this.state.country,
        postcode: this.state.postcode,
        addressLine1: this.state.addressLine1,
        addressLine2: this.state.addressLine2,
        suburb: this.state.suburb,
        state: this.state.state,
        city: this.state.city,
        defaultAddress: this.state.defaultAddress === true ? 1 : 0,
        idDealer: localStorage.userDealerId
      }
    })
      .then(response => {
        const { msg, success, id } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }

        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
          redirectPage: <Redirect to={`utilities/location/${id}`} />
        });
      })
      .catch(function(response) {
        console.log(response);
        const { msg } = response.data;
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message={msg}
              unmountMe={this.handleCloseAlert}
            />
          )
        });
      });
  };

  submitForm = () => {
    var msgErro = "";
    const {
      title,
      emailAddress,
      postcode,
      addressLine1,
      suburb,
      state,
      city
    } = this.state;
    if (title === "") {
      msgErro += "Title, ";
    }
    if (emailAddress === "") {
      msgErro += "Email Address, ";
    }
    if (postcode === "") {
      msgErro += "Postcode, ";
    }
    if (addressLine1 === "") {
      msgErro += "Address Line 1, ";
    }
    if (suburb === "") {
      msgErro += "Suburb, ";
    }
    if (state === "") {
      msgErro += "State, ";
    }
    if (city === "") {
      msgErro += "City, ";
    }

    if (msgErro !== "") {
      msgErro = msgErro.substring(0, msgErro.length - 2);
      this.setState({
        AlertMessage: (
          <AlertMessageBootstrap
            messageType="danger"
            message={`Please fill the required fields: ${msgErro}`}
            unmountMe={this.handleCloseAlert}
          />
        )
      });
      return false;
    }

    if (!isNaN(this.props.match.params.id)) {
      this.updateData();
    } else {
      this.insertData();
    }
  };

  handleData = () => {
    const { id } = this.props.match.params;
    if (!isNaN(id)) {
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}dealerAddress/${localStorage.userDealerId}/${id}`
      })
        .then(function(response) {
          if (response.data.success === false) {
            self.setState({ handleError: <Redirect to="/404Error" /> });
          }
          const {
            title,
            firstName,
            lastName,
            mobileNumber,
            phoneNumber,
            faxNumber,
            emailAddress,
            postcode,
            addressLine1,
            addressLine2,
            suburb,
            state,
            city,
            defaultAddress
          } = response.data.data.results[0];

          self.setState({
            title: title,
            firstName: firstName,
            lastName: lastName,
            mobileNumber: mobileNumber,
            phoneNumber: phoneNumber,
            faxNumber: faxNumber,
            emailAddress: emailAddress,
            postcode: postcode,
            addressLine1: addressLine1,
            addressLine2: addressLine2 || "",
            suburb: suburb,
            state: state,
            city: city,
            defaultAddress: defaultAddress === 1 ? true : false
          });
        })
        .catch(function(response) {
          console.log(response);
        });
    }
  };

  handleChange = e => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  handleCheckBox = () => {
    if (this.state.defaultAddress === true) {
      this.setState({ defaultAddress: false });
    } else {
      this.setState({ defaultAddress: true });
    }
  };

  componentDidMount() {
    if (this.props.match.params.id !== "new") this.handleData();
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }

  render() {
    return (
      <div className="br-mainpanel">
        {this.state.handleError}
        {this.state.redirectPage}
        <div className="br-pageheader pd-y-15 pd-l-20">
          <nav className="breadcrumb pd-0 mg-0 tx-12">
            <a className="breadcrumb-item" href="index.html">
              Boat Sync
            </a>
            <span className="breadcrumb-item active">Utilities</span>
            <span className="breadcrumb-item active">Company Locations</span>
          </nav>
        </div>

        <div className="d-flex align-items-center justify-content-between mg-b-30 pd-x-30 pd-t-30">
          <div>
            <h4 className="tx-gray-800 mg-b-5">Company Locations</h4>
          </div>

          <div>
            <Link
              to="/utilities/locations/"
              className="btn btn-primary btn-oblong tx-11 tx-uppercase tx-mont tx-medium tx-spacing-1 pd-x-30 bd-2"
            >
              Go Back
            </Link>
          </div>
        </div>

        <div className="br-pagebody">
          <div className="br-section-wrapper">
            <div className="table-wrapper">
              <div className="form-layout">
                {this.state.AlertMessage}
                <div className="row mg-b-25">
                  {/* Title # */}
                  <div className="col-12">
                    <div className="form-group">
                      <label className="form-control-label d-block">
                        Location Title # <span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="title"
                        maxLength="255"
                        value={this.state.title}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <section id="manager-details">
                  <hr />
                  <div className="row mg-b-25">
                    <div className="col-12">
                      <div className="form-group">
                        <h4>Manager Details</h4>
                      </div>
                    </div>
                  </div>
                  <div className="row mg-b-25">
                    {/* First Name */}
                    <div className="col-6 col-sm-4">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          First Name
                        </label>
                        <input
                          type="text"
                          id="firstName"
                          maxLength="50"
                          onChange={this.handleChange}
                          value={this.state.firstName}
                        />
                      </div>
                    </div>
                    {/* Last Name */}
                    <div className="col-6 col-sm-4">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          Last Name <span className="tx-danger"></span>
                        </label>
                        <input
                          type="text"
                          id="lastName"
                          maxLength="50"
                          onChange={this.handleChange}
                          value={this.state.lastName}
                        />
                      </div>
                    </div>
                    {/* Email */}
                    <div className="col-6 col-sm-4">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          Email <span className="tx-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="emailAddress"
                          maxLength="255"
                          onChange={this.handleChange}
                          value={this.state.emailAddress}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mg-b-25">
                    {/* Mobile Number */}
                    <div className="col-6 col-sm-4">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          Mobile Number
                        </label>
                        <input
                          type="number"
                          id="mobileNumber"
                          maxLength="50"
                          onChange={this.handleChange}
                          value={this.state.mobileNumber}
                        />
                      </div>
                    </div>
                    {/* Last Name */}
                    <div className="col-6 col-sm-4">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          Phone Number <span className="tx-danger"></span>
                        </label>
                        <input
                          type="number"
                          id="phoneNumber"
                          maxLength="50"
                          onChange={this.handleChange}
                          value={this.state.phoneNumber}
                        />
                      </div>
                    </div>
                    {/* FAX Number */}
                    <div className="col-6 col-sm-4">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          Fax Number
                        </label>
                        <input
                          type="number"
                          id="faxNumber"
                          maxLength="50"
                          onChange={this.handleChange}
                          value={this.state.faxNumber}
                        />
                      </div>
                    </div>
                  </div>
                </section>

                <section id="location-details">
                  <hr />
                  <div className="row mg-b-25">
                    <div className="col-12">
                      <div className="form-group">
                        <h4>Location Details</h4>
                      </div>
                    </div>
                  </div>
                  <div className="row mg-b-25">
                    {/* Country */}
                    <div className="col-6 col-sm-4">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          Country <span className="tx-danger"></span>
                        </label>
                        <h5>
                          <span className="badge badge-info">Australia</span>
                        </h5>
                        <input type="hidden" id="country" value="Australia" />
                      </div>
                    </div>
                    {/* State */}
                    <div className="col-6 col-sm-4">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          State <span className="tx-danger">*</span>
                        </label>
                        <select
                          id="state"
                          className="w-50"
                          value={this.state.state}
                          onChange={this.handleChange}
                          required
                        >
                          <option></option>
                          <option value="NSW">NSW</option>
                          <option value="QLD">QLD</option>
                          <option value="SA">SA</option>
                          <option value="Tas">Tas</option>
                          <option value="Vic">Vic</option>
                          <option value="WA">WA</option>
                        </select>
                      </div>
                    </div>
                    {/* City */}
                    <div className="col-6 col-sm-4">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          City <span className="tx-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="city"
                          maxLength="255"
                          onChange={this.handleChange}
                          value={this.state.city}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mg-b-25">
                    {/* Postcode */}
                    <div className="col-6 col-sm-4">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          Postcode <span className="tx-danger">*</span>
                        </label>
                        <input
                          type="number"
                          id="postcode"
                          minLength="4"
                          maxLength="4"
                          onChange={this.handleChange}
                          value={this.state.postcode}
                          required
                        />
                      </div>
                    </div>
                    {/* Suburb */}
                    <div className="col-6 col-sm-4">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          Suburb <span className="tx-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="suburb"
                          maxLength="255"
                          onChange={this.handleChange}
                          value={this.state.suburb}
                          required
                        />
                      </div>
                    </div>
                    {/* Address */}
                    <div className="col-6 col-sm-4">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          Address <span className="tx-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="addressLine1"
                          maxLength="255"
                          className="d-block w-75"
                          onChange={this.handleChange}
                          value={this.state.addressLine1}
                          required
                        />
                        <input
                          type="text"
                          id="addressLine2"
                          maxLength="255"
                          onChange={this.handleChange}
                          value={this.state.addressLine2}
                          className="d-block w-75"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mg-b-25">
                    {/* DefaultLocation */}
                    <div className="col-6 col-sm-4">
                      <div className="form-group">
                        <input
                          type="checkbox"
                          id="defaultLocation"
                          checked={this.state.defaultAddress}
                          onChange={this.handleCheckBox}
                        />{" "}
                        Default Location
                      </div>
                    </div>

                    {/* Empty */}
                    <div className="col-6 col-sm-4"></div>
                  </div>
                </section>

                <div>
                  <hr />
                  <input
                    type="submit"
                    className="btn btn-primary mb-4"
                    formAction="submit"
                    onClick={this.submitForm.bind(this)}
                  />
                  {this.state.AlertMessage}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LocationForm;
