import React, { Component } from 'react';
import $ from "jquery";

class AlertMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {typeOfAlert: "alert alert-"+this.props.messageType}
    }
    render() {
        return (
            <div className={this.state.typeOfAlert} role="alert">
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
                {this.props.message}
            </div>
        );
    }

componentDidMount(){
    setTimeout(function() {
        $(".alert").fadeTo(500, 0).slideUp(500, function(){
            $(this).remove(); 
        });
    }, 4000);
}   
}

export default AlertMessage;