import React, { Component } from "react";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";
import _ from "lodash";
import TextHelper from "../../utilities/textHelper";
const axios = require("axios");
require("dotenv").config();

class Safety extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapse: "",
      AlertMessage: false,
      idTable: 0,
      // Fields
      lifeJackets: "",
      epirb: "",
      bilgePump: "",
      lifeRaft: "",
      lifeBuoys: "",
      gasFumeDetector: "",
      flares: "",
      fireExtinguishers: "",
      engineRoomSystem: "",
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }
  handleChangeSelect = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  componentDidMount() {
    if (this.props.idBoat !== "new") this.handleData();
  }

  handleData = () => {
    if (!isNaN(this.props.idBoat)) {
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatSafety/${this.props.idBoat}`,
      })
        .then(function (response) {
          if (response.data.success !== false) {
            const {
              lifeJackets,
              epirb,
              bilgePump,
              lifeRaft,
              lifeBuoys,
              gasFumeDetector,
              flares,
              fireExtinguishers,
              engineRoomSystem,
              id,
            } = response.data.data.results[0];

            self.setState({
              lifeJackets: lifeJackets,
              epirb: epirb,
              bilgePump: bilgePump,
              lifeRaft: lifeRaft,
              lifeBuoys: lifeBuoys,
              gasFumeDetector: gasFumeDetector,
              flares: flares,
              fireExtinguishers: fireExtinguishers,
              engineRoomSystem: engineRoomSystem,
              idTable: id,
              showCollapse: "show",
            });
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    }
  };

  submitForm = () => {
    if (this.state.idTable !== 0) {
      this.updateData();
    } else {
      this.insertData();
    }
  };

  updateData = () => {
    var self = this;
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatSafety/${this.props.idBoat}`,
      data: {
        lifeJackets: this.state.lifeJackets,
        epirb: this.state.epirb,
        bilgePump: this.state.bilgePump,
        lifeRaft: this.state.lifeRaft,
        lifeBuoys: this.state.lifeBuoys,
        gasFumeDetector: this.state.gasFumeDetector,
        flares: this.state.flares,
        fireExtinguishers: this.state.fireExtinguishers,
        engineRoomSystem: this.state.engineRoomSystem,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        self.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Erro!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  insertData = () => {
    //console.log("AddMe");
    //console.log(this.state);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatSafety/${this.props.idBoat}`,
      data: {
        lifeJackets: this.state.lifeJackets,
        epirb: this.state.epirb,
        bilgePump: this.state.bilgePump,
        lifeRaft: this.state.lifeRaft,
        lifeBuoys: this.state.lifeBuoys,
        gasFumeDetector: this.state.gasFumeDetector,
        flares: this.state.flares,
        fireExtinguishers: this.state.fireExtinguishers,
        engineRoomSystem: this.state.engineRoomSystem,
        boatId: this.props.idBoat,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success, id } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }

        this.setState({
          idTable: id,
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Error!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  render() {
    if (this.props.idBoat === "new") {
      return null;
    }

    return (
      <div className="accordion mb-4 mt-4" id="accordion">
        <div className="card">
          <div className="card-header" id={`headingSafety`}>
            <h2 className="mb-0">
              <button
                className="btn btn-secondary w-100"
                type="button"
                data-toggle="collapse"
                data-target={`#collapseSafety`}
                aria-expanded="true"
                aria-controls="collapseSafety"
              >
                Safety
              </button>
            </h2>
          </div>

          <div
            id="collapseSafety"
            className={`collapse ${this.state.showCollapse}`}
            aria-labelledby="headingSafety"
            data-parent="#accordion"
          >
            <div className="card-body mt-4">
              <div className="row mg-b-25">
                {/* Bilge Pump # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Bilge Pump
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "safety.bilgepump") {
                            return o;
                          }
                        })}
                        fieldId="safety.bilgepump"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="bilgePump"
                      className="w-100"
                      maxLength="500"
                      value={this.state.bilgePump}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Life Raft # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Life Raft
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "safety.liferaft") {
                            return o;
                          }
                        })}
                        fieldId="safety.liferaft"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="lifeRaft"
                      className="w-100"
                      maxLength="500"
                      value={this.state.lifeRaft}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/*Life Buoys# */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Life Buoys
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "safety.lifebuoys") {
                            return o;
                          }
                        })}
                        fieldId="safety.lifebuoys"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="lifeBuoys"
                      className="w-100"
                      maxLength="500"
                      value={this.state.lifeBuoys}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* EPIRB # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      EPIRB
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "safety.epirb") {
                            return o;
                          }
                        })}
                        fieldId="safety.epirb"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="epirb"
                      className="w-100"
                      maxLength="500"
                      value={this.state.epirb}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Life Jackets # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Life Jackets
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "safety.lifejackets") {
                            return o;
                          }
                        })}
                        fieldId="safety.lifejackets"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="lifeJackets"
                      className="w-100"
                      maxLength="500"
                      value={this.state.lifeJackets}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Gas Fume Detector # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Gas Fume Detector
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "safety.gasfumedetector") {
                            return o;
                          }
                        })}
                        fieldId="safety.gasfumedetector"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="gasFumeDetector"
                      className="w-100"
                      maxLength="500"
                      value={this.state.gasFumeDetector}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Flares # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Flares
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "safety.flares") {
                            return o;
                          }
                        })}
                        fieldId="safety.flares"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="flares"
                      className="w-100"
                      maxLength="500"
                      value={this.state.flares}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Fire Extinguishers # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Fire Extinguishers
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "safety.fireextinguishers") {
                            return o;
                          }
                        })}
                        fieldId="safety.fireextinguishers"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="fireExtinguishers"
                      className="w-100"
                      maxLength="500"
                      value={this.state.fireExtinguishers}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Engine Room System # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Engine Room System
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "safety.engineroomsystem") {
                            return o;
                          }
                        })}
                        fieldId="safety.engineroomsystem"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="engineRoomSystem"
                      className="w-100"
                      maxLength="500"
                      value={this.state.engineRoomSystem}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <input
                  type="submit"
                  className="btn btn-primary mb-4"
                  formAction="submit"
                  value="Submit Safety"
                  onClick={this.submitForm.bind(this)}
                />
                {this.state.AlertMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Safety;
