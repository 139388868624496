import React, { Component } from "react";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";
import _ from "lodash";
import TextHelper from "../../utilities/textHelper";

const axios = require("axios");
require("dotenv").config();

class BuyerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapse: "",
      AlertMessage: false,
      idTable: 0,
      // Fields
      buyerName: "",
      buyerABN: "",
      buyerAddress: "",
      buyerPhone: "",
      buyerWorkPhone: "",
      buyerMobilePhone: "",
      buyerFax: "",
      buyerEmail: "",
      buyerGSTRegistered: 0,
      buyerDOB: "",
      buyerDriversLicense: "",
      buyerMarketingCategory: "",
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }
  handleChangeSelect = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  handleChangeCheckbox = () => {
    if (this.state.buyerGSTRegistered === 0) {
      this.setState({ buyerGSTRegistered: 1 });
    } else {
      this.setState({ buyerGSTRegistered: 0 });
    }
  };

  componentDidMount() {
    if (this.props.idBoat !== "new") this.handleData();
  }

  handleData = () => {
    if (!isNaN(this.props.idBoat)) {
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatBuyerDetails/${this.props.idBoat}`,
      })
        .then(function (response) {
          if (response.data.success !== false) {
            const {
              buyerName,
              buyerABN,
              buyerAddress,
              buyerPhone,
              buyerWorkPhone,
              buyerMobilePhone,
              buyerFax,
              buyerEmail,
              buyerGSTRegistered,
              buyerDOB,
              buyerDriversLicense,
              buyerMarketingCategory,
              id,
            } = response.data.data.results[0];

            self.setState({
              buyerName: buyerName,
              buyerABN: buyerABN,
              buyerAddress: buyerAddress,
              buyerPhone: buyerPhone,
              buyerWorkPhone: buyerWorkPhone,
              buyerMobilePhone: buyerMobilePhone,
              buyerFax: buyerFax,
              buyerEmail: buyerEmail,
              buyerGSTRegistered: buyerGSTRegistered,
              buyerDOB: buyerDOB,
              buyerDriversLicense: buyerDriversLicense,
              buyerMarketingCategory: buyerMarketingCategory,
              idTable: id,
              showCollapse: "show",
            });
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    }
  };

  submitForm = () => {
    if (this.state.idTable !== 0) {
      this.updateData();
    } else {
      this.insertData();
    }
  };

  updateData = () => {
    var self = this;
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatBuyerDetails/${this.props.idBoat}`,
      data: {
        buyerName: this.state.buyerName,
        buyerABN: this.state.buyerABN,
        buyerAddress: this.state.buyerAddress,
        buyerPhone: this.state.buyerPhone,
        buyerWorkPhone: this.state.buyerWorkPhone,
        buyerMobilePhone: this.state.buyerMobilePhone,
        buyerFax: this.state.buyerFax,
        buyerEmail: this.state.buyerEmail,
        buyerGSTRegistered: this.state.buyerGSTRegistered,
        buyerDOB: this.state.buyerDOB,
        buyerDriversLicense: this.state.buyerDriversLicense,
        buyerMarketingCategory: this.state.buyerMarketingCategory,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        self.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Erro!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  insertData = () => {
    //console.log("AddMe");
    //console.log(this.state);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatBuyerDetails/${this.props.idBoat}`,
      data: {
        buyerName: this.state.buyerName,
        buyerABN: this.state.buyerABN,
        buyerAddress: this.state.buyerAddress,
        buyerPhone: this.state.buyerPhone,
        buyerWorkPhone: this.state.buyerWorkPhone,
        buyerMobilePhone: this.state.buyerMobilePhone,
        buyerFax: this.state.buyerFax,
        buyerEmail: this.state.buyerEmail,
        buyerGSTRegistered: this.state.buyerGSTRegistered,
        buyerDOB: this.state.buyerDOB,
        buyerDriversLicense: this.state.buyerDriversLicense,
        buyerMarketingCategory: this.state.buyerMarketingCategory,
        boatId: this.props.idBoat,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success, id } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }

        this.setState({
          idTable: id,
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Error!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  render() {
    if (this.props.idBoat === "new") {
      return null;
    }

    return (
      <div className="accordion mb-4 mt-4" id="accordion">
        <div className="card">
          <div className="card-header" id={`headingBuyerDetails`}>
            <h2 className="mb-0">
              <button
                className="btn btn-secondary w-100"
                type="button"
                data-toggle="collapse"
                data-target={`#collapseBuyerDetails`}
                aria-expanded="true"
                aria-controls="collapseBuyerDetails"
              >
                Buyer Details
              </button>
            </h2>
          </div>

          <div
            id="collapseBuyerDetails"
            className={`collapse ${this.state.showCollapse}`}
            aria-labelledby="headingBuyerDetails"
            data-parent="#accordion"
          >
            <div className="card-body mt-4">
              <div className="row mg-b-25">
                {/* buyerName # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Name
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "buyerdetails.name") {
                            return o;
                          }
                        })}
                        fieldId="buyerdetails.name"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="buyerName"
                      className="w-100"
                      maxLength="500"
                      value={this.state.buyerName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* buyerABN # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      ABN
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "buyerdetails.abn") {
                            return o;
                          }
                        })}
                        fieldId="buyerdetails.abn"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="buyerABN"
                      className="w-100"
                      maxLength="500"
                      value={this.state.buyerABN}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/*Address# */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Address
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "buyerdetails.address") {
                            return o;
                          }
                        })}
                        fieldId="buyerdetails.address"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="buyerAddress"
                      className="w-100"
                      maxLength="500"
                      value={this.state.buyerAddress}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Phone # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Phone
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "buyerdetails.phone") {
                            return o;
                          }
                        })}
                        fieldId="buyerdetails.phone"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="buyerPhone"
                      className="w-100"
                      maxLength="500"
                      value={this.state.buyerPhone}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Work Phone # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Work Phone
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "buyerdetails.workphone") {
                            return o;
                          }
                        })}
                        fieldId="buyerdetails.workphone"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="buyerWorkPhone"
                      className="w-100"
                      maxLength="500"
                      value={this.state.buyerWorkPhone}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Mobile # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Mobile
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "buyerdetails.mobile") {
                            return o;
                          }
                        })}
                        fieldId="buyerdetails.mobile"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="buyerMobilePhone"
                      className="w-100"
                      maxLength="500"
                      value={this.state.buyerMobilePhone}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Fax # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Fax
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "buyerdetails.fax") {
                            return o;
                          }
                        })}
                        fieldId="buyerdetails.fax"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="buyerFax"
                      className="w-100"
                      maxLength="500"
                      value={this.state.buyerFax}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Email # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Email
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "buyerdetails.email") {
                            return o;
                          }
                        })}
                        fieldId="buyerdetails.email"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="buyerEmail"
                      className="w-100"
                      maxLength="500"
                      value={this.state.buyerEmail}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* GST Registered # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      GST Registered
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "buyerdetails.gstregistered") {
                            return o;
                          }
                        })}
                        fieldId="buyerdetails.gstregistered"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="checkbox"
                      id="buyerGSTRegistered"
                      value={this.state.buyerGSTRegistered}
                      onChange={this.handleChangeCheckbox}
                    />
                  </div>
                </div>

                {/* DOB # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      DOB
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "buyerdetails.dob") {
                            return o;
                          }
                        })}
                        fieldId="buyerdetails.dob"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="buyerDOB"
                      className="w-100"
                      maxLength="500"
                      value={this.state.buyerDOB}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Drivers License # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Drivers License
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "buyerdetails.driverslicense") {
                            return o;
                          }
                        })}
                        fieldId="buyerdetails.driverslicense"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="buyerDriversLicense"
                      className="w-100"
                      maxLength="500"
                      value={this.state.buyerDriversLicense}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Marketing Category # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Marketing Category
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "buyerdetails.mktcategory") {
                            return o;
                          }
                        })}
                        fieldId="buyerdetails.mktcategory"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="buyerMarketingCategory"
                      className="w-100"
                      maxLength="500"
                      value={this.state.buyerMarketingCategory}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <input
                  type="submit"
                  className="btn btn-primary mb-4"
                  formAction="submit"
                  value="Submit Buyer Details"
                  onClick={this.submitForm.bind(this)}
                />
                {this.state.AlertMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BuyerDetails;
