import React, { Component } from "react";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";
import _ from "lodash";
import TextHelper from "../../utilities/textHelper";

const axios = require("axios");
require("dotenv").config();

class GensetRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapse: "",
      AlertMessage: false,
      idTable: 0,
      showme: "",
      // Fields
      make: "",
      model: "",
      hours: "",
      displacement: "",
      fuelType: "",
      comments: "",
    };

    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }
  handleChangeSelect = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  componentDidMount() {
    if (this.props.idBoat !== "new") this.handleData();
  }

  delete() {
    var self = this;
    if (this.state.idTable !== 0) {
      var vUrl = `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatGenset/${this.props.idBoat}/${this.state.idTable}`;
      axios({
        method: "delete",
        url: vUrl,
      })
        .then(function (response) {
          self.setState({ showme: "d-none" });
        })
        .catch(function (response) {
          console.log(response);
        });
    } else {
      self.setState({ showme: "d-none" });
    }
  }

  handleData = () => {
    if (!isNaN(this.props.idBoat)) {
      var self = this;
      console.log("props.rowId", self.props.rowId);
      var vUrl = "";
      if (Number(self.props.rowId) !== 0) {
        vUrl = `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatGenset/${self.props.idBoat}/${self.props.rowId}`;
        self.setState({ idTable: self.props.rowId, bgRow: "bg-light" });

        axios({
          method: "get",
          url: vUrl,
        })
          .then(function (response) {
            if (response.data.success !== false) {
              const {
                make,
                model,
                hours,
                displacement,
                fuelType,
                comments,
                id,
              } = response.data.data.results[0];

              self.setState({
                make: make,
                model: model,
                displacement: displacement,
                hours: hours,
                fuelType: fuelType,
                comments: comments,
                idTable: id,
                showCollapse: "show",
              });
            }
          })
          .catch(function (response) {
            console.log(response);
          });
      } else {
        vUrl = `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatGenset/${self.props.idBoat}`;
        self.setState({ bgRow: "bg-secondary text-white" });
      }

      console.log("vUrl", vUrl);
    }
  };

  submitForm = () => {
    if (this.state.idTable !== 0) {
      this.updateData();
    } else {
      this.insertData();
    }
  };

  updateData = () => {
    var self = this;
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatGenset/${this.props.idBoat}/${this.state.idTable}`,
      data: {
        make: this.state.make,
        model: this.state.model,
        displacement: this.state.displacement,
        hours: this.state.hours,
        fuelType: this.state.fuelType,
        comments: this.state.comments,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        self.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Erro!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  insertData = () => {
    //console.log("AddMe");
    //console.log(this.state);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatGenset/${this.props.idBoat}`,
      data: {
        make: this.state.make,
        model: this.state.model,
        displacement: this.state.displacement,
        hours: this.state.hours,
        fuelType: this.state.fuelType,
        comments: this.state.comments,
        boatId: this.props.idBoat,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success, id } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }

        this.setState({
          idTable: id,
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Error!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  render() {
    if (this.props.idBoat === "new") {
      return null;
    }

    return (
      <div className={this.state.showme}>
        <div
          className={`row mg-b-25 mb-0 border border-secondary ${this.state.bgRow} p-3 m-2`}
        >
          {/* Make # */}
          <div className="col-2">
            <div className="form-group">
              <label className="form-control-label d-block">
                Make
                <TextHelper
                  data={_.filter(this.props.tooltipData, function (o) {
                    if (o.fieldId === "genset.make") {
                      return o;
                    }
                  })}
                  fieldId="genset.make"
                  updateTooltip={this.props.updateTooltip}
                />
              </label>
              <input
                type="text"
                id="make"
                className="w-100"
                maxLength="256"
                value={this.state.make}
                onChange={this.handleChange}
              />
            </div>
          </div>
          {/* model # */}
          <div className="col-2">
            <div className="form-group">
              <label className="form-control-label d-block">
                Model
                <TextHelper
                  data={_.filter(this.props.tooltipData, function (o) {
                    if (o.fieldId === "genset.model") {
                      return o;
                    }
                  })}
                  fieldId="genset.model"
                  updateTooltip={this.props.updateTooltip}
                />
              </label>
              <input
                type="text"
                id="model"
                className="w-100"
                maxLength="256"
                value={this.state.model}
                onChange={this.handleChange}
              />
            </div>
          </div>

          {/* displacement # */}
          <div className="col-2">
            <div className="form-group">
              <label className="form-control-label d-block">
                Displacement
                <TextHelper
                  data={_.filter(this.props.tooltipData, function (o) {
                    if (o.fieldId === "genset.displacement") {
                      return o;
                    }
                  })}
                  fieldId="genset.displacement"
                  updateTooltip={this.props.updateTooltip}
                />
              </label>
              <input
                type="text"
                id="displacement"
                className="w-100"
                maxLength="256"
                value={this.state.displacement}
                onChange={this.handleChange}
              />
            </div>
          </div>

          {/* fuelType # */}
          <div className="col-2">
            <div className="form-group">
              <label className="form-control-label d-block">
                Fuel Type
                <TextHelper
                  data={_.filter(this.props.tooltipData, function (o) {
                    if (o.fieldId === "genset.fueltype") {
                      return o;
                    }
                  })}
                  fieldId="genset.fueltype"
                  updateTooltip={this.props.updateTooltip}
                />
              </label>
              <input
                type="text"
                id="fuelType"
                className="w-100"
                maxLength="256"
                value={this.state.fuelType}
                onChange={this.handleChange}
              />
            </div>
          </div>

          {/* hours # */}
          <div className="col-2">
            <div className="form-group">
              <label className="form-control-label d-block">
                Hours
                <TextHelper
                  data={_.filter(this.props.tooltipData, function (o) {
                    if (o.fieldId === "genset.hours") {
                      return o;
                    }
                  })}
                  fieldId="genset.hours"
                  updateTooltip={this.props.updateTooltip}
                />
              </label>
              <input
                type="text"
                id="hours"
                className="w-100"
                maxLength="256"
                value={this.state.hours}
                onChange={this.handleChange}
              />
            </div>
          </div>

          {/* comments # */}
          <div className="col-2">
            <div className="form-group">
              <label className="form-control-label d-block">
                Comments
                <TextHelper
                  data={_.filter(this.props.tooltipData, function (o) {
                    if (o.fieldId === "genset.comments") {
                      return o;
                    }
                  })}
                  fieldId="genset.comments"
                  updateTooltip={this.props.updateTooltip}
                />
              </label>
              <input
                type="text"
                id="comments"
                className="w-100"
                maxLength="500"
                value={this.state.comments}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="col-6">
            <div className="form-group mb-0">
              <input
                type="submit"
                className="btn btn-primary"
                formAction="submit"
                value="Submit"
                onClick={this.submitForm.bind(this)}
              />
            </div>
          </div>

          <div className="col-6 text-right">
            <div className="form-group mb-0">
              <input
                type="submit"
                className="btn btn-danger"
                value="Delete"
                onClick={() => this.delete()}
              />
            </div>
          </div>
        </div>
        {this.state.AlertMessage}
      </div>
    );
  }
}

export default GensetRow;
