import React, { Component } from "react";
import TenderRow from "./TenderRow";

const axios = require("axios");
require("dotenv").config();

class Tender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapse: "",
      AlertMessage: false,
      idTable: 0,
      tenderRow: "",
      keyCount: 0,
    };
  }

  handleData = (a) => {
    console.log(`a=${a} boatId=${this.props.idBoat}`);
    if (!isNaN(this.props.idBoat)) {
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatTender/${this.props.idBoat}`,
      })
        .then(function (response) {
          if (response.data.success !== false) {
            var tenderRow = [];

            for (let i = 0; i < response.data.data.results.length; i++) {
              tenderRow.push(
                <TenderRow
                  idBoat={self.props.idBoat}
                  rowId={response.data.data.results[i].id}
                  key={self.state.keyCount++}
                  tooltipData={self.props.tooltipData}
                  updateTooltip={self.props.updateTooltip}
                />
              );
            }
            console.log("new ->" + a);
            if (a === "new") {
              console.log("new ->" + a);
              tenderRow.push(
                <TenderRow
                  idBoat={self.props.idBoat}
                  rowId="0"
                  key={self.state.keyCount++}
                  tooltipData={self.props.tooltipData}
                  updateTooltip={self.props.updateTooltip}
                />
              );
            }

            self.setState({
              showCollapse: "show",
              tenderRow: tenderRow,
            });
          } else {
            if (a === "new") {
              self.setState({
                tenderRow: (
                  <TenderRow
                    idBoat={self.props.idBoat}
                    rowId="0"
                    key={self.state.keyCount++}
                    tooltipData={self.props.tooltipData}
                    updateTooltip={self.props.updateTooltip}
                  />
                ),
              });
            }
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    }
  };

  componentDidMount() {
    this.handleData();
  }

  render() {
    if (this.props.idBoat === "new") {
      return null;
    }

    return (
      <div className="accordion mb-4 mt-4" id="accordion">
        <div className="card">
          <div className="card-header" id={`headingTender`}>
            <h2 className="mb-0">
              <button
                className="btn btn-secondary w-100"
                type="button"
                data-toggle="collapse"
                data-target={`#collapseTender`}
                aria-expanded="true"
                aria-controls="collapseTender"
              >
                Tender
              </button>
            </h2>
          </div>

          <div
            id="collapseTender"
            className={`collapse ${this.state.showCollapse}`}
            aria-labelledby="headingTender"
            data-parent="#accordion"
          >
            <div className="card-body mt-4">
              {this.state.tenderRow}
              <input
                type="submit"
                className="btn btn-secundary border border-success mt-2 ml-2"
                value="Add New Tender"
                onClick={() => this.handleData("new")}
              ></input>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Tender;
