import axios from 'axios';
require('dotenv').config();

const apiEndPoint = `${process.env.REACT_APP_API_URL}website-downloads`;

export async function getDocumentList(boatId) {
  try {
    const response = await axios.get(`${apiEndPoint}/${boatId}`);
    return response.data.results;
  } catch (error) {
    return null;
  }
}

export async function setDocumentStatus(boatId, id, status) {
  try {
    const response = await axios.put(`${apiEndPoint}/${boatId}`, {
      id,
      status,
    });
    return response.data;
  } catch (error) {
    return null;
  }
}
