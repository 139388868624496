import React, { Component } from 'react';
import AlertMessageBootstrap from '../../geral/AlertMessageBootstrap';
import _ from 'lodash';
import TextHelper from '../../utilities/textHelper';

const axios = require('axios');
require('dotenv').config();

class Electrical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapse: '',
      AlertMessage: false,
      idTable: 0,
      // Fields
      electrics: '',
      inverter: '',
      waterHeating: '',
      shorePower: '',
      isolationSwitch: '',
      spotLights: '',
      deckLights: '',
      interiorLighting: '',
      cameraSystems: '',
      underwaterLights: '',
      startBatteries: '',
      houseBatteries: '',
      batteryCharger: '',
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }
  handleChangeSelect = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  componentDidMount() {
    if (this.props.idBoat !== 'new') this.handleData();
  }

  handleData = () => {
    if (!isNaN(this.props.idBoat)) {
      var self = this;
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatElectrical/${this.props.idBoat}`,
      })
        .then(function (response) {
          if (response.data.success !== false) {
            const {
              electrics,
              inverter,
              waterHeating,
              shorePower,
              isolationSwitch,
              spotLights,
              deckLights,
              interiorLighting,
              cameraSystems,
              underwaterLights,
              startBatteries,
              houseBatteries,
              batteryCharger,
              id,
            } = response.data.data.results[0];

            self.setState({
              electrics: electrics,
              inverter: inverter,
              waterHeating: waterHeating,
              shorePower: shorePower,
              isolationSwitch: isolationSwitch,
              spotLights: spotLights,
              deckLights: deckLights,
              interiorLighting: interiorLighting,
              cameraSystems: cameraSystems,
              underwaterLights: underwaterLights,
              startBatteries: startBatteries,
              houseBatteries: houseBatteries,
              batteryCharger: batteryCharger,
              idTable: id,
              showCollapse: 'show',
            });
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    }
  };

  submitForm = () => {
    if (this.state.idTable !== 0) {
      this.updateData();
    } else {
      this.insertData();
    }
  };

  updateData = () => {
    var self = this;
    axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatElectrical/${this.props.idBoat}`,
      data: {
        electrics: this.state.electrics,
        inverter: this.state.inverter,
        waterHeating: this.state.waterHeating,
        shorePower: this.state.shorePower,
        isolationSwitch: this.state.isolationSwitch,
        spotLights: this.state.spotLights,
        deckLights: this.state.deckLights,
        interiorLighting: this.state.interiorLighting,
        cameraSystems: this.state.cameraSystems,
        underwaterLights: this.state.underwaterLights,
        startBatteries: this.state.startBatteries,
        houseBatteries: this.state.houseBatteries,
        batteryCharger: this.state.batteryCharger,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success } = response.data;
        var messageType = 'success';
        var messageDetail = msg;
        if (success === false) {
          messageType = 'danger';
        }
        self.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Erro!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  insertData = () => {
    //console.log("AddMe");
    //console.log(this.state);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatElectrical/${this.props.idBoat}`,
      data: {
        electrics: this.state.electrics,
        inverter: this.state.inverter,
        waterHeating: this.state.waterHeating,
        shorePower: this.state.shorePower,
        isolationSwitch: this.state.isolationSwitch,
        spotLights: this.state.spotLights,
        deckLights: this.state.deckLights,
        interiorLighting: this.state.interiorLighting,
        cameraSystems: this.state.cameraSystems,
        underwaterLights: this.state.underwaterLights,
        startBatteries: this.state.startBatteries,
        houseBatteries: this.state.houseBatteries,
        batteryCharger: this.state.batteryCharger,
        boatId: this.props.idBoat,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success, id } = response.data;
        var messageType = 'success';
        var messageDetail = msg;
        if (success === false) {
          messageType = 'danger';
        }

        this.setState({
          idTable: id,
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Error!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  render() {
    if (this.props.idBoat === 'new') {
      return null;
    }

    return (
      <div className="accordion mb-4 mt-4" id="accordion">
        <div className="card">
          <div className="card-header" id={`headingElectrical`}>
            <h2 className="mb-0">
              <button
                className="btn btn-secondary w-100"
                type="button"
                data-toggle="collapse"
                data-target={`#collapseElectrical`}
                aria-expanded="true"
                aria-controls="collapseElectrical"
              >
                Electrical
              </button>
            </h2>
          </div>

          <div
            id="collapseElectrical"
            className={`collapse ${this.state.showCollapse}`}
            aria-labelledby="headingElectrical"
            data-parent="#accordion"
          >
            <div className="card-body mt-4">
              <div className="row mg-b-25">
                {/* Start Batteries	 # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Start Batteries
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'electrical.startbatteries') {
                            return o;
                          }
                        })}
                        fieldId="electrical.startbatteries"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="startBatteries"
                      className="w-100"
                      maxLength="255"
                      value={this.state.startBatteries}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* House Batteries # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      House Batteries
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'electrical.housebatteries') {
                            return o;
                          }
                        })}
                        fieldId="electrical.housebatteries"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="houseBatteries"
                      className="w-100"
                      maxLength="255"
                      value={this.state.houseBatteries}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/*Battery Charger# */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Battery Charger
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'electrical.batterycharger') {
                            return o;
                          }
                        })}
                        fieldId="electrical.batterycharger"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="batteryCharger"
                      className="w-100"
                      maxLength="255"
                      value={this.state.batteryCharger}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Inverter # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Inverter
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'electrical.inverter') {
                            return o;
                          }
                        })}
                        fieldId="electrical.inverter"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="inverter"
                      className="w-100"
                      maxLength="255"
                      value={this.state.inverter}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Water Heating # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Water Heating
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'electrical.waterheating') {
                            return o;
                          }
                        })}
                        fieldId="electrical.waterheating"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="waterHeating"
                      className="w-100"
                      maxLength="255"
                      value={this.state.waterHeating}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Shore Power # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Shore Power
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'electrical.shorepower') {
                            return o;
                          }
                        })}
                        fieldId="electrical.shorepower"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="shorePower"
                      className="w-100"
                      maxLength="255"
                      value={this.state.shorePower}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Isolation Switch # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Isolation Switch
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'electrical.isolationswitch') {
                            return o;
                          }
                        })}
                        fieldId="electrical.isolationswitch"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="isolationSwitch"
                      className="w-100"
                      maxLength="255"
                      value={this.state.isolationSwitch}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Spotlight # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Spotlight
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'electrical.spotlight') {
                            return o;
                          }
                        })}
                        fieldId="electrical.spotlight"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="spotLights"
                      className="w-100"
                      maxLength="255"
                      value={this.state.spotLights}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Deck Lights # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Deck Lights
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'electrical.decklight') {
                            return o;
                          }
                        })}
                        fieldId="electrical.decklight"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="deckLights"
                      className="w-100"
                      maxLength="255"
                      value={this.state.deckLights}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Interior Lighting # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Interior Lighting
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'electrical.interiorlighting') {
                            return o;
                          }
                        })}
                        fieldId="electrical.interiorlighting"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="interiorLighting"
                      className="w-100"
                      maxLength="255"
                      value={this.state.interiorLighting}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Camera Systems# */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Camera Systems
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'electrical.camerasystems') {
                            return o;
                          }
                        })}
                        fieldId="electrical.camerasystems"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="cameraSystems"
                      className="w-100"
                      maxLength="255"
                      value={this.state.cameraSystems}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Underwater Lights # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Underwater Lights
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'electrical.underwaterlights') {
                            return o;
                          }
                        })}
                        fieldId="electrical.underwaterlights"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="underwaterLights"
                      className="w-100"
                      maxLength="255"
                      value={this.state.underwaterLights}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Electrics # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Electrics
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'electrical.electrics') {
                            return o;
                          }
                        })}
                        fieldId="electrical.electrics"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="electrics"
                      className="w-100"
                      maxLength="500"
                      value={this.state.electrics}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <input
                  type="submit"
                  className="btn btn-primary mb-4"
                  formAction="submit"
                  value="Submit Engineering"
                  onClick={this.submitForm.bind(this)}
                />
                {this.state.AlertMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Electrical;
