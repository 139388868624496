import React, { Component } from "react";
import { Link } from "react-router-dom";
//import SupportModal from "./SupportModal";

class SideMenu extends Component {
  render() {
    return (
      <div className="br-sideleft overflow-y-auto">
        <label className="sidebar-label pd-x-15 mg-t-20">Navigation</label>
        <div className="br-sideleft-menu">
          <a href="/admin/boats/?status=1" className="br-menu-link">
            <div className="br-menu-item">
              <i className="menu-item-icon icon ion-ios-gear-outline tx-24"></i>
              <span className="menu-item-label">Boats</span>
            </div>
          </a>
          <a href="/admin/boats/" className="nav-link text-muted">
            - All
          </a>
          <a href="/admin/boats/?&status=1" className="nav-link text-muted">
            - Approved
          </a>
          <a href="/admin/boats/?&status=3" className="nav-link text-muted">
            - Sold
          </a>
          <a href="/admin/boats/?&status=4" className="nav-link text-muted">
            - Under Offer
          </a>
          <a href="/admin/boats/?&status=7" className="nav-link text-muted">
            - Inactive
          </a>
          <a href="/admin/boats/?&status=6" className="nav-link text-muted">
            - Pending Approval
          </a>

          <Link to="/" className="br-menu-link">
            <div className="br-menu-item">
              <i className="menu-item-icon icon ion-ios-briefcase-outline tx-22"></i>
              <span className="menu-item-label">Utilities</span>
            </div>
          </Link>

          <Link to="/utilities/locations" className="nav-link text-muted">
            - Company Locations
          </Link>

          <Link to="/utilities/manufacturer" className="nav-link text-muted">
            - Manufacturer
          </Link>

          <Link to="/utilities/category" className="nav-link text-muted">
            - Category
          </Link>

          <Link to="/utilities/users" className="nav-link text-muted mb-4">
            - Users
          </Link>

          {/* <SupportModal /> */}
        </div>

        <div className="info-list">
          <label className="sidebar-label pd-x-15 mg-t-25 mg-b-20 tx-info op-9"></label>
          <div></div>
        </div>
      </div>
    );
  }
}

export default SideMenu;
