import axios from "axios";
require("dotenv").config();

const apiEndPoint = `${process.env.REACT_APP_API_URL}/tooltip`;

export async function getTooltipData() {
  try {
    const response = await axios.get(`${apiEndPoint}`);
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function setTooltip(data) {
  try {
    const response = await axios.post(`${apiEndPoint}`, data);
    return response.data;
  } catch (error) {
    return null;
  }
}
