import React, { Component } from "react";
const axios = require("axios");
require("dotenv").config();

class Yachthub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: ""
    };
  }

  handleData = () => {
    var self = this;
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}yachthubfeed/status/${this.props.idBoat}`
    })
      .then(function(response) {
        if (response.data.success === true) {
          self.setState({
            result: (
              <div class="card mt-3">
                <div class="card-header">YachtHub Details</div>
                <div class="card-body">
                  <p class="mt-3">
                    <strong>Id YachtHub:</strong>{" "}
                    {response.data.data.idYachtHub}
                  </p>
                  <p class="mt-3">
                    <strong>Created:</strong> {response.data.data.adDate}
                  </p>
                  <p class="mt-3">
                    <strong>Last Update:</strong>{" "}
                    {response.data.data.lastUpdate}
                  </p>
                </div>
              </div>
            )
          });
        }
      })
      .catch(function(response) {
        console.log(response);
      });
  };

  componentDidMount() {
    this.handleData();
  }

  render() {
    if (this.props.idBoat === "new") {
      return null;
    }

    return <div>{this.state.result}</div>;
  }
}

export default Yachthub;
