import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";

const axios = require("axios");

require("dotenv").config();

class ManufacturerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AlertMessage: false,
      handleError: false,
      showData: false,
      redirectPage: false,
      //dataFields
      title: ""
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
  }

  updateData = () => {
    const { id } = this.props.match.params;
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}manufacturer/${localStorage.userDealerId}/${id}`,
      data: {
        title: this.state.title
      }
    })
      .then(response => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          )
        });
      })
      .catch(function(response) {
        console.log(response);
        const { msg } = response.data;
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message={msg}
              unmountMe={this.handleCloseAlert}
            />
          )
        });
      });
  };

  insertData = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}manufacturer/${localStorage.userDealerId}`,
      data: {
        title: this.state.title,
        idDealer: localStorage.userDealerId
      }
    })
      .then(response => {
        const { msg, success, id } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }

        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
          redirectPage: <Redirect to={`utilities/manufacturer/${id}`} />
        });
      })
      .catch(function(response) {
        console.log(response);
        const { msg } = response.data;
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message={msg}
              unmountMe={this.handleCloseAlert}
            />
          )
        });
      });
  };

  submitForm = () => {
    var msgErro = "";
    const { title } = this.state;
    if (title === "") {
      msgErro += "Title, ";
    }

    if (msgErro !== "") {
      msgErro = msgErro.substring(0, msgErro.length - 2);
      this.setState({
        AlertMessage: (
          <AlertMessageBootstrap
            messageType="danger"
            message={`Please fill the required field: ${msgErro}`}
            unmountMe={this.handleCloseAlert}
          />
        )
      });
      return false;
    }

    if (!isNaN(this.props.match.params.id)) {
      this.updateData();
    } else {
      this.insertData();
    }
  };

  handleData = () => {
    const { id } = this.props.match.params;
    if (!isNaN(id)) {
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}manufacturer/${localStorage.userDealerId}/${id}`
      })
        .then(function(response) {
          if (response.data.success === false) {
            self.setState({ handleError: <Redirect to="/404Error" /> });
          }
          const { title } = response.data.data.results[0];

          self.setState({
            title: title
          });
        })
        .catch(function(response) {
          console.log(response);
        });
    }
  };

  handleChange = e => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  componentDidMount() {
    if (this.props.match.params.id !== "new") this.handleData();
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }

  render() {
    return (
      <div className="br-mainpanel">
        {this.state.handleError}
        {this.state.redirectPage}
        <div className="br-pageheader pd-y-15 pd-l-20">
          <nav className="breadcrumb pd-0 mg-0 tx-12">
            <a className="breadcrumb-item" href="index.html">
              Boat Sync
            </a>
            <span className="breadcrumb-item active">Utilities</span>
            <span className="breadcrumb-item active">Manufacturer</span>
          </nav>
        </div>

        <div className="d-flex align-items-center justify-content-between mg-b-30 pd-x-30 pd-t-30">
          <div>
            <h4 className="tx-gray-800 mg-b-5">Manufacturers</h4>
          </div>

          <div>
            <Link
              to="/utilities/manufacturer/"
              className="btn btn-primary btn-oblong tx-11 tx-uppercase tx-mont tx-medium tx-spacing-1 pd-x-30 bd-2"
            >
              Go Back
            </Link>
          </div>
        </div>

        <div className="br-pagebody">
          <div className="br-section-wrapper">
            <div className="table-wrapper">
              <div className="form-layout">
                {/*  { this.state . AlertMessage } */}
                <div className="row mg-b-25">
                  {/* Title # */}
                  <div className="col-12">
                    <div className="form-group">
                      <label className="form-control-label d-block">
                        Manufacture Title # <span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="title"
                        maxLength="255"
                        value={this.state.title}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <hr />
                  <input
                    type="submit"
                    className="btn btn-primary mb-4"
                    formAction="submit"
                    onClick={this.submitForm.bind(this)}
                  />
                  {this.state.AlertMessage}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManufacturerForm;
