import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const axios = require("axios");

class AmtPresentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myDocument: 0,
      showDocument: 0,
      downloadStatus: "",
      displayCreateBt: "",
    };
  }

  componentDidMount() {
    this.setState({
      myDocument: this.props.myDocument,
      showDocument: this.props.showDocument,
    });
  }

  handleCreate = () => {
    var self = this;
    const { boatId } = this.props;

    this.setState({
      myDocument: 0,
      displayCreateBt: "d-none",
      downloadStatus: (
        <p classNam="badge badge-secondary p-4 w-100 my-3">
          We are creating your PDF... It will be ready soon.
        </p>
      ),
    });
    axios({
      method: "get",
      url: `${process.env.REACT_APP_PDF_GENERATOR}boat/createamtpresentation/${boatId}/16/${localStorage.userDealerId}`,
      timeout: 120000,
    })
      .then(function (response) {
        console.log(response.data);
        if (response.data.success === false) {
          self.setState({
            downloadStatus: (
              <span className="badge badge-danger p-2  d-block">
                Error to create your PDF!
              </span>
            ),
            myDocument: 0,
            displayCreateBt: "",
          });
        }

        //const dowloadbleUrl = response.data.data.dowloadbleUrl;

        //messageType = "danger";
        self.setState({
          downloadStatus: "",
          myDocument: 1,
          displayCreateBt: "",
        });
      })
      .catch(function (response) {
        console.log("erro 420");
        self.setState({
          downloadStatus: (
            <span className="badge badge-danger p-2  d-block">
              Error to create your PDF!
            </span>
          ),
          displayCreateBt: "",
          myDocument: 0,
        });
      });
  };

  handleShow = () => {
    const { boatId } = this.props;
    const { showDocument } = this.state;
    var updateTo = 0;
    if (showDocument === 0) {
      updateTo = 1;
      this.setState({ showDocument: 1 });
    } else {
      updateTo = 0;
      this.setState({ showDocument: 0 });
    }

    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}boat/showpresentation/${boatId}`,
      data: { showpresentation: updateTo },
    })
      .then(function (response) {})
      .catch(function (response) {
        console.log("erro 94");
      });
  };

  render() {
    var { myDocument, displayCreateBt } = this.state;
    return (
      <div class="card mt-3">
        <div class="card-header">AMT Presentation</div>
        <div class="card-body">
          <span
            className={`btn btn-primary ${displayCreateBt}`}
            onClick={this.handleCreate}
          >
            Create/Update Presentation
          </span>
          {this.state.downloadStatus}
          {myDocument === 1 ? (
            <>
              <p className="my-3">
                <a
                  href={`${process.env.REACT_APP_PDF_GENERATOR}public/presentation/5/${this.props.boatId}-AMT-Presentation.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here
                </a>{" "}
                to download your presentation
              </p>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.showDocument === 1 ? true : false}
                    color="primary"
                    onChange={this.handleShow}
                  />
                }
                label="Available on the website"
                className="w-100"
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default AmtPresentation;
