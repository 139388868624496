import React, { Component } from "react";
import { Redirect } from "react-router-dom";

const axios = require("axios");

require("dotenv").config();

class SelectBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      options: "",
    };
  }

  handleChange = (e) => {
    const value = e.target.value;
    this.setState({ value: e.target.value });
    this.props.handleChange(this.props.name, value);
  };

  componentDidMount() {
    var optionList = [];

    var self = this;

    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}${this.props.table}/${localStorage.userDealerId}`,
    }).then(function (response) {
      if (response.data.success === false) {
        return <Redirect to="/404Error" />;
      }
      for (let i = 0; i < response.data.data.results.length; i++) {
        const { id, title } = response.data.data.results[i];
        if (!self.props.ignore || self.props.ignore !== id) {
          optionList.push(
            <option value={id} key={id}>
              {title}
            </option>
          );
        }
      }

      self.setState({ options: optionList });

      setTimeout(() => {
        self.setState({ value: self.props.value });
      }, 100);
    });
  }

  render() {
    return (
      <React.Fragment>
        <select
          value={this.props.value}
          onChange={this.handleChange}
          className={this.props.mystyle}
        >
          <option></option>
          {this.state.options}
        </select>
      </React.Fragment>
    );
  }
}

export default SelectBox;
