import React, { Component } from "react";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";
import _ from "lodash";
import TextHelper from "../../utilities/textHelper";

const axios = require("axios");
require("dotenv").config();

class Stabiliser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapse: "",
      AlertMessage: false,
      idTable: 0,
      // Fields
      stabiliserType: "",
      stabiliserAtRest: "",
      stabiliserMake: "",
      stabiliserModel: "",
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }
  handleChangeSelect = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  componentDidMount() {
    if (this.props.idBoat !== "new") this.handleData();
  }

  handleData = () => {
    if (!isNaN(this.props.idBoat)) {
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatStabiliser/${this.props.idBoat}`,
      })
        .then(function (response) {
          if (response.data.success !== false) {
            const {
              stabiliserType,
              stabiliserAtRest,
              stabiliserMake,
              stabiliserModel,
              id,
            } = response.data.data.results[0];

            self.setState({
              stabiliserType: stabiliserType,
              stabiliserAtRest: stabiliserAtRest,
              stabiliserMake: stabiliserMake,
              stabiliserModel: stabiliserModel,
              idTable: id,
              showCollapse: "show",
            });
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    }
  };

  submitForm = () => {
    if (this.state.idTable !== 0) {
      this.updateData();
    } else {
      this.insertData();
    }
  };

  updateData = () => {
    var self = this;
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatStabiliser/${this.props.idBoat}`,
      data: {
        stabiliserType: this.state.stabiliserType,
        stabiliserAtRest: this.state.stabiliserAtRest,
        stabiliserMake: this.state.stabiliserMake,
        stabiliserModel: this.state.stabiliserModel,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        self.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Erro!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  insertData = () => {
    //console.log("AddMe");
    //console.log(this.state);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatStabiliser/${this.props.idBoat}`,
      data: {
        stabiliserType: this.state.stabiliserType,
        stabiliserAtRest: this.state.stabiliserAtRest,
        stabiliserMake: this.state.stabiliserMake,
        stabiliserModel: this.state.stabiliserModel,
        boatId: this.props.idBoat,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success, id } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }

        this.setState({
          idTable: id,
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Error!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  render() {
    if (this.props.idBoat === "new") {
      return null;
    }

    return (
      <div className="accordion mb-4 mt-4" id="accordion">
        <div className="card">
          <div className="card-header" id={`headingStabiliser`}>
            <h2 className="mb-0">
              <button
                className="btn btn-secondary w-100"
                type="button"
                data-toggle="collapse"
                data-target={`#collapseStabiliser`}
                aria-expanded="true"
                aria-controls="collapseStabiliser"
              >
                Stabiliser
              </button>
            </h2>
          </div>

          <div
            id="collapseStabiliser"
            className={`collapse ${this.state.showCollapse}`}
            aria-labelledby="headingStabiliser"
            data-parent="#accordion"
          >
            <div className="card-body mt-4">
              <div className="row mg-b-25">
                {/* Type # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Type
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "stabiliser.type") {
                            return o;
                          }
                        })}
                        fieldId="stabiliser.type"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="stabiliserType"
                      className="w-100"
                      maxLength="255"
                      value={this.state.stabiliserType}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/*  At Rest # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      At Rest
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "stabiliser.atrest") {
                            return o;
                          }
                        })}
                        fieldId="stabiliser.atrest"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="stabiliserAtRest"
                      className="w-100"
                      maxLength="255"
                      value={this.state.stabiliserAtRest}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Make # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Make
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "stabiliser.make") {
                            return o;
                          }
                        })}
                        fieldId="stabiliser.make"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="stabiliserMake"
                      className="w-100"
                      maxLength="255"
                      value={this.state.stabiliserMake}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Model # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Model
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "stabiliser.model") {
                            return o;
                          }
                        })}
                        fieldId="stabiliser.model"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="stabiliserModel"
                      className="w-50"
                      maxLength="255"
                      value={this.state.stabiliserModel}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="col-8"></div>
              </div>

              <div>
                <hr />
                <input
                  type="submit"
                  className="btn btn-primary mb-4"
                  formAction="submit"
                  value="Submit Stabiliser"
                  onClick={this.submitForm.bind(this)}
                />
                {this.state.AlertMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Stabiliser;
