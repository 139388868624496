import React, { Component } from "react";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";
import _ from "lodash";
import TextHelper from "../../utilities/textHelper";

const axios = require("axios");
require("dotenv").config();

class SellerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapse: "",
      AlertMessage: false,
      idTable: 0,
      // Fields
      sellerName: "",
      sellerABN: "",
      sellerAddress: "",
      sellerPhone: "",
      sellerWorkPhone: "",
      sellerMobile: "",
      sellerFax: "",
      sellerEmail: "",
      sellerGSTRegistered: 0,
      sellerDOB: "",
      sellerDriverLicense: "",
      sellerMarketingCategory: "",
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }
  handleChangeSelect = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  handleChangeCheckbox = () => {
    if (this.state.sellerGSTRegistered === 0) {
      this.setState({ sellerGSTRegistered: 1 });
    } else {
      this.setState({ sellerGSTRegistered: 0 });
    }
  };
  sellerGSTRegistered;

  componentDidMount() {
    if (this.props.idBoat !== "new") this.handleData();
  }

  handleData = () => {
    if (!isNaN(this.props.idBoat)) {
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatSellerDetails/${this.props.idBoat}`,
      })
        .then(function (response) {
          if (response.data.success !== false) {
            const {
              sellerName,
              sellerABN,
              sellerAddress,
              sellerPhone,
              sellerWorkPhone,
              sellerMobile,
              sellerFax,
              sellerEmail,
              sellerGSTRegistered,
              sellerDOB,
              sellerDriverLicense,
              sellerMarketingCategory,
              id,
            } = response.data.data.results[0];

            self.setState({
              sellerName: sellerName,
              sellerABN: sellerABN,
              sellerAddress: sellerAddress,
              sellerPhone: sellerPhone,
              sellerWorkPhone: sellerWorkPhone,
              sellerMobile: sellerMobile,
              sellerFax: sellerFax,
              sellerEmail: sellerEmail,
              sellerGSTRegistered: sellerGSTRegistered,
              sellerDOB: sellerDOB,
              sellerDriverLicense: sellerDriverLicense,
              sellerMarketingCategory: sellerMarketingCategory,
              idTable: id,
              showCollapse: "show",
            });
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    }
  };

  submitForm = () => {
    if (this.state.idTable !== 0) {
      this.updateData();
    } else {
      this.insertData();
    }
  };

  updateData = () => {
    var self = this;
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatSellerDetails/${this.props.idBoat}`,
      data: {
        sellerName: this.state.sellerName,
        sellerABN: this.state.sellerABN,
        sellerAddress: this.state.sellerAddress,
        sellerPhone: this.state.sellerPhone,
        sellerWorkPhone: this.state.sellerWorkPhone,
        sellerMobile: this.state.sellerMobile,
        sellerFax: this.state.sellerFax,
        sellerEmail: this.state.sellerEmail,
        sellerGSTRegistered: this.state.sellerGSTRegistered,
        sellerDOB: this.state.sellerDOB,
        sellerDriverLicense: this.state.sellerDriverLicense,
        sellerMarketingCategory: this.state.sellerMarketingCategory,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        self.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Erro!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  insertData = () => {
    //console.log("AddMe");
    //console.log(this.state);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatSellerDetails/${this.props.idBoat}`,
      data: {
        sellerName: this.state.sellerName,
        sellerABN: this.state.sellerABN,
        sellerAddress: this.state.sellerAddress,
        sellerPhone: this.state.sellerPhone,
        sellerWorkPhone: this.state.sellerWorkPhone,
        sellerMobile: this.state.sellerMobile,
        sellerFax: this.state.sellerFax,
        sellerEmail: this.state.sellerEmail,
        sellerGSTRegistered: this.state.sellerGSTRegistered,
        sellerDOB: this.state.sellerDOB,
        sellerDriverLicense: this.state.sellerDriverLicense,
        sellerMarketingCategory: this.state.sellerMarketingCategory,
        boatId: this.props.idBoat,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success, id } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }

        this.setState({
          idTable: id,
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Error!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  render() {
    if (this.props.idBoat === "new") {
      return null;
    }

    return (
      <div className="accordion mb-4 mt-4" id="accordion">
        <div className="card">
          <div className="card-header" id={`headingSellerDetails`}>
            <h2 className="mb-0">
              <button
                className="btn btn-secondary w-100"
                type="button"
                data-toggle="collapse"
                data-target={`#collapseSellerDetails`}
                aria-expanded="true"
                aria-controls="collapseSellerDetails"
              >
                Seller Details
              </button>
            </h2>
          </div>

          <div
            id="collapseSellerDetails"
            className={`collapse ${this.state.showCollapse}`}
            aria-labelledby="headingSellerDetails"
            data-parent="#accordion"
          >
            <div className="card-body mt-4">
              <div className="row mg-b-25">
                {/* Name # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Name
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "sellerdetails.name") {
                            return o;
                          }
                        })}
                        fieldId="sellerdetails.name"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="sellerName"
                      className="w-100"
                      maxLength="100"
                      value={this.state.sellerName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* ABN # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      ABN
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "sellerdetails.abn") {
                            return o;
                          }
                        })}
                        fieldId="sellerdetails.abn"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="sellerABN"
                      className="w-100"
                      maxLength="500"
                      value={this.state.sellerABN}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/*Address# */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Address
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "sellerdetails.address") {
                            return o;
                          }
                        })}
                        fieldId="sellerdetails.address"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="sellerAddress"
                      className="w-100"
                      maxLength="1000"
                      value={this.state.sellerAddress}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Phone # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Phone
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "sellerdetails.phone") {
                            return o;
                          }
                        })}
                        fieldId="sellerdetails.phone"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="sellerPhone"
                      className="w-100"
                      maxLength="500"
                      value={this.state.sellerPhone}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Work Phone # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Work Phone
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "sellerdetails.workphone") {
                            return o;
                          }
                        })}
                        fieldId="sellerdetails.workphone"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="sellerWorkPhone"
                      className="w-100"
                      maxLength="500"
                      value={this.state.sellerWorkPhone}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Mobile # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Mobile
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "sellerdetails.mobile") {
                            return o;
                          }
                        })}
                        fieldId="sellerdetails.mobile"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="sellerMobile"
                      className="w-100"
                      maxLength="500"
                      value={this.state.sellerMobile}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Fax # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Fax
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "sellerdetails.fax") {
                            return o;
                          }
                        })}
                        fieldId="sellerdetails.fax"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="sellerFax"
                      className="w-100"
                      maxLength="500"
                      value={this.state.sellerFax}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Email # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Email
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "sellerdetails.email") {
                            return o;
                          }
                        })}
                        fieldId="sellerdetails.email"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="sellerEmail"
                      className="w-100"
                      maxLength="500"
                      value={this.state.sellerEmail}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* GST Registered # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      GST Registered
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "sellerdetails.gstreg") {
                            return o;
                          }
                        })}
                        fieldId="sellerdetails.gstreg"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="checkbox"
                      id="sellerGSTRegistered"
                      value={this.state.sellerGSTRegistered}
                      onChange={this.handleChangeCheckbox}
                    />
                  </div>
                </div>

                {/* DOB # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      DOB
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "sellerdetails.dbo") {
                            return o;
                          }
                        })}
                        fieldId="sellerdetails.dbo"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="sellerDOB"
                      className="w-100"
                      maxLength="500"
                      value={this.state.sellerDOB}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Drivers License # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Drivers License
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "sellerdetails.driverlicense") {
                            return o;
                          }
                        })}
                        fieldId="sellerdetails.driverlicense"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="sellerDriverLicense"
                      className="w-100"
                      maxLength="500"
                      value={this.state.sellerDriverLicense}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Marketing Category # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Marketing Category
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "sellerdetails.marketingcategory") {
                            return o;
                          }
                        })}
                        fieldId="sellerdetails.marketingcategory"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="sellerMarketingCategory"
                      className="w-100"
                      maxLength="500"
                      value={this.state.sellerMarketingCategory}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <input
                  type="submit"
                  className="btn btn-primary mb-4"
                  formAction="submit"
                  value="Submit Seller Details"
                  onClick={this.submitForm.bind(this)}
                />
                {this.state.AlertMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SellerDetails;
