import React, { Component } from "react";
import { setTimeout } from "timers";

const axios = require("axios");

class AlexanderMarinePresentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBtCreate: false,
      formMsg: null,
      myDocument: 0,
      showDocument: 0,
      downloadStatus: "",
      displayCreateBt: "",
      details: {},
    };
  }

  componentDidMount() {
    this.setState({
      myDocument: this.props.myDocument,
      showDocument: this.props.showDocument,
    });
    this.fechData();
  }

  fechData = () => {
    const { boatId } = this.props;
    var self = this;
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}alexandermarine/${boatId}`,
    })
      .then(function (response) {
        console.log(response.data);
        if (response.data.success === true) {
          self.setState({ details: response.data.data, showBtCreate: true });
        }
      })
      .catch(function (response) {
        console.log("erro fetch!");
      });
  };

  handleSubmit = () => {
    const boatId = this.props.boatId;
    const formData = this.state.details;
    var self = this;
    console.log({ formData });
    self.setState({ formMessage: <p>Processing...</p> });

    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}alexandermarine/${boatId}`,
      data: formData,
    })
      .then(function (response) {
        if (response.data.success === true) {
          self.setState({
            formMessage: (
              <p className="text-success">Data updated successfully!</p>
            ),
            showBtCreate: true,
          });
          setTimeout(() => {
            self.setState({ formMessage: null });
          }, 1100);
          return;
        } else {
          self.setState({
            formMessage: <p className="text-danger">Something went wrong.</p>,
          });

          setTimeout(() => {
            self.setState({ formMessage: null });
          }, 1100);
        }
      })
      .catch(function (response) {
        self.setState({
          formMessage: (
            <p className="text-danger">
              Something went wrong. Data not updated.
            </p>
          ),
        });
        setTimeout(() => {
          self.setState({ formMessage: null });
        }, 1100);
      });

    self.setState({ formMessage: null });
  };

  handleCreate = () => {
    var self = this;
    const { boatId } = this.props;

    this.setState({
      myDocument: 0,
      displayCreateBt: "d-none",
      downloadStatus: (
        <p classNam="badge badge-secondary p-4 w-100 my-3">
          We are creating your PDF... It will be ready soon.
        </p>
      ),
    });
    axios({
      method: "get",
      url: `${process.env.REACT_APP_PDF_GENERATOR}alexandermarine/create/${boatId}`,
      timeout: 120000,
    })
      .then(function (response) {
        console.log(response.data);
        if (response.data.success === false) {
          self.setState({
            downloadStatus: (
              <span className="badge badge-danger p-2  d-block">
                Error to create your PDF!
              </span>
            ),
            myDocument: 0,
            displayCreateBt: "",
          });
        }

        self.setState({
          downloadStatus: "",
          myDocument: 1,
          displayCreateBt: "",
        });
      })
      .catch(function (response) {
        console.log("erro 420");
        self.setState({
          downloadStatus: (
            <span className="badge badge-danger p-2  d-block">
              Error to create your PDF!
            </span>
          ),
          displayCreateBt: "",
          myDocument: 0,
        });
      });
  };

  handleShow = () => {
    const { boatId } = this.props;
    const { showDocument } = this.state;
    var updateTo = 0;
    if (showDocument === 0) {
      updateTo = 1;
      this.setState({ showDocument: 1 });
    } else {
      updateTo = 0;
      this.setState({ showDocument: 0 });
    }

    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}boat/showpresentation/${boatId}`,
      data: { showpresentation: updateTo },
    })
      .then(function (response) {})
      .catch(function (response) {
        console.log("erro 94");
      });
  };

  handleChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    let varDetails = this.state.details;
    varDetails[id] = value;
    this.setState({ details: varDetails });
  };

  render() {
    var { myDocument, displayCreateBt } = this.state;
    return (
      <div class="card mt-3">
        <div class="card-header">Brochure</div>
        <div class="card-body">
          <div className="row">
            <div className="col-12 col-md-4 d-block">
              <div className="form-group">
                <label className="form-control-label d-block">
                  Vessel Name
                </label>
                <input
                  type="text"
                  id="vessel_name"
                  className="w-100"
                  maxLength="250"
                  value={this.state.details.vessel_name || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-4 d-block">
              <div className="form-group">
                <label className="form-control-label d-block">
                  Main Vessel Tagline
                </label>
                <input
                  type="text"
                  id="main_vessel_tagline"
                  className="w-100"
                  maxLength="256"
                  value={this.state.details.main_vessel_tagline || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-4 d-block">
              <div className="form-group">
                <label className="form-control-label d-block">
                  Heading One
                </label>
                <input
                  type="text"
                  id="heading_one"
                  className="w-100"
                  maxLength="256"
                  value={this.state.details.heading_one || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-4 d-block">
              <div className="form-group">
                <label className="form-control-label d-block">Body Copy</label>
                <textarea
                  id="body_copy"
                  className="w-100"
                  value={this.state.details.body_copy}
                  onChange={this.handleChange}
                  style={{ height: "150px" }}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <h3>Principal Specifications</h3>
            </div>

            {/* Line 1*/}

            <div className="col-8 col-md-4 d-block">
              <div className="form-group">
                <label className="form-control-label d-block">Title</label>
                <input
                  type="text"
                  id="specific_1"
                  className="w-100"
                  maxLength="256"
                  value={this.state.details.specific_1 || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="col-4 col-md-2 d-block">
              <div className="form-group">
                <label className="form-control-label d-block">Value</label>
                <input
                  type="text"
                  id="specific_1_value"
                  className="w-100"
                  maxLength="256"
                  value={this.state.details.specific_1_value || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-8 col-md-4 d-block">
              <div className="form-group">
                <input
                  type="text"
                  id="specific_2"
                  className="w-100"
                  maxLength="256"
                  value={this.state.details.specific_2 || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="col-4 col-md-2 d-block">
              <div className="form-group">
                <input
                  type="text"
                  id="specific_2_value"
                  className="w-100"
                  maxLength="256"
                  value={this.state.details.specific_2_value || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-8 col-md-4 d-block">
              <div className="form-group">
                <input
                  type="text"
                  id="specific_3"
                  className="w-100"
                  maxLength="256"
                  value={this.state.details.specific_3 || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="col-4 col-md-2 d-block">
              <div className="form-group">
                <input
                  type="text"
                  id="specific_3_value"
                  className="w-100"
                  maxLength="256"
                  value={this.state.details.specific_3_value || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-8 col-md-4 d-block">
              <div className="form-group">
                <input
                  type="text"
                  id="specific_4"
                  className="w-100"
                  maxLength="256"
                  value={this.state.details.specific_4 || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="col-4 col-md-2 d-block">
              <div className="form-group">
                <input
                  type="text"
                  id="specific_4_value"
                  className="w-100"
                  maxLength="256"
                  value={this.state.details.specific_4_value || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-8 col-md-4 d-block">
              <div className="form-group">
                <input
                  type="text"
                  id="specific_5"
                  className="w-100"
                  maxLength="256"
                  value={this.state.details.specific_5 || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="col-4 col-md-2 d-block">
              <div className="form-group">
                <input
                  type="text"
                  id="specific_5_value"
                  className="w-100"
                  maxLength="256"
                  value={this.state.details.specific_5_value || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-8 col-md-4 d-block">
              <div className="form-group">
                <input
                  type="text"
                  id="specific_6"
                  className="w-100"
                  maxLength="256"
                  value={this.state.details.specific_6 || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="col-4 col-md-2 d-block">
              <div className="form-group">
                <input
                  type="text"
                  id="specific_6_value"
                  className="w-100"
                  maxLength="256"
                  value={this.state.details.specific_6_value || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-8 col-md-4 d-block">
              <div className="form-group">
                <input
                  type="text"
                  id="specific_7"
                  className="w-100"
                  maxLength="256"
                  value={this.state.details.specific_7 || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="col-4 col-md-2 d-block">
              <div className="form-group">
                <input
                  type="text"
                  id="specific_7_value"
                  className="w-100"
                  maxLength="256"
                  value={this.state.details.specific_7_value || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <h3>Features</h3>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <textarea
                  id="features_col_1"
                  className="w-100"
                  value={this.state.details.features_col_1 || ""}
                  onChange={this.handleChange}
                  style={{ height: "150px" }}
                ></textarea>
              </div>
            </div>
            <div className="col-12 col-md-6 d-none">
              <div className="form-group">
                <label className="form-control-label d-block">
                  Right Column
                </label>
                <textarea
                  id="features_col_2"
                  className="w-100"
                  value={this.state.details.features_col_2 || ""}
                  onChange={this.handleChange}
                  style={{ height: "150px" }}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              {this.state.formMessage ? (
                this.state.formMessage
              ) : (
                <span
                  className={`btn btn-primary ${displayCreateBt}`}
                  onClick={this.handleSubmit}
                >
                  Submit Content
                </span>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-4">
              <span
                className={`btn btn-primary ${displayCreateBt} ${
                  !this.state.showBtCreate ? "d-none" : ""
                }`}
                onClick={this.handleCreate}
              >
                Create/Update Brochure
              </span>
            </div>
          </div>

          {this.state.downloadStatus}
          {myDocument === 1 ? (
            <>
              <p className="my-3">
                <a
                  href={`${process.env.REACT_APP_PDF_GENERATOR}public/presentation/11/${this.props.boatId}-brochure.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here
                </a>{" "}
                to download
              </p>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default AlexanderMarinePresentation;
