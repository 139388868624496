import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const axios = require("axios");

require("dotenv").config();

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

class DuplicateABoatModal extends Component {
  constructor(props) {
    super(props);
    this.state = { open: true, setOpen: true };
  }

  handleClickOpen = () => {
    this.setState({ setOpen: true });
  };
  handleClose = () => {
    this.setState({ setOpen: false });
    this.props.onClose();
  };
  duplicateTheBoat = () => {
    var self = this;
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}boat-duplicate/${self.props.boatId}`
    })
      .then(function(response) {
        if (response.data.success === false) {
          self.setState({
            message: (
              <div className="mb-4 mt-4">
                Error to duplicate the boat {self.props.id}.<br />
                Please contact our support team.
              </div>
            )
          });
        }
        self.setState({
          message: (
            <div className="mb-4 mt-4">
              The <strong>{self.props.boatName}</strong> boat was successfully
              duplicated!
              <br /> <br />
              <a href={response.data.data.id}>Click here</a> to edit the new
              boat.
            </div>
          )
        });
      })
      .catch(function(response) {
        self.setState({
          message: (
            <div className="mb-4 mt-4">
              Error to duplicate the boat {self.props.boatId}.<br />
              Please contact our support team.
            </div>
          )
        });
        console.log(response);
      });
  };

  componentDidMount() {
    this.duplicateTheBoat();
  }

  render() {
    return (
      <div>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Duplicated Boat
          </DialogTitle>
          <DialogContent dividers>{this.state.message}</DialogContent>
        </Dialog>
      </div>
    );
  }
}
export default DuplicateABoatModal;
