import React from "react";

class Logo extends React.Component {
  render() {
    return (
      <div className="br-logo">
        <a href="/">
          <img srcSet="/img/main-logo.png" className="logo-sidebar" alt="" />
        </a>
      </div>
    );
  }
}

export default Logo;
