import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import ModalDialogDelete from "../geral/ModalDialogDelete";

class BoatRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectPage: false,
      modalDelete: false,
      showMe: true,
    };
  }

  handleClose = (id) => {
    this.setState({ showMe: false });
    this.props.handleDelete(id);
  };

  handleChildUnmount = () => {
    this.setState({
      modalDelete: false,
    });
  };

  processDelete = (id, title) => {
    this.setState({
      modalDelete: (
        <ModalDialogDelete
          name={title}
          idRow={id}
          unmountMe={this.handleChildUnmount}
          deleteMe={() => this.handleClose(id)}
        />
      ),
    });
  };

  handleEdit = (id) => {
    this.setState({
      redirectPage: <Redirect to={`/admin/boats/${id}`} />,
    });
  };

  render() {
    const {
      id,
      adTypeTitle,
      title,
      price,
      boatStatusTitle,
      thumbName,
      stockNumber,
    } = this.props.data;
    var showThumbName = "";
    if (thumbName) {
      showThumbName = (
        <Link to={`/admin/boats/${id}`}>
          <img
            srcSet={`${process.env.REACT_APP_PUBLIC_FOLDER}/images/${id}/${thumbName}`}
            alt=""
            width="150px"
          />
        </Link>
      );
    }

    if (this.state.showMe === false) {
      return false;
    }
    return (
      <tr key={id}>
        {this.state.redirectPage}
        {this.state.modalDelete}
        <td className="">{id}</td>
        <td className="">{showThumbName}</td>
        <td className="">{stockNumber}</td>
        <td className="">{title}</td>
        <td className="">{price}</td>
        <td className="">{adTypeTitle}</td>
        <td>{boatStatusTitle}</td>
        <td className="">
          <Link to={`/admin/boats/${id}`}>
            <i
              className="icon ion-edit tx-20 pointer"
              //onClick={() => this.handleEdit(id)}
            />
          </Link>
          <i
            className="icon ion-close tx-20 mg-l-20 pointer"
            onClick={() => this.processDelete(id, title)}
          />
        </td>
      </tr>
    );
  }
}

export default BoatRow;
