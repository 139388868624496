import React, { Component } from 'react';
import AlertMessageBootstrap from '../../geral/AlertMessageBootstrap';
import SelectBox from '../../geral/SelectBox';
import _ from 'lodash';
import TextHelper from '../../utilities/textHelper';

const axios = require('axios');
require('dotenv').config();

class Specification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapse: '',
      AlertMessage: false,
      idTable: 0,
      // Fields
      length: '',
      beamLength: '',
      width: '',
      feet: '',
      hullYear: '',
      hull: '',
      hullMaterial: '',
      hullStyle: '',
      hin: '',
      dryWeight: '',
      draft: '',
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }
  handleChangeSelect = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  componentDidMount() {
    if (this.props.idBoat !== 'new') this.handleData();
  }

  handleData = () => {
    if (!isNaN(this.props.idBoat)) {
      var self = this;
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}boatDetails/specification/${this.props.idBoat}`,
      })
        .then(function (response) {
          if (response.data.success !== false) {
            const {
              length,
              beamLength,
              width,
              feet,
              hullYear,
              hull,
              hullStyle,
              hin,
              dryWeight,
              draft,
              id,
            } = response.data.data.results[0];

            self.setState({
              length: length,
              beamLength: beamLength,
              width: width,
              feet: feet,
              hullYear: hullYear,
              hull: hull,
              hullStyle: hullStyle,
              hin: hin,
              dryWeight: dryWeight,
              draft: draft,
              idTable: id,
              showCollapse: 'show',
            });
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    }
  };

  submitForm = () => {
    if (this.state.idTable !== 0) {
      this.updateData();
    } else {
      this.insertData();
    }
  };

  updateData = () => {
    var self = this;
    axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}boatDetails/specification/${this.props.idBoat}`,
      data: {
        length: this.state.length,
        beamLength: this.state.beamLength,
        width: this.state.width,
        feet: this.state.feet,
        hullYear: this.state.hullYear,
        hull: this.state.hull,
        hullStyle: this.state.hullStyle,
        hin: this.state.hin,
        dryWeight: this.state.dryWeight,
        draft: this.state.draft,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success } = response.data;
        var messageType = 'success';
        var messageDetail = msg;
        if (success === false) {
          messageType = 'danger';
        }
        self.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Erro!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  insertData = () => {
    //console.log("AddMe");
    //console.log(this.state);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}boatDetails/specification/${this.props.idBoat}`,
      data: {
        length: this.state.length,
        beamLength: this.state.beamLength,
        width: this.state.width,
        feet: this.state.feet,
        hullYear: this.state.hullYear,
        hull: this.state.hull,
        hullStyle: this.state.hullStyle,
        hin: this.state.hin,
        dryWeight: this.state.dryWeight,
        draft: this.state.draft,
        boatId: this.props.idBoat,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success, id } = response.data;
        var messageType = 'success';
        var messageDetail = msg;
        if (success === false) {
          messageType = 'danger';
        }

        this.setState({
          idTable: id,
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Error!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  render() {
    if (this.props.idBoat === 'new') {
      return null;
    }

    return (
      <div className="accordion mb-4 mt-4" id="accordion">
        <div className="card">
          <div className="card-header" id={`headingSpecification`}>
            <h2 className="mb-0">
              <button
                className="btn btn-secondary w-100"
                type="button"
                data-toggle="collapse"
                data-target={`#collapseSpecification`}
                aria-expanded="true"
                aria-controls="collapseSpecification"
              >
                Specification
              </button>
            </h2>
          </div>

          <div
            id="collapseSpecification"
            className={`collapse ${this.state.showCollapse}`}
            aria-labelledby="headingSpecification"
            data-parent="#accordion"
          >
            <div className="card-body mt-4">
              <div className="row mg-b-25">
                {/* Length # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Length
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'specification.length') {
                            return o;
                          }
                        })}
                        fieldId="specification.length"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="number"
                      id="length"
                      className="w-100"
                      maxLength="9"
                      value={this.state.length}
                      onChange={this.handleChange}
                    />{' '}
                    meters
                  </div>
                </div>

                {/* Width # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Width
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'specification.width') {
                            return o;
                          }
                        })}
                        fieldId="specification.width"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="number"
                      id="width"
                      className="w-100"
                      maxLength="9"
                      value={this.state.width}
                      onChange={this.handleChange}
                    />{' '}
                    meters
                  </div>
                </div>

                {/* Feet # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Feet
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'specification.feet') {
                            return o;
                          }
                        })}
                        fieldId="specification.feet"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="number"
                      id="feet"
                      className="w-100"
                      maxLength="9"
                      value={this.state.feet}
                      onChange={this.handleChange}
                    />{' '}
                    feet
                  </div>
                </div>

                {/* BeamLength # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Beam Length
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'specification.beamlength') {
                            return o;
                          }
                        })}
                        fieldId="specification.beamlength"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="beamLength"
                      className="w-100"
                      maxLength="255"
                      value={this.state.beamLength}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Draft # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Draft (M)
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'specification.draftm') {
                            return o;
                          }
                        })}
                        fieldId="specification.draftm"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="draft"
                      className="w-100"
                      maxLength="255"
                      value={this.state.draft}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Dry Weight (KG) # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Dry Weight (KG)
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'specification.dryweight') {
                            return o;
                          }
                        })}
                        fieldId="specification.dryweight"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="number"
                      id="dryWeight"
                      className="w-100"
                      maxLength="255"
                      value={this.state.dryWeight}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Hull Style # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Hull Style
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'specification.hullstyle') {
                            return o;
                          }
                        })}
                        fieldId="specification.hullstyle"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <SelectBox
                      value={this.state.hullStyle}
                      handleChange={this.handleChangeSelect}
                      name="hullStyle"
                      table="supporting-tables/hullStyle"
                      key="1"
                    />
                  </div>
                </div>

                {/* Hull Year # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Hull Year
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'specification.hullyear') {
                            return o;
                          }
                        })}
                        fieldId="specification.hullyear"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="number"
                      id="hullYear"
                      className="w-100"
                      maxLength="5"
                      value={this.state.hullYear}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Hull  */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Hull
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'specification.hull') {
                            return o;
                          }
                        })}
                        fieldId="specification.hull"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <SelectBox
                      value={this.state.hull}
                      handleChange={this.handleChangeSelect}
                      name="hull"
                      table="supporting-tables/hull"
                      key="1"
                    />
                  </div>
                </div>

                {/* Hin  */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Hin
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === 'specification.hin') {
                            return o;
                          }
                        })}
                        fieldId="specification.hin"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="hin"
                      className="w-100"
                      maxLength="255"
                      value={this.state.hin}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="col-4"></div>
              </div>

              <div>
                <hr />
                <input
                  type="submit"
                  className="btn btn-primary mb-4"
                  formAction="submit"
                  value="Submit Specification"
                  onClick={this.submitForm.bind(this)}
                />
                {this.state.AlertMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Specification;
