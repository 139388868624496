import React, { Component } from "react";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";
import _ from "lodash";
import TextHelper from "../../utilities/textHelper";

const axios = require("axios");
require("dotenv").config();

class Galley extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapse: "",
      AlertMessage: false,
      idTable: 0,
      // Fields
      fridge: "",
      freezer: "",
      sink: "",
      stove: "",
      ovenAndGrill: "",
      microwave: "",
      dishwasher: "",
      iceBox: "",
      galley: "",
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }
  handleChangeSelect = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  componentDidMount() {
    if (this.props.idBoat !== "new") this.handleData();
  }

  handleData = () => {
    if (!isNaN(this.props.idBoat)) {
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatGalley/${this.props.idBoat}`,
      })
        .then(function (response) {
          if (response.data.success !== false) {
            const {
              fridge,
              freezer,
              sink,
              stove,
              ovenAndGrill,
              microwave,
              dishwasher,
              iceBox,
              galley,
              id,
            } = response.data.data.results[0];

            self.setState({
              fridge: fridge,
              freezer: freezer,
              sink: sink,
              stove: stove,
              ovenAndGrill: ovenAndGrill,
              microwave: microwave,
              dishwasher: dishwasher,
              iceBox: iceBox,
              galley: galley,
              idTable: id,
              showCollapse: "show",
            });
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    }
  };

  submitForm = () => {
    if (this.state.idTable !== 0) {
      this.updateData();
    } else {
      this.insertData();
    }
  };

  updateData = () => {
    var self = this;
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatGalley/${this.props.idBoat}`,
      data: {
        fridge: this.state.fridge,
        freezer: this.state.freezer,
        sink: this.state.sink,
        stove: this.state.stove,
        ovenAndGrill: this.state.ovenAndGrill,
        microwave: this.state.microwave,
        dishwasher: this.state.dishwasher,
        iceBox: this.state.iceBox,
        galley: this.state.galley,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        self.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Erro!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  insertData = () => {
    //console.log("AddMe");
    //console.log(this.state);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatGalley/${this.props.idBoat}`,
      data: {
        fridge: this.state.fridge,
        freezer: this.state.freezer,
        sink: this.state.sink,
        stove: this.state.stove,
        ovenAndGrill: this.state.ovenAndGrill,
        microwave: this.state.microwave,
        dishwasher: this.state.dishwasher,
        iceBox: this.state.iceBox,
        galley: this.state.galley,
        boatId: this.props.idBoat,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success, id } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }

        this.setState({
          idTable: id,
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Error!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  render() {
    if (this.props.idBoat === "new") {
      return null;
    }

    return (
      <div className="accordion mb-4 mt-4" id="accordion">
        <div className="card">
          <div className="card-header" id={`headingGalley`}>
            <h2 className="mb-0">
              <button
                className="btn btn-secondary w-100"
                type="button"
                data-toggle="collapse"
                data-target={`#collapseGalley`}
                aria-expanded="true"
                aria-controls="collapseGalley"
              >
                Galley
              </button>
            </h2>
          </div>

          <div
            id="collapseGalley"
            className={`collapse ${this.state.showCollapse}`}
            aria-labelledby="headingGalley"
            data-parent="#accordion"
          >
            <div className="card-body mt-4">
              <div className="row mg-b-25">
                {/* Freezer # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Freezer
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "galley.freezer") {
                            return o;
                          }
                        })}
                        fieldId="galley.freezer"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="freezer"
                      className="w-100"
                      maxLength="500"
                      value={this.state.freezer}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Fridge # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Fridge
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "galley.fridge") {
                            return o;
                          }
                        })}
                        fieldId="galley.fridge"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="fridge"
                      className="w-100"
                      maxLength="500"
                      value={this.state.fridge}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/*Sink# */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Sink
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "galley.sink") {
                            return o;
                          }
                        })}
                        fieldId="galley.sink"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="sink"
                      className="w-100"
                      maxLength="500"
                      value={this.state.sink}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Stove # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Stove
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "galley.stove") {
                            return o;
                          }
                        })}
                        fieldId="galley.stove"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="stove"
                      className="w-100"
                      maxLength="500"
                      value={this.state.stove}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Oven and Grill # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Oven and Grill
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "galley.ovenandgrill") {
                            return o;
                          }
                        })}
                        fieldId="galley.ovenandgrill"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="ovenAndGrill"
                      className="w-100"
                      maxLength="500"
                      value={this.state.ovenAndGrill}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Microwave # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Microwave
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "galley.microwave") {
                            return o;
                          }
                        })}
                        fieldId="galley.microwave"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="microwave"
                      className="w-100"
                      maxLength="500"
                      value={this.state.microwave}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Dishwasher # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Dishwasher
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "galley.dishwasher") {
                            return o;
                          }
                        })}
                        fieldId="galley.dishwasher"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="dishwasher"
                      className="w-100"
                      maxLength="500"
                      value={this.state.dishwasher}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Ice Box # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Ice Box
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "galley.icebox") {
                            return o;
                          }
                        })}
                        fieldId="galley.icebox"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="iceBox"
                      className="w-100"
                      maxLength="500"
                      value={this.state.iceBox}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Galley # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Galley
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "galley.galley") {
                            return o;
                          }
                        })}
                        fieldId="galley.galley"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="galley"
                      className="w-100"
                      maxLength="500"
                      value={this.state.galley}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <input
                  type="submit"
                  className="btn btn-primary mb-4"
                  formAction="submit"
                  value="Submit Galley"
                  onClick={this.submitForm.bind(this)}
                />
                {this.state.AlertMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Galley;
