import React, { useEffect, useState } from "react";
import format from "date-format";
import { getBoatStatus } from "../../services/autogate";
import { setTimeout } from "timers";

const AutoGateStatus = ({ boatId, badge }) => {
  const [status, setStatus] = useState();
  const [processing, setProcessing] = useState(false);

  const getAutoGateStatus = async () => {
    const boatStatus = await getBoatStatus(boatId);
    if (!boatStatus.success) {
      return;
    }

    setStatus(boatStatus);
  };

  useEffect(() => {
    getAutoGateStatus();
    // eslint-disable-next-line
  }, []);

  const onRefresh = () => {
    setProcessing(true);
    setTimeout(() => {
      getAutoGateStatus();
      setProcessing(false);
    }, 1000);
  };

  if (status) {
    if (badge) {
      return (
        <span
          className={`d-block alert alert-${
            status.data && !status.data.xmlCreated ? "warning" : "success"
          }`}
        >
          {processing ? (
            "Refreshing..."
          ) : (
            <>
              Boat Sales Integration:{" "}
              {status.data && !status.data.xmlCreated
                ? "Update Scheduled to the next hour"
                : `Last Update ${format(
                    "dd/MM/yyyy hh:mm:ss",
                    new Date(status.data.xmlCreated)
                  )}`}{" "}
              <span onClick={onRefresh}>
                <i className="fa fa-refresh" style={{ cursor: "pointer" }}></i>
              </span>
            </>
          )}
        </span>
      );
    } else {
      return (
        <>
          <p className="w-100 mb-0">
            <b>Boat Sales Integration</b>
          </p>

          {processing ? (
            "Refreshing..."
          ) : (
            <>
              {" "}
              {status.data && !status.data.xmlCreated ? (
                <p className="d-block">Update Scheduled to the next hour</p>
              ) : (
                <p className="d-block">
                  Last Update:{" "}
                  {format(
                    "dd/MM/yyyy hh:mm:ss",
                    new Date(status.data.xmlCreated)
                  )}
                </p>
              )}{" "}
              <span onClick={onRefresh} className="ml-2">
                <i className="fa fa-refresh" style={{ cursor: "pointer" }}></i>
              </span>
            </>
          )}
        </>
      );
    }
  } else {
    return null;
  }
};

export default AutoGateStatus;
