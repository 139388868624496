import React, { Component } from "react";
//import { Link } from "react-router-dom";

import { Redirect } from "react-router-dom";
import _ from "lodash";
import AlertMessageBootstrap from "../geral/AlertMessageBootstrap";
import SelectBox from "../geral/SelectBox";

import VesselDetails from "./components/VesselDetails"; // 1 - VesselDetails
import General from "./components/General"; // 2 - General
import Specification from "./components/Specification"; // 3 - General

import Performance from "./components/Performance"; // 6 - Performance
import Tankage from "./components/Tankage";
import Steering from "./components/Steering";
import Stabiliser from "./components/Stabiliser";
import Engineering from "./components/Engineering";
import Electrical from "./components/Electrical";
import EntertainmentSystem from "./components/EntertainmentSystem";
import Electronics from "./components/Electronics";
import AdditionalEquipment from "./components/AdditionalEquipment";
import Awning from "./components/Awning";
import Accommodation from "./components/Accommodation";
import Bathroom from "./components/Bathroom";
import Galley from "./components/Galley";
import Access from "./components/Access";
import Sailing from "./components/Sailing";
import Safety from "./components/Safety";
import ServiceHistory from "./components/ServiceHistory";
import SellerDetails from "./components/SellerDetails";
import AgentDetails from "./components/AgentDetails";
import BuyerDetails from "./components/BuyerDetails";
import MediaDetails from "./components/MediaDetails";

import Engine from "./components/Engine";
import Genset from "./components/Genset";
import Tender from "./components/Tender";

import ManageCategories from "./components/ManageCategories";
import Upload from "./pictures/Upload";

import AutoGate from "./components/AutoGate";

import Yachthub from "./components/Yachthub";

import Wysiwyg from "./../utilities/wysiwyg";
import DuplicateABoatModal from "./DuplicateABoatModal";
import Integrations from "./components/Integrations";
import AmtPresentation from "./components/AmtPresentation";
import AlexanderMarinePresentation from "./components/AlexanderMarinePresentation";
//import ShareABoat from "./components/ShareABoat";
import AutoGateStatus from "../integration/autoGateStatus";
import TextHelper from "../utilities/textHelper";
import { getTooltipData, setTooltip } from "../../services/tooltip";
import Trailer from "./components/Trailer";
import { DisplayWebsite } from "./components/DisplayWebsite";

const axios = require("axios");

require("dotenv").config();

class BoatForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AlertMessage: false,
      handleError: false,
      showData: false,
      redirectPage: false,
      morefields: "",
      morefieldsShow: "",
      showAll: "d-none",
      manageCategories: "",
      manageCategoriesShow: "",
      pictureShow: "",
      exportShow: "",
      picture: "",
      export: "",
      categoryListIds: [],
      //dataFields
      documentId: "",
      adTypeId: "",
      title: "",
      price: "",
      shortDescription: "",
      description: "",
      boatSalesDescription: "",
      status: "",
      dataAdded: "",
      keywords: "",
      searchWeighting: 0,
      locationId: null,
      presentation: "",
      showPresentation: 0,
      //selectItens
      adTypeIdOptions: "",
      statusOptions: "",
      //more
      downloadStatus: "",
      fileGenerated: 0,
      autoGate: "",
      yachthub: "",
      boatId: "",
      myeditor: "",
      btPublish: "",
      btDuplicate: "",
      modalDuplicate: "",
      autoGateStatus: 0,
      luxmarine: 0,
      tradeABoatStatus: 0,
      tooltipData: [],
      websiteStatus: 1,
      boatPath: null,
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleChangeHTMLEditor = this.handleChangeHTMLEditor.bind(this);
    this.getTooltip = this.getTooltip.bind(this);
    this.updateTooltip = this.updateTooltip.bind(this);
    this.categoryList = this.categoryList.bind(this);
  }

  getTooltip = async () => {
    try {
      this.setState({
        tooltipData: [],
      });
      const { data } = await getTooltipData();
      data &&
        this.setState({
          tooltipData: data,
        });
    } catch (error) {
      this.setState({
        tooltipData: [],
      });
    }
  };

  updateTooltip = async (fieldId, tooltip) => {
    try {
      await setTooltip({ fieldId, tooltip });
      this.getTooltip();
      this.setState({
        AlertMessage: (
          <AlertMessageBootstrap
            messageType="success"
            message="Data has been updated!"
            unmountMe={this.handleCloseAlert}
          />
        ),
      });
      setTimeout(() => {
        this.setState({
          AlertMessage: false,
        });
      }, 2000);
    } catch (error) {
      this.setState({
        AlertMessage: (
          <AlertMessageBootstrap
            messageType="danger"
            message="Something went wrong!"
            unmountMe={this.handleCloseAlert}
          />
        ),
      });
    }
  };

  switchIntegrations = (integration) => {
    if (integration === "autoGate") {
      if (this.state.autoGateStatus === 0) {
        this.setState({ autoGateStatus: 1 });
      } else {
        this.setState({ autoGateStatus: 0 });
      }
      const { id } = this.props.match.params;
      const dateTime = new Date();
      setTimeout(() => {
        axios({
          method: "put",
          url: `${process.env.REACT_APP_API_URL}integrations/switch/${id}`,
          data: { autoGate: this.state.autoGateStatus, dtUpdated: dateTime },
        })
          .then((response) => {
            if (this.state.autoGateStatus === 0) {
              axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}autogate/delete/${id}`,
                data: {
                  autoGate: this.state.autoGateStatus,
                  dtUpdated: dateTime,
                },
              });
            } else {
              axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}autogate/add/${localStorage.userDealerId}/${id}`,
                data: {
                  autoGate: this.state.autoGateStatus,
                  dtUpdated: dateTime,
                },
              });
            }
          })
          .catch(function (response) {
            //console.log(response);
          });
      }, 2);
    }

    if (integration === "luxmarine") {
      if (this.state.luxmarine === 0) {
        this.setState({ luxmarine: 1 });
      } else {
        this.setState({ luxmarine: 0 });
      }
      const { id } = this.props.match.params;
      const dateTime = new Date();
      setTimeout(() => {
        axios({
          method: "put",
          url: `${process.env.REACT_APP_API_URL}integrations/switch/${id}`,
          data: { luxmarine: this.state.luxmarine, dtUpdated: dateTime },
        })
          .then((response) => {
            // console.log("done!");
          })
          .catch(function (response) {
            // console.log(response);
          });
      }, 2);
    }

    if (integration === "tradeABoat") {
      if (this.state.tradeABoatStatus === 0) {
        this.setState({ tradeABoatStatus: 1 });
      } else {
        this.setState({ tradeABoatStatus: 0 });
      }
      const { id } = this.props.match.params;
      const dateTime = new Date();
      setTimeout(() => {
        axios({
          method: "put",
          url: `${process.env.REACT_APP_API_URL}integrations/switch/${id}`,
          data: {
            tradeABoat: this.state.tradeABoatStatus,
            dtUpdated: dateTime,
          },
        })
          .then((response) => {
            // console.log("done!");

            if (this.state.tradeABoatStatus === 0) {
              axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}tradeaboat/delete/${id}`,
                data: {
                  tradeABoat: this.state.tradeABoatStatus,
                  dtUpdated: dateTime,
                },
              });
            } else {
              axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}tradeaboat/add/${localStorage.userDealerId}/${id}`,
                data: {
                  tradeABoat: this.state.tradeABoatStatus,
                  dtUpdated: dateTime,
                },
              });
            }
          })
          .catch(function (response) {
            // console.log(response);
          });
      }, 2);
    }
  };

  approveBoatSave() {
    this.handleChangeSelect("status", "1");
    setTimeout(() => {
      this.submitForm();
    });
  }

  handleContentChange = (content) => {
    this.setState({ description: content });
  };

  handleCloseDuplicate = () => {
    this.setState({ modalDuplicate: "" });
  };

  handleDuplicate() {
    this.setState({
      modalDuplicate: (
        <DuplicateABoatModal
          boatId={this.props.match.params.id}
          boatName={this.state.title}
          onClose={this.handleCloseDuplicate}
        />
      ),
    });
  }

  handleBtPublish() {
    if (
      this.state.status === 6 ||
      this.state.status === 7 ||
      this.state.status === "6" ||
      this.state.status === "7"
    ) {
      this.setState({
        btPublish: (
          <spam
            onClick={() => this.approveBoatSave()}
            className="btn btn-outline-primary btn-oblong tx-11 tx-uppercase tx-mont tx-medium tx-spacing-1 mr-3"
          >
            Approve this Boat
          </spam>
        ),
      });
    } else {
      this.setState({ btPublish: "" });
    }
  }

  updateData = () => {
    const { id } = this.props.match.params;
    const dateTime = new Date();
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}boat/${localStorage.userDealerId}/${id}`,
      data: {
        adTypeId: this.state.adTypeId,
        title: this.state.title,
        price: this.state.price,
        shortDescription: this.state.shortDescription,
        description: this.state.description,
        boatSalesDescription: this.state.boatSalesDescription,
        status: this.state.status,
        keywords: this.state.keywords,
        searchWeighting: this.state.searchWeighting,
        locationId: this.state.locationId,
        userUpdated: localStorage.userId,
        dtUpdated: dateTime,
        websiteStatus: this.state.websiteStatus,
      },
    })
      .then((response) => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
        this.handleBtPublish();
      })
      .catch(function (response) {
        const { msg } = response.data;
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message={msg}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  insertData = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}boat/${localStorage.userDealerId}`,
      data: {
        adTypeId: this.state.adTypeId,
        title: this.state.title,
        price: this.state.price,
        shortDescription: this.state.shortDescription,
        description: this.state.description,
        boatSalesDescription: this.state.boatSalesDescription,
        status: this.state.status,
        keywords: this.state.keywords,
        searchWeighting: this.state.searchWeighting,
        locationId: this.state.locationId,
        userAdded: localStorage.userId,
        idDealer: localStorage.userDealerId,
        websiteStatus: this.state.websiteStatus,
      },
    })
      .then((response) => {
        const { msg, success, id } = response.data;
        var messageType = "success";
        var messageDetail = msg;

        if (success === false) {
          messageType = "danger";

          this.setState({
            AlertMessage: (
              <AlertMessageBootstrap
                messageType={messageType}
                message={messageDetail}
                unmountMe={this.handleCloseAlert}
              />
            ),
          });
        } else {
          this.setState({
            showAll: "",
            boatId: id,
            AlertMessage: (
              <AlertMessageBootstrap
                messageType={messageType}
                message={messageDetail}
                unmountMe={this.handleCloseAlert}
              />
            ),
            redirectPage: <Redirect to={`${id}`} />,
          });
          this.handleBtPublish();
          //browserHistory.push("/path-to-link");
        }
      })
      .catch(function (response) {
        //console.log(response);
        const { msg } = response.data;
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message={msg}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  submitForm = () => {
    var msgErro = "";
    const { adTypeId, title, price, status } = this.state;
    if (adTypeId === "") {
      msgErro += "Condition, ";
    }
    if (title === "") {
      msgErro += "Title, ";
    }
    if (price === "") {
      msgErro += "Price, ";
    } else {
      this.setState({ price: parseInt(price, 10) });
    }
    if (status === "") {
      msgErro += "Status, ";
    }

    if (msgErro !== "") {
      msgErro = msgErro.substring(0, msgErro.length - 2);
      this.setState({
        AlertMessage: (
          <AlertMessageBootstrap
            messageType="danger"
            message={`Please fill the required fields: ${msgErro}`}
            unmountMe={this.handleCloseAlert}
          />
        ),
      });
      return false;
    }

    if (!isNaN(this.props.match.params.id)) {
      this.updateData();
    } else {
      this.insertData();
    }
  };

  handleChangeHTMLEditor = (description) => {
    this.setState({ description });
  };

  handleData = () => {
    const { id } = this.props.match.params;
    if (!isNaN(id)) {
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}boat/${localStorage.userDealerId}/${id}`,
      })
        .then(function (response) {
          if (response.data.success === false) {
            self.setState({ handleError: <Redirect to="/404Error" /> });
          }
          const {
            adTypeId,
            title,
            price,
            shortDescription,
            description,
            boatSalesDescription,
            status,
            dataAdded,
            keywords,
            searchWeighting,
            locationId,
            autoGate,
            luxmarine,
            tradeABoat,
            presentation,
            showPresentation,
            websiteStatus,
            boatPath,
          } = response.data.data.results[0];

          self.setState({
            presentation: presentation,
            showPresentation: showPresentation,
            showAll: "",
            adTypeId: adTypeId,
            title: title,
            price: price,
            shortDescription: shortDescription,
            description: description,
            boatSalesDescription: boatSalesDescription,
            status: status,
            dataAdded: dataAdded,
            keywords: keywords,
            searchWeighting: searchWeighting,
            locationId: locationId,
            autoGateStatus: autoGate,
            luxmarine: luxmarine,
            tradeABoatStatus: tradeABoat,
            websiteStatus: websiteStatus,
            boatPath: boatPath,
            myeditor: (
              <Wysiwyg
                mycontent={description}
                handleChangeHTMLEditor={self.handleChangeHTMLEditor}
              />
            ),
          });
          self.handleBtPublish();
        })
        .catch(function (response) {
          //console.log(response);
        });
    }
  };

  handleChangeSelect = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  componentDidMount() {
    if (this.props.match.params.id !== "new") {
      this.setState({
        autoGate: <AutoGate idBoat={this.props.match.params.id} />,
        yachthub: <Yachthub idBoat={this.props.match.params.id} />,
        btDuplicate: (
          <spam
            onClick={() => this.handleDuplicate()}
            className="btn btn-outline-primary btn-oblong tx-11 tx-uppercase tx-mont tx-medium tx-spacing-1 "
          >
            Duplicate This Boat
          </spam>
        ),
      });
      this.handleData();
      this.getTooltip();
    } else {
      this.setState({
        myeditor: (
          <Wysiwyg
            mycontent={""}
            handleChangeHTMLEditor={this.handleChangeHTMLEditor}
          />
        ),
      });
    }
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }

  handleChangeEditor = (e) => {
    const value = e.target.getContent();
    this.setState({ description: value });
  };

  toggleMoreFields = async () => {
    if (this.state.morefieldsShow === "") {
      let moreFields = <div></div>;
      if (this.state.categoryListIds.findIndex((e) => e === 176) > -1) {
        moreFields = (
          <div>
            <VesselDetails
              idBoat={this.props.match.params.id}
              id="1"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
            <Specification
              idBoat={this.props.match.params.id}
              id="3"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
            <Trailer
              idBoat={this.props.match.params.id}
              id="28"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
          </div>
        );
      } else {
        moreFields = (
          <div>
            <VesselDetails
              idBoat={this.props.match.params.id}
              id="1"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />

            <General
              idBoat={this.props.match.params.id}
              id="2"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />

            <Specification
              idBoat={this.props.match.params.id}
              id="3"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />

            <Engine
              idBoat={this.props.match.params.id}
              id="4"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />

            <Genset
              idBoat={this.props.match.params.id}
              id="5"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />

            <Performance
              idBoat={this.props.match.params.id}
              id="6"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />

            <Tankage
              idBoat={this.props.match.params.id}
              id="7"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />

            <Steering
              idBoat={this.props.match.params.id}
              id="8"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />

            <Stabiliser
              idBoat={this.props.match.params.id}
              id="9"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
            <Engineering
              idBoat={this.props.match.params.id}
              id="10"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
            <Electrical
              idBoat={this.props.match.params.id}
              id="11"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
            <Electronics
              idBoat={this.props.match.params.id}
              id="12"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
            <EntertainmentSystem
              idBoat={this.props.match.params.id}
              id="13"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
            <AdditionalEquipment
              idBoat={this.props.match.params.id}
              id="14"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
            <Awning
              idBoat={this.props.match.params.id}
              id="15"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
            <Accommodation
              idBoat={this.props.match.params.id}
              id="16"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
            <Bathroom
              idBoat={this.props.match.params.id}
              id="17"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
            <Galley
              idBoat={this.props.match.params.id}
              id="18"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />

            <Access
              idBoat={this.props.match.params.id}
              id="19"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
            <Sailing
              idBoat={this.props.match.params.id}
              id="20"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />

            <Safety
              idBoat={this.props.match.params.id}
              id="21"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
            <ServiceHistory
              idBoat={this.props.match.params.id}
              id="22"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
            <Tender
              idBoat={this.props.match.params.id}
              id="23"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
            <SellerDetails
              idBoat={this.props.match.params.id}
              id="24"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
            <AgentDetails
              idBoat={this.props.match.params.id}
              id="25"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
            <BuyerDetails
              idBoat={this.props.match.params.id}
              id="26"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
            <MediaDetails
              idBoat={this.props.match.params.id}
              id="27"
              tooltipData={this.state.tooltipData}
              updateTooltip={this.updateTooltip}
            />
          </div>
        );
      }

      this.setState({
        morefieldsShow: "show",
        morefields: moreFields,
      });
    } else {
      this.setState({ morefieldsShow: "" });
    }
  };

  categoryList = (list) => {
    this.setState({
      categoryListIds: list,
    });
  };

  toggleManageCategories = () => {
    if (this.state.manageCategoriesShow === "") {
      this.setState({
        manageCategoriesShow: "show",
      });
    } else {
      this.setState({ manageCategoriesShow: "" });
    }
  };

  togglePicture = () => {
    if (this.state.pictureShow === "") {
      this.setState({
        pictureShow: "show",
        picture: (
          <div>
            <Upload idBoat={this.props.match.params.id} key="102" />
          </div>
        ),
      });
    } else {
      this.setState({ pictureShow: "", picture: "" });
    }
  };

  createPdf = () => {
    const { id } = this.props.match.params;
    const documentid = this.state.documentId;
    if (!isNaN(id && !isNaN(documentid))) {
      var self = this;

      self.setState({
        downloadStatus: `<span classNam="badge badge-secondary p-4">
            We are creating your PDF... It will be ready soon.
          </span>`,
      });

      axios({
        method: "get",
        url: `${process.env.REACT_APP_PDF_GENERATOR}boat/createdocuments/${id}/${documentid}/${localStorage.userDealerId}`,
      })
        .then(function (response) {
          if (response.data.success === false) {
            self.setState({
              downloadStatus: "",
            });
            setTimeout(
              () =>
                self.setState({
                  downloadStatus: `
                <span className="badge badge-danger p-2">
                  Error to create your PDF!
                </span>`,
                }),
              10
            );
          }

          const dowloadbleUrl = response.data.data.dowloadbleUrl;

          //messageType = "danger";

          self.setState({
            downloadStatus: `<span className="badge badge-warning p-4">
                Your PDF is ready to download.
                <a href="${
                  process.env.REACT_APP_PDF_GENERATOR + "public" + dowloadbleUrl
                }" target="_blank">
                  Click Here!
                </a>
              </span>`,
          });

          setTimeout(() => {
            self.setState({
              fileGenerated: self.state.fileGenerated + 1,
            });
          }, 4000);
        })
        .catch(function (response) {
          self.setState({
            downloadStatus: "",
          });

          setTimeout(() => {
            self.setState({
              downloadStatus: `<span className="badge badge-warning p-4">
                Error!
              </span>`,
            });
          }, 10);
        });
    }
  };

  render() {
    return (
      <div className="br-mainpanel">
        {this.state.handleError}
        {this.state.redirectPage}
        <div className="br-pageheader pd-y-15 pd-l-20">
          <nav className="breadcrumb pd-0 mg-0 tx-12">
            <a className="breadcrumb-item" href="index.html">
              Boat Sync
            </a>
          </nav>
        </div>

        <div className="d-flex align-items-center justify-content-between mg-b-30 pd-x-30 pd-t-30">
          <div>
            <h4 className="tx-gray-800 mg-b-5">Boats</h4>
          </div>

          <div>
            <spam
              onClick={() => window.history.go(-1)}
              className="btn btn-primary btn-oblong tx-11 tx-uppercase tx-mont tx-medium tx-spacing-1 pd-x-30 bd-2"
            >
              Go Back
            </spam>
          </div>
        </div>

        <div className="br-pagebody">
          <div className="br-section-wrapper">
            <AutoGateStatus boatId={this.props.match.params.id} badge={true} />
            <div className="table-wrapper">
              <div className="form-layout">
                <div className="d-flex justify-content-end w-100 align-items-right">
                  <div>
                    {this.state.btPublish}
                    {this.state.btDuplicate}
                    {this.state.modalDuplicate}
                  </div>
                </div>

                {this.state.AlertMessage}

                <section id="mainInfo">
                  <div className="row mg-b-25">
                    {/* Title # */}
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          Title <span className="tx-danger">*</span>
                          <TextHelper
                            data={_.filter(
                              this.state.tooltipData,
                              function (o) {
                                if (o.fieldId === "title") {
                                  return o;
                                }
                              }
                            )}
                            fieldId="title"
                            updateTooltip={this.updateTooltip}
                          />
                        </label>

                        <input
                          type="text"
                          id="title"
                          className="w-100"
                          maxLength="255"
                          value={this.state.title}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>

                    {/* Price */}
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          Price <span className="tx-danger">*</span>
                          {" (Drive Away)"}
                          <TextHelper
                            data={_.filter(
                              this.state.tooltipData,
                              function (o) {
                                if (o.fieldId === "price") {
                                  return o;
                                }
                              }
                            )}
                            fieldId="price"
                            updateTooltip={this.updateTooltip}
                          />
                        </label>
                        AU${" "}
                        <input
                          type="number"
                          id="price"
                          maxLength="10"
                          value={this.state.price}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>

                    {/* Status # */}
                    <div className="col-12 col-md-3">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          Status <span className="tx-danger">*</span>
                          <TextHelper
                            data={_.filter(
                              this.state.tooltipData,
                              function (o) {
                                if (o.fieldId === "status") {
                                  return o;
                                }
                              }
                            )}
                            fieldId="status"
                            updateTooltip={this.updateTooltip}
                          />
                        </label>
                        <SelectBox
                          value={this.state.status}
                          handleChange={this.handleChangeSelect}
                          name="status"
                          table="supporting-tables/status"
                          key="1"
                        />
                      </div>
                    </div>

                    {/* Condition # */}
                    <div className="col-3">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          Condition <span className="tx-danger">*</span>
                          <TextHelper
                            data={_.filter(
                              this.state.tooltipData,
                              function (o) {
                                if (o.fieldId === "condition") {
                                  return o;
                                }
                              }
                            )}
                            fieldId="condition"
                            updateTooltip={this.updateTooltip}
                          />
                        </label>
                        <SelectBox
                          value={this.state.adTypeId}
                          handleChange={this.handleChangeSelect}
                          name="adTypeId"
                          table="supporting-tables/adtype"
                          key="adtype"
                        />
                      </div>
                    </div>

                    {/* WEBSITE STATUS */}
                    <div className="col-12 col-md-6">
                      {localStorage.userDealerId === "12" && (
                        <div className="form-group">
                          <label className="form-control-label d-block">
                            Website status<span className="tx-danger">*</span>
                            <TextHelper
                              data={_.filter(
                                this.state.tooltipData,
                                function (o) {
                                  if (o.fieldId === "website-status") {
                                    return o;
                                  }
                                }
                              )}
                              fieldId="website-status"
                              updateTooltip={this.updateTooltip}
                            />
                          </label>
                          <SelectBox
                            value={this.state.websiteStatus}
                            handleChange={this.handleChangeSelect}
                            name="websiteStatus"
                            table="supporting-tables/websitestatus"
                            key="websiteStatus"
                          />
                          {this.state.websiteStatus < 3 &&
                            this.props.match.params &&
                            this.props.match.params.id &&
                            !isNaN(this.props.match.params.id) &&
                            this.state.boatPath && (
                              <a
                                href={`${this.state.boatPath}/${
                                  this.props.match.params.id
                                }/${this.state.title.replace(
                                  new RegExp(" ", "g"),
                                  "-"
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i class="fa fa-eye ml-2"></i>
                              </a>
                            )}
                        </div>
                      )}
                    </div>

                    {/* Description */}
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          Description <span className="tx-danger"></span>
                          <TextHelper
                            data={_.filter(
                              this.state.tooltipData,
                              function (o) {
                                if (o.fieldId === "description") {
                                  return o;
                                }
                              }
                            )}
                            fieldId="description"
                            updateTooltip={this.updateTooltip}
                          />
                        </label>
                        {/* descriptioneditor */}
                        {this.state.myeditor}
                        {/* <textarea
                          id="description"
                          value={this.state.description}
                          onChange={this.handleChange}
                          style={{ width: "100%", height: "250px" }}
                        /> */}
                      </div>
                      <hr />
                    </div>

                    {/* Keywords */}
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          Keywords <span className="tx-danger"></span>
                          <TextHelper
                            data={_.filter(
                              this.state.tooltipData,
                              function (o) {
                                if (o.fieldId === "keywords") {
                                  return o;
                                }
                              }
                            )}
                            fieldId="keywords"
                            updateTooltip={this.updateTooltip}
                          />
                        </label>
                        <textarea
                          id="keywords"
                          className="w-100"
                          value={this.state.keywords}
                          onChange={this.handleChange}
                          style={{ height: "150px" }}
                        ></textarea>
                      </div>
                    </div>

                    {/* Short Description */}
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          Short Description <span className="tx-danger"></span>
                          <TextHelper
                            data={_.filter(
                              this.state.tooltipData,
                              function (o) {
                                if (o.fieldId === "shortdescription") {
                                  return o;
                                }
                              }
                            )}
                            fieldId="shortdescription"
                            updateTooltip={this.updateTooltip}
                          />
                        </label>
                        <textarea
                          id="shortDescription"
                          className="w-100"
                          value={this.state.shortDescription}
                          onChange={this.handleChange}
                          style={{ height: "150px" }}
                        ></textarea>
                      </div>
                    </div>

                    {/* BoatSales / PhotoCard Desc */}
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          BoatSales / PhotoCard Desc{" "}
                          <span className="tx-danger"></span>
                          <TextHelper
                            data={_.filter(
                              this.state.tooltipData,
                              function (o) {
                                if (o.fieldId === "boatsalesphotocarddesc") {
                                  return o;
                                }
                              }
                            )}
                            fieldId="boatsalesphotocarddesc"
                            updateTooltip={this.updateTooltip}
                          />
                        </label>
                        <textarea
                          id="boatSalesDescription"
                          className="w-100"
                          value={this.state.boatSalesDescription}
                          onChange={this.handleChange}
                          style={{ height: "150px" }}
                        ></textarea>
                      </div>
                    </div>

                    {/* search weighting */}
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label className="form-control-label d-block">
                          Search Weighting <span className="tx-danger"></span>
                          <TextHelper
                            data={_.filter(
                              this.state.tooltipData,
                              function (o) {
                                if (o.fieldId === "searchweighting") {
                                  return o;
                                }
                              }
                            )}
                            fieldId="searchweighting"
                            updateTooltip={this.updateTooltip}
                          />
                        </label>
                        <input
                          type="number"
                          id="searchWeighting"
                          maxLength="10"
                          value={this.state.searchWeighting}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <div className="row mg-b-25">
                  <hr />
                  <div className="col-12 col-md-6">
                    <input
                      type="submit"
                      className="btn btn-primary mb-4"
                      formAction="submit"
                      onClick={this.submitForm.bind(this)}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <div className={this.state.showAll}>
                      <p>
                        Documents:&nbsp;
                        <SelectBox
                          value={this.state.documentId}
                          handleChange={this.handleChangeSelect}
                          name="documentId"
                          table="supporting-tables/documents"
                          ignore={16}
                          key="1"
                          mystyle="h-50"
                        />{" "}
                        <input
                          type="submit"
                          className="btn btn-primary"
                          formAction="submit"
                          value="Create PDF"
                          onClick={this.createPdf}
                        />
                      </p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: this.state.downloadStatus,
                        }}
                      ></span>
                    </div>
                  </div>

                  <div className="col-12 col-md-6"></div>

                  <div className="col-12 col-md-6">
                    <div className={this.state.showAll}>
                      {localStorage.userDealerId === "12" && (
                        <DisplayWebsite
                          boatId={this.props.match.params.id}
                          createStatus={this.state.fileGenerated}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {this.state.AlertMessage}
              </div>

              <div id="accordion1" className={this.state.showAll}>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed  w-100 text-left"
                        data-toggle="collapse"
                        data-target="#collapseCatSelection"
                        aria-expanded="false"
                        aria-controls="collapseCatSelection"
                        onClick={this.toggleManageCategories}
                      >
                        Manage Categories
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseCatSelection"
                    className={`collapse`}
                    aria-labelledby="headingThree"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      {!isNaN(this.props.match.params.id) && (
                        <ManageCategories
                          idBoat={this.props.match.params.id}
                          key="101"
                          tooltipData={this.state.tooltipData}
                          updateTooltip={this.updateTooltip}
                          sendData={this.categoryList}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link w-100 text-left"
                        data-toggle={`collapse`}
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                        onClick={this.toggleMoreFields}
                      >
                        Manage All Details
                      </button>
                    </h5>
                  </div>

                  <div
                    id="collapseOne"
                    className={`collapse`}
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="card-body">{this.state.morefields}</div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed w-100 text-left"
                        data-toggle="collapse"
                        data-target="#collapsePictures"
                        aria-expanded="false"
                        aria-controls="collapsePictures"
                        onClick={this.togglePicture}
                      >
                        Pictures
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapsePictures"
                    className={`collapse`}
                    aria-labelledby="headingThree"
                    data-parent="#accordion"
                  >
                    <div className="card-body">{this.state.picture}</div>
                  </div>
                </div>

                {/*!isNaN(this.props.match.params.id) ? (
                  <ShareABoat boatId={this.props.match.params.id} />
                ) : (
                  false
                )*/}

                {/*this.state.autoGate*/}
                {this.state.yachthub}

                {!isNaN(this.props.match.params.id) ? (
                  <Integrations
                    boatId={this.props.match.params.id}
                    autoGate={this.state.autoGateStatus}
                    luxmarine={this.state.luxmarine}
                    tradeABoat={this.state.tradeABoatStatus}
                    onChange={this.switchIntegrations}
                  />
                ) : (
                  false
                )}

                {this.state.presentation !== "" &&
                localStorage.userDealerId === "5" ? (
                  <AmtPresentation
                    boatId={this.props.match.params.id}
                    myDocument={this.state.presentation}
                    showDocument={this.state.showPresentation}
                  />
                ) : (
                  ""
                )}

                {this.state.presentation !== "" &&
                localStorage.userDealerId === "11" ? (
                  <AlexanderMarinePresentation
                    boatId={this.props.match.params.id}
                    myDocument={this.state.presentation}
                    showDocument={this.state.showPresentation}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BoatForm;
