import React, { Component } from "react";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";
import _ from "lodash";
import TextHelper from "../../utilities/textHelper";

const axios = require("axios");
require("dotenv").config();

class General extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapse: "",
      AlertMessage: false,
      idTable: 0,
      // Fields
      antiFoul: "",
      deckMaterial: "",
      vesselLocation: "",
      vesselName: "",
      designerBuilder: "",
      whereBuilt: "",
      loa: "",
      bridgeClearance: "",
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }
  handleChangeSelect = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  componentDidMount() {
    if (this.props.idBoat !== "new") this.handleData();
  }

  handleData = () => {
    if (!isNaN(this.props.idBoat)) {
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}boatDetails/general/${this.props.idBoat}`,
      })
        .then(function (response) {
          //console.log("got it", response);
          if (response.data.success !== false) {
            const {
              antiFoul,
              deckMaterial,
              vesselLocation,
              vesselName,
              designerBuilder,
              whereBuilt,
              loa,
              bridgeClearance,
              id,
            } = response.data.data.results[0];

            self.setState({
              antiFoul: antiFoul,
              deckMaterial: deckMaterial,
              vesselLocation: vesselLocation,
              vesselName: vesselName,
              designerBuilder: designerBuilder,
              whereBuilt: whereBuilt,
              loa: loa,
              bridgeClearance: bridgeClearance,
              idTable: id,
              showCollapse: "show",
            });
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    }
  };

  submitForm = () => {
    if (this.state.idTable !== 0) {
      this.updateData();
    } else {
      this.insertData();
    }
  };

  updateData = () => {
    var self = this;
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}boatDetails/general/${this.props.idBoat}`,
      data: {
        antiFoul: this.state.antiFoul,
        deckMaterial: this.state.deckMaterial,
        vesselLocation: this.state.vesselLocation,
        vesselName: this.state.vesselName,
        designerBuilder: this.state.designerBuilder,
        whereBuilt: this.state.whereBuilt,
        loa: this.state.loa,
        bridgeClearance: this.state.bridgeClearance,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        self.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Erro!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  insertData = () => {
    //console.log("AddMe");
    //console.log(this.state);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}boatDetails/general/${this.props.idBoat}`,
      data: {
        antiFoul: this.state.antiFoul,
        deckMaterial: this.state.deckMaterial,
        vesselLocation: this.state.vesselLocation,
        vesselName: this.state.vesselName,
        designerBuilder: this.state.designerBuilder,
        whereBuilt: this.state.whereBuilt,
        loa: this.state.loa,
        bridgeClearance: this.state.bridgeClearance,
        boatId: this.props.idBoat,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success, id } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }

        this.setState({
          idTable: id,
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Error!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  render() {
    if (this.props.idBoat === "new") {
      return null;
    }

    return (
      <div className="accordion mb-4 mt-4" id="accordion">
        <div className="card">
          <div className="card-header" id={`headingGeneral`}>
            <h2 className="mb-0">
              <button
                className="btn btn-secondary w-100"
                type="button"
                data-toggle="collapse"
                data-target={`#collapseGeneral`}
                aria-expanded="true"
                aria-controls="collapseGeneral"
              >
                General Details
              </button>
            </h2>
          </div>

          <div
            id="collapseGeneral"
            className={`collapse ${this.state.showCollapse}`}
            aria-labelledby="headingGeneral"
            data-parent="#accordion"
          >
            <div className="card-body mt-4">
              <div className="row mg-b-25">
                {/* ANTI FOUL # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Anti Foul
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "general.antifoul") {
                            return o;
                          }
                        })}
                        fieldId="general.antifoul"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="antiFoul"
                      className="w-100"
                      maxLength="255"
                      value={this.state.antiFoul}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* deckMaterial # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Deck Material
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "general.deckmaterial") {
                            return o;
                          }
                        })}
                        fieldId="general.deckmaterial"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="deckMaterial"
                      className="w-100"
                      maxLength="255"
                      value={this.state.deckMaterial}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* 	vesselLocation # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Vessel Location
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "general.vessellocation") {
                            return o;
                          }
                        })}
                        fieldId="general.vessellocation"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="vesselLocation"
                      className="w-100"
                      maxLength="255"
                      value={this.state.vesselLocation}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* 	vesselName # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Vessel Name
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "general.vesselname") {
                            return o;
                          }
                        })}
                        fieldId="general.vesselname"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="vesselName"
                      className="w-100"
                      maxLength="255"
                      value={this.state.vesselName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* 	designerBuilder # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Designer Builder
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "general.designerbuilder") {
                            return o;
                          }
                        })}
                        fieldId="general.designerbuilder"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="designerBuilder"
                      className="w-100"
                      maxLength="255"
                      value={this.state.designerBuilder}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* 	whereBuilt # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Where Built
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "general.wherebuild") {
                            return o;
                          }
                        })}
                        fieldId="general.wherebuild"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="whereBuilt"
                      className="w-100"
                      maxLength="255"
                      value={this.state.whereBuilt}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* 	LOA # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      LOA
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "general.loa") {
                            return o;
                          }
                        })}
                        fieldId="general.loa"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="loa"
                      className="w-100"
                      maxLength="255"
                      value={this.state.loa}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                {/* 	bridgeClearance # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Bridge Clearance
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "general.bridgeclearance") {
                            return o;
                          }
                        })}
                        fieldId="general.bridgeclearance"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="bridgeClearance"
                      className="w-100"
                      maxLength="255"
                      value={this.state.bridgeClearance}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="col-4"></div>
              </div>

              <div>
                <hr />
                <input
                  type="submit"
                  className="btn btn-primary mb-4"
                  formAction="submit"
                  value="Submit General Details"
                  onClick={this.submitForm.bind(this)}
                />
                {this.state.AlertMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default General;
