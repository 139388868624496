import React, { useEffect, useState } from 'react';
import {
  getDocumentList,
  setDocumentStatus,
} from '../../../../services/displayWebsite';
import { FormControlLabel, Switch } from '@material-ui/core';

// setDocumentStatus(boatId, id, status)
export const DisplayWebsite = ({ boatId, createStatus }) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    const getList = async () => {
      const docList = await getDocumentList(boatId);
      setList(docList);
    };
    setList([]);
    console.log('getList');
    setTimeout(() => getList(), 1000);
  }, [boatId, createStatus]);

  const setStatus = async (id, status) => {
    setDocumentStatus(boatId, id, status);
    setList([]);
    setTimeout(() => {
      const item = list.findIndex((e) => e.id === id);
      let newValue = list;
      newValue[item].status = status;
      setTimeout(setList(newValue));
    }, 0);
  };

  const renderList = (listItens) => (
    <>
      {listItens.map((item) => (
        <li style={{ listStyle: 'none', marginBottom: '0px' }}>
          <FormControlLabel
            control={
              <Switch
                checked={item.status}
                color="primary"
                onChange={() => setStatus(item.id, item.status === 0 ? 1 : 0)}
              />
            }
            label={`Display ${item.title} on the webiste`}
            style={{ margin: '0px 15px 0px 0px' }}
          />
          <a
            href={`${process.env.REACT_APP_PDF_GENERATOR}public${item.fileSource}`}
            target="_blank"
            without
            rel="noopener noreferrer"
          >
            [Download]
          </a>
        </li>
      ))}
    </>
  );

  return (
    <>
      {list.length > 0 && (
        <>
          <hr />
          <ul style={{ padding: '0px' }}>{renderList(list)}</ul>
        </>
      )}
    </>
  );
};
