import React, { Component } from "react";
import { Link } from "react-router-dom";
import BoatRow from "./BoatRow";
import AlertMessageBootstrap from "../geral/AlertMessageBootstrap";
import SelectBox from "../geral/SelectBox";

require("dotenv").config();
const axios = require("axios");

class BoatPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showData: false,
      AlertMessage: false,

      status: "",
      category: "",
      manufacturer: "",
      lengthFrom: "",
      lengthTo: "",
      priceFrom: "",
      priceTo: "",
      adType: "",
      keyWords: "",
      orderId: "",
      execRedirec: "",
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.submitSearch = this.submitSearch.bind(this);
  }

  handleChangeSelect = (name, value) => {
    this.setState({ [name]: value });
  };

  searchQuery = () => {
    var searchQuery = `?`;
    if (this.state.keyWords !== "") {
      searchQuery += `keyWords=${this.state.keyWords}`;
    }
    if (this.state.orderId !== "") {
      searchQuery += `&orderId=${this.state.orderId}`;
    }
    if (this.state.lengthFrom !== "") {
      searchQuery += `&lengthFrom=${this.state.lengthFrom}`;
    }
    if (this.state.lengthTo !== "") {
      searchQuery += `&lengthTo=${this.state.lengthTo}`;
    }
    if (this.state.priceFrom !== "") {
      searchQuery += `&priceFrom=${this.state.priceFrom}`;
    }
    if (this.state.priceTo !== "") {
      searchQuery += `&priceTo=${this.state.priceTo}`;
    }
    if (this.state.status !== "") {
      searchQuery += `&status=${this.state.status}`;
    }
    if (this.state.category !== "") {
      searchQuery += `&category=${this.state.category}`;
    }
    if (this.state.manufacturer !== "") {
      searchQuery += `&manufacturer=${this.state.manufacturer}`;
    }
    if (this.state.adType !== "") {
      searchQuery += `&adType=${this.state.adType}`;
    }
    return searchQuery;
  };

  submitSearch() {
    //console.log(this.state);
    window.location.href = "/admin/boats/" + this.searchQuery();
  }

  handleChangeSearch = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
    //console.log(this.state);
  };

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }

  handleDelete = (id) => {
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}boat/${localStorage.userDealerId}/${id}`,
    })
      .then((response) => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch(function (response) {
        console.log(response);
        const { msg } = response.data;
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message={msg}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);

    var keyWords = params.get("keyWords");
    if (keyWords === null) keyWords = "";
    var orderId = params.get("orderId");
    if (orderId === null) orderId = "";
    var priceFrom = params.get("priceFrom");
    if (priceFrom === null) priceFrom = "";
    var priceTo = params.get("priceTo");
    if (priceTo === null) priceTo = "";
    var lengthFrom = params.get("lengthFrom");
    if (lengthFrom === null) lengthFrom = "";
    var lengthTo = params.get("lengthTo");
    if (lengthTo === null) lengthTo = "";

    var status = params.get("status");
    if (status === null) status = "";

    var category = params.get("category");
    if (category === null) category = "";

    var manufacturer = params.get("manufacturer");
    if (manufacturer === null) manufacturer = "";

    var adType = params.get("adType");
    if (adType === null) adType = "";

    this.setState({
      status: status,
      category: category,
      manufacturer: manufacturer,
      lengthFrom: lengthFrom,
      lengthTo: lengthTo,
      priceFrom: priceFrom,
      priceTo: priceTo,
      adType: adType,
      keyWords: keyWords,
      orderId: orderId,
    });

    setTimeout(() => {
      this.loadData();
    }, 100);
  }

  loadData = () => {
    this.setState({
      showData: (
        <tr key="7">
          <td colSpan="7">Fetching data...</td>
        </tr>
      ),
    });

    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}boat/${
        localStorage.userDealerId
      }/${this.searchQuery()}`,
    })
      .then((response) => {
        if (response.data.success === true) {
          //console.log(response.data.data.results);
          var dataShow = [];
          if (response.data.data.results.length !== 0) {
            for (let i = 0; i < response.data.data.results.length; i++) {
              dataShow.push(
                <BoatRow
                  key={response.data.data.results[i].id}
                  data={response.data.data.results[i]}
                  handleDelete={this.handleDelete}
                />
              );
            }
          } else {
            dataShow.push(
              <tr key="7">
                <td colSpan="7">Data not found!</td>
              </tr>
            );
          }

          this.setState({
            showData: dataShow,
          });
        } else {
          console.log("Failed!");
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  render() {
    return (
      <div className="br-mainpanel">
        {this.state.execRedirec}
        <div className="br-pageheader pd-y-15 pd-l-20">
          <nav className="breadcrumb pd-0 mg-0 tx-12">
            <a className="breadcrumb-item" href="index.html">
              Boat Sync
            </a>
          </nav>
        </div>

        <div className="d-flex align-items-center justify-content-between mg-b-30 pd-x-30 pd-t-30">
          <div>
            <h4 className="tx-gray-800 mg-b-5">Boats</h4>
          </div>

          <div>
            <Link
              to="/admin/boats/new"
              className="btn btn-primary btn-oblong tx-11 tx-uppercase tx-mont tx-medium tx-spacing-1 pd-x-30 bd-2"
            >
              Add New
            </Link>
          </div>
        </div>

        <div className="br-pagebody">
          <div className="form-layout form-layout-1">
            <div className="row mg-b-25">
              <div className="col-lg-4">
                <div className="form-group mg-b-10-force">
                  <label className="form-control-label">Status:</label>
                  <SelectBox
                    value={this.state.status}
                    handleChange={this.handleChangeSelect}
                    name="status"
                    id="status"
                    table="supporting-tables/status"
                    mystyle="form-control select2 select2-hidden-accessible"
                    key="1"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group mg-b-10-force">
                  <label className="form-control-label">Condition:</label>
                  <SelectBox
                    value={this.state.adType}
                    handleChange={this.handleChangeSelect}
                    name="adType"
                    id="adType"
                    table="supporting-tables/adtype"
                    mystyle="form-control select2 select2-hidden-accessible"
                    key="adtype"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group mg-b-10-force">
                  <label className="form-control-label">Category:</label>
                  <SelectBox
                    value={this.state.category}
                    handleChange={this.handleChangeSelect}
                    name="category"
                    id="category`"
                    table="categorylist"
                    mystyle="form-control select2 select2-hidden-accessible"
                    key="1"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group mg-b-10-force">
                  <label className="form-control-label">Manufacture:</label>
                  <SelectBox
                    value={this.state.manufacturer}
                    handleChange={this.handleChangeSelect}
                    name="manufacturer"
                    id="manufacturer"
                    table="manufacturer"
                    mystyle="form-control select2 select2-hidden-accessible"
                    key="1"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group mg-b-10-force">
                  <label className="form-control-label">Lenght:</label>
                  {"From "}
                  <select
                    className="form-control select2 select2-hidden-accessible"
                    tabIndex="-1"
                    aria-hidden="true"
                    id="lengthFrom"
                    value={this.state.lengthFrom}
                    onChange={this.handleChangeSearch}
                    style={{ width: "40%", display: "inline-block" }}
                  >
                    <option value="0"></option>
                    <option value="10">10 feet</option>
                    <option value="20">20 feet</option>
                    <option value="30">30 feet</option>
                    <option value="40">40 feet</option>
                    <option value="50">50 feet</option>
                    <option value="60">60 feet</option>
                    <option value="70">70 feet</option>
                    <option value="80">80 feet</option>
                    <option value="90">90 feet</option>
                    <option value="100">100 feet</option>
                    <option value="150">150 feet</option>
                    <option value="200">200 feet</option>
                    <option value="250">250 feet</option>
                    <option value="300">300 feet</option>
                    <option value="350">350 feet</option>
                    <option value="400">400 feet</option>
                    <option value="450">450 feet</option>
                    <option value="500">500 feet</option>
                    <option value="550">550 feet</option>
                  </select>
                  {" to "}
                  <select
                    className="form-control select2 select2-hidden-accessible"
                    tabIndex="-1"
                    aria-hidden="true"
                    id="lengthTo"
                    value={this.state.lengthTo}
                    onChange={this.handleChangeSearch}
                    style={{ width: "40%", display: "inline-block" }}
                  >
                    <option value="0"></option>
                    <option value="10">10 feet</option>
                    <option value="20">20 feet</option>
                    <option value="30">30 feet</option>
                    <option value="40">40 feet</option>
                    <option value="50">50 feet</option>
                    <option value="60">60 feet</option>
                    <option value="70">70 feet</option>
                    <option value="80">80 feet</option>
                    <option value="90">90 feet</option>
                    <option value="100">100 feet</option>
                    <option value="150">150 feet</option>
                    <option value="200">200 feet</option>
                    <option value="250">250 feet</option>
                    <option value="300">300 feet</option>
                    <option value="350">350 feet</option>
                    <option value="400">400 feet</option>
                    <option value="450">450 feet</option>
                    <option value="500">500 feet</option>
                    <option value="550">550 feet</option>
                  </select>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="form-group mg-b-10-force">
                  <label className="form-control-label">Price:</label>
                  {"From "}
                  <select
                    className="form-control select2 select2-hidden-accessible"
                    tabIndex="-1"
                    aria-hidden="true"
                    id="priceFrom"
                    value={this.state.priceFrom}
                    onChange={this.handleChangeSearch}
                    style={{ width: "40%", display: "inline-block" }}
                  >
                    <option value="0"></option>
                    <option value="1">$1</option>
                    <option value="500">$500</option>
                    <option value="1000">$1,000</option>
                    <option value="2500">$2,500</option>
                    <option value="5000">$5,000</option>
                    <option value="10000">$10,000</option>
                    <option value="25000">$25,000</option>
                    <option value="50000">$50,000</option>
                    <option value="100000">$100,000</option>
                    <option value="150000">$150,000</option>
                    <option value="200000">$200,000</option>
                    <option value="300000">$300,000</option>
                    <option value="400000">$400,000</option>
                    <option value="500000">$500,000</option>
                    <option value="750000">$750,000</option>
                    <option value="1000000">$1,000,000</option>
                    <option value="5000000">$5,000,000</option>
                    <option value="10000000">$10,000,000</option>
                    <option value="50000000">$50,000,000</option>
                    <option value="100000000">$100,000,000</option>
                    <option value="200000000">$200,000,000</option>
                  </select>
                  {" to "}
                  <select
                    className="form-control select2 select2-hidden-accessible"
                    tabIndex="-1"
                    aria-hidden="true"
                    id="priceTo"
                    value={this.state.priceTo}
                    onChange={this.handleChangeSearch}
                    style={{ width: "40%", display: "inline-block" }}
                  >
                    <option value="0"></option>
                    <option value="1">$1</option>
                    <option value="500">$500</option>
                    <option value="1000">$1,000</option>
                    <option value="2500">$2,500</option>
                    <option value="5000">$5,000</option>
                    <option value="10000">$10,000</option>
                    <option value="25000">$25,000</option>
                    <option value="50000">$50,000</option>
                    <option value="100000">$100,000</option>
                    <option value="150000">$150,000</option>
                    <option value="200000">$200,000</option>
                    <option value="300000">$300,000</option>
                    <option value="400000">$400,000</option>
                    <option value="500000">$500,000</option>
                    <option value="750000">$750,000</option>
                    <option value="1000000">$1,000,000</option>
                    <option value="5000000">$5,000,000</option>
                    <option value="10000000">$10,000,000</option>
                    <option value="50000000">$50,000,000</option>
                    <option value="100000000">$100,000,000</option>
                    <option value="200000000">$200,000,000</option>
                  </select>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="form-group mg-b-10-force">
                  <label className="form-control-label">Keywords:</label>
                  <input
                    className="form-control"
                    type="text"
                    name="keyWords"
                    id="keyWords"
                    value={this.state.keyWords}
                    onChange={this.handleChangeSearch}
                  />
                </div>
              </div>

              <div className="col-lg-4">
                <div className="form-group mg-b-10-force">
                  <label className="form-control-label">Order by:</label>
                  <select
                    className="form-control select2 select2-hidden-accessible"
                    data-placeholder="Choose country"
                    tabIndex="-1"
                    aria-hidden="true"
                    id="orderId"
                    value={this.state.orderId}
                    onChange={this.handleChangeSearch}
                  >
                    <option value="0"></option>
                    <option value="1">Price (Low - High)</option>
                    <option value="2">Price (High - Low)</option>
                    <option value="3">Length (Low - High)</option>
                    <option value="4">Length (High - Low)</option>
                    <option defaultValue value="5">
                      Ad Date (Newest - Oldest)
                    </option>
                    <option value="6">Ad Date (Oldest - Newest)</option>
                    <option value="7">Manufacturer</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="form-layout-footer">
              <button className="btn btn-info" onClick={this.submitSearch}>
                Search
              </button>
            </div>
          </div>

          <div className="row row-sm mb-4"></div>

          <div className="br-section-wrapper">
            {this.state.AlertMessage}
            <div className="table-wrapper">
              <table
                id="datatable1"
                className="table display responsive nowrap"
              >
                <thead>
                  <tr>
                    <th className="wd-5p">#</th>
                    <th className="wd-15p"></th>
                    <th className="wd-15p">Stock Number</th>
                    <th className="wd-20p">Title</th>
                    <th className="wd-15p">Price</th>
                    <th className="wd-10p">Ad Type</th>
                    <th className="wd-10p">Status</th>
                    <th className="wd-15p"></th>
                  </tr>
                </thead>
                <tbody>{this.state.showData}</tbody>
              </table>
            </div>
            {this.state.AlertMessage}
          </div>
        </div>
      </div>
    );
  }
}

export default BoatPanel;
