import React, { Component } from "react";
import { Link } from "react-router-dom";
import ManufacturerRow from "./ManufacturerRow";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";

require("dotenv").config();
const axios = require("axios");

class ManufacturerPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showData: false,
      AlertMessage: false
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }

  handleDelete = id => {
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}manufacturer/${localStorage.userDealerId}/${id}`
    })
      .then(response => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          )
        });
      })
      .catch(function(response) {
        console.log(response);
        const { msg } = response.data;
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message={msg}
              unmountMe={this.handleCloseAlert}
            />
          )
        });
      });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}manufacturer/${localStorage.userDealerId}`
    })
      .then(response => {
        if (response.data.success === true) {
          var dataShow = [];
          for (let i = 0; i < response.data.data.results.length; i++) {
            dataShow.push(
              <ManufacturerRow
                key={response.data.data.results[i].id}
                data={response.data.data.results[i]}
                handleDelete={this.handleDelete}
              />
            );
          }
          this.setState({
            showData: dataShow
          });
        } else {
          console.log("Failed!");
        }
      })
      .catch(function(response) {
        console.log(response);
      });
  };

  render() {
    return (
      <div className="br-mainpanel">
        <div className="br-pageheader pd-y-15 pd-l-20">
          <nav className="breadcrumb pd-0 mg-0 tx-12">
            <a className="breadcrumb-item" href="index.html">
              Boat Sync
            </a>
            <span className="breadcrumb-item active">Utilities</span>
            <span className="breadcrumb-item active">Manufacturer</span>
          </nav>
        </div>

        <div className="d-flex align-items-center justify-content-between mg-b-30 pd-x-30 pd-t-30">
          <div>
            <h4 className="tx-gray-800 mg-b-5">Manufacturers</h4>
          </div>

          <div>
            <Link
              to="/utilities/manufacturer/new"
              className="btn btn-primary btn-oblong tx-11 tx-uppercase tx-mont tx-medium tx-spacing-1 pd-x-30 bd-2"
            >
              Add New
            </Link>
          </div>
        </div>

        <div className="br-pagebody">
          <div className="row row-sm mb-4"></div>

          <div className="br-section-wrapper">
            {this.state.AlertMessage}
            <div className="table-wrapper">
              <table
                id="datatable1"
                className="table display responsive nowrap"
              >
                <thead>
                  <tr>
                    <th className="wd-5p">#</th>
                    <th className="wd-10p">Title</th>
                    <th className="wd-35p"></th>
                  </tr>
                </thead>
                <tbody>{this.state.showData}</tbody>
              </table>
            </div>
            {this.state.AlertMessage}
          </div>
        </div>
      </div>
    );
  }
}

export default ManufacturerPanel;
