import React, { Component } from "react";
import format from "date-format";
const axios = require("axios");
require("dotenv").config();

class AutoGate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btAction: "",
      msgLastUpdate: "",
      msgerro: ""
    };
  }

  sendToAutoGate = () => {
    var self = this;
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}autogate/add/${localStorage.userDealerId}/${this.props.idBoat}`
    })
      .then(function(response) {
        if (response.data.success === false) {
          self.setState({
            msgerro: response.data.data.message
          });
        } else {
          self.setState({
            btAction: (
              <input
                type="submit"
                className="btn btn-danger"
                value="Disconnect to AutoGate"
                onClick={() => self.cancelData()}
              ></input>
            ),
            msgLastUpdate: <p className="card-text  d-inline">Processing...</p>
          });
        }
      })
      .catch(function(response) {
        console.log(response);
      });
  };

  cancelData = () => {
    ///autogate/delete/:boatId'
    var self = this;
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}autogate/delete/${this.props.idBoat}`
    })
      .then(function(response) {
        if (response.data.success === false) {
          self.setState({
            msgerro: "Failed!"
          });
        } else {
          self.setState({
            btAction: "",
            msgLastUpdate: ""
          });
          self.handleData();
        }
      })
      .catch(function(response) {
        console.log(response);
      });
  };

  handleData = () => {
    var self = this;
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}autogate/status/${this.props.idBoat}`
    })
      .then(function(response) {
        if (response.data.success === false) {
          self.setState({
            btAction: (
              <input
                type="submit"
                className="btn btn-primary"
                value="Connect to AutoGate"
                onClick={() => self.sendToAutoGate()}
              ></input>
            ),
            msgLastUpdate: ""
          });
        } else {
          if (response.data.data.status === 0) {
            self.setState({
              msgLastUpdate: (
                <p className="card-text  d-inline">Processing...</p>
              )
            });

            /**btAction: (
                <input
                  type="submit"
                  className="btn btn-danger"
                  value="Disconnect to AutoGate"
                  onClick={() => self.cancelData()}
                ></input>
              ),
            */
          } else {
            self.setState({
              btAction: (
                <input
                  type="submit"
                  className="btn btn-primary"
                  value="Update to AutoGate"
                  onClick={() => self.sendToAutoGate()}
                ></input>
              ),
              msgLastUpdate: (
                <p className="card-text  d-inline">
                  <strong>Last Update:</strong>{" "}
                  {format(
                    "dd/MM/yyyy hh:mm:ss",
                    new Date(response.data.data.xmlCreated)
                  )}
                </p>
              )
            });
          }
        }
      })
      .catch(function(response) {
        console.log(response);
      });
  };

  componentDidMount() {
    this.handleData();
  }

  render() {
    if (this.props.idBoat === "new") {
      return null;
    }

    return (
      <div className="card mt-3">
        <div className="card-header">AutoGate Integration</div>
        <div className="card-body">
          {this.state.msgLastUpdate}
          <p className="mt-3">{this.state.btAction}</p>

          {this.state.msgerro}
        </div>
      </div>
    );
  }
}

export default AutoGate;
