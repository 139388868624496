import React, { Component } from "react";
import UploadForm from "./UploadForm";
import SortImages from "./SortImages";

require("dotenv").config();

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionShow: ""
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }

  handleChange = e => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  componentDidMount() {
    this.picFunction("uploadForm");
  }

  picFunction = a => {
    this.setState({
      actionShow: ""
    });
    switch (a) {
      case "uploadForm":
        this.setState({
          actionShow: (
            <UploadForm
              idBoat={this.props.idBoat}
              key="1"
              sortfield="imgOrder"
            />
          )
        });
        break;
      case "sort1":
        setTimeout(() => {
          this.setState({
            actionShow: (
              <SortImages
                idBoat={this.props.idBoat}
                key="1"
                sortfield="imgOrder"
                title="Picture Sort and Edit"
              />
            )
          });
        }, 20);

        break;
      case "sort2":
        setTimeout(() => {
          this.setState({
            actionShow: (
              <SortImages
                idBoat={this.props.idBoat}
                key="1"
                sortfield="imgOrderWindowCard"
                title="Window Card Picture Sort"
              />
            )
          });
        }, 20);
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <div className="card">
        <div className="card-body pt-1">
          <div className="row mb-3">
            <button
              type="button"
              className="btn btn-primary ml-3 mt-2"
              onClick={() => this.picFunction("uploadForm")}
            >
              Upload
            </button>
            <button
              type="button"
              className="btn btn-primary ml-3 mt-2"
              onClick={() => this.picFunction("sort1")}
            >
              Picture Sort and Edit
            </button>
            <button
              type="button"
              className="btn btn-primary ml-3 mt-2"
              onClick={() => this.picFunction("sort2")}
            >
              Window Card Picture Sort
            </button>
          </div>
          <hr />
          <div className="row pl-3">{this.state.actionShow}</div>
        </div>
      </div>
    );
  }
}

export default Upload;
