import React, { Component } from "react";
import SelectBox from "../../geral/SelectBox";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";
import TextHelper from "../../utilities/textHelper";
import _ from "lodash";

const axios = require("axios");
require("dotenv").config();

class ManageCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapse: "",
      AlertMessage: false,
      idTable: 0,
      categoryList: "",
      selectBoxShow: "",
      // Fields
      categoryId: "",
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }
  handleChangeSelect = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  componentDidMount() {
    if (this.props.idBoat !== "new") this.handleData();
  }

  removeCat = (e) => {
    /* /relBoatCat/:dealerId/:idBoat */
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/relBoatCat/${localStorage.userDealerId}/${e.target.id}/`,
    })
      .then((response) => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        this.setState({
          selectBoxShow: "",
          categoryList: "",
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
        this.handleData();
      })
      .catch(function (response) {
        const { msg } = response.data;
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message={msg}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  handleData = () => {
    this.setState({
      selectBoxShow: (
        <SelectBox
          value={this.state.categoryId}
          handleChange={this.handleChangeSelect}
          name="categoryId"
          table="categorylist"
          key="1"
        />
      ),
    });

    if (!isNaN(this.props.idBoat)) {
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}relBoatCat/${localStorage.userDealerId}/${this.props.idBoat}`,
      }).then(function (response) {
        var listOfCatIdValues = [];
        if (response.data.success !== false) {
          var listOfCat = [];

          for (let i = 0; i < response.data.data.results.length; i++) {
            const { id, title, categoryId } = response.data.data.results[i];
            listOfCatIdValues.push(categoryId);
            listOfCat.push(
              <button type="button" className="btn btn-info m-2" key={id}>
                {title}{" "}
                <span
                  className="badge badge-light ml-2"
                  id={id}
                  onClick={self.removeCat}
                >
                  Remove
                </span>
              </button>
            );
          }

          if (listOfCat.length > 0) {
            self.setState({
              categoryList: listOfCat,
            });
          }
        }
        self.props.sendData(listOfCatIdValues);
      });
    }
  };

  submitForm = () => {
    if (this.state.categoryId !== "") {
      this.setState({ categoryId: "" });
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}relBoatCat/${this.props.idBoat}/${this.state.categoryId}`,
        data: {
          categoryId: this.state.categoryId,
          boatId: this.props.idBoat,
          userUpdated: localStorage.userId,
        },
      })
        .then((response) => {
          const { msg, success, id } = response.data;
          var messageType = "success";
          var messageDetail = msg;
          if (success === false) {
            messageType = "danger";
          }

          this.setState({
            idTable: id,
            categoryList: "",
            selectBoxShow: "",
            AlertMessage: (
              <AlertMessageBootstrap
                messageType={messageType}
                message={messageDetail}
                unmountMe={this.handleCloseAlert}
              />
            ),
          });
          this.handleData();
        })
        .catch((response) => {
          this.setState({
            AlertMessage: (
              <AlertMessageBootstrap
                messageType="danger"
                message="Error!"
                unmountMe={this.handleCloseAlert}
              />
            ),
          });
        });
    } else {
      this.setState({
        AlertMessage: (
          <AlertMessageBootstrap
            messageType="danger"
            message="Select a category"
            unmountMe={this.handleCloseAlert}
          />
        ),
      });
    }
  };

  render() {
    if (this.props.idBoat === "new") {
      return null;
    }

    return (
      <div className="card">
        <div className="card-body mt-4">
          <div className="row mg-b-25 mb-1">
            {/* Categories # */}

            <div className="col-4">
              <div className="form-group">
                <label className="form-control-label d-block">
                  Categories
                  <TextHelper
                    data={_.filter(this.props.tooltipData, function (o) {
                      if (o.fieldId === "categories.categories") {
                        return o;
                      }
                    })}
                    fieldId="categories.categories"
                    updateTooltip={this.props.updateTooltip}
                  />
                </label>
                {this.state.selectBoxShow}
                <input
                  type="submit"
                  className="btn btn-primary p-1 ml-2"
                  formAction="submit"
                  value="Add"
                  onClick={this.submitForm.bind(this)}
                />
              </div>
            </div>
          </div>

          <div>
            {this.state.AlertMessage}
            <hr />
            {this.state.categoryList}
          </div>
        </div>
      </div>
    );
  }
}

export default ManageCategories;
