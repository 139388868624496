import React, { Component } from "react";
import UploadImageRow from "./UploadImageRow";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";

const axios = require("axios");
require("dotenv").config();

class UploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMessage: null,
      selectedFiles: "",
      uploadButton: "",
      rowImages: "",
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }

  componentDidMount() {
    this.loadButton();
    this.handleRowImages();
  }

  loadButton() {
    this.setState({
      uploadButton: (
        <button
          type="button"
          className="btn btn-primary mb-2 mt-1"
          formAction="submit"
          onClick={this.handleUploadImages}
        >
          Upload Images
        </button>
      ),
    });
  }

  handleSubmitImages = (event) => {
    var listOfSelectedFiles = [];
    for (let i = 0; i < event.target.files.length; i++) {
      listOfSelectedFiles.push(event.target.files[i]);
    }
    this.setState({ selectedFiles: listOfSelectedFiles });
  };

  handleUploadImages = () => {
    if (this.state.selectedFiles === "") {
      this.setState({
        alertMessage: (
          <AlertMessageBootstrap
            messageType="danger"
            message="Image not selected!"
            unmountMe={this.handleCloseAlert}
          />
        ),
      });
      return false;
    }
    this.setState({
      uploadButton: (
        <button className="btn btn-primary" type="button" disabled="">
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Uploading...
        </button>
      ),
    });
    const data = new FormData();
    for (var x = 0; x < this.state.selectedFiles.length; x++) {
      data.append("file", this.state.selectedFiles[x]);
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}boat-upload/${this.props.idBoat}/${localStorage.userId}`,
        data,
        {
          // receive two parameter endpoint url ,form data
        }
      )
      .then((res) => {
        // then print response status

        this.setState({
          rowImages: "",
          alertMessage: (
            <AlertMessageBootstrap
              messageType="success"
              message="The upload has been done!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });

        setTimeout(() => {
          this.setState({ selectedFiles: null });
          this.handleRowImages();
        }, 20);
      })
      .catch((err) => {
        this.loadButton();
        this.setState({
          alertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Failure!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
        console.log("Upload Error:", err);
      });
  };

  handleRowImages = () => {
    var imgRows = [];
    var self = this;
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}boat-upload/${self.props.idBoat}/imgOrder`,
    }).then(function (response) {
      console.log(
        `${process.env.REACT_APP_API_URL}boat-upload/${self.props.idBoat}`
      );
      if (response.data.success === false) {
        self.setState({
          rowImages: <p className="m-3 text-danger">No images!</p>,
        });
        return false;
      }
      for (let i = 0; i < response.data.data.results.length; i++) {
        imgRows.push(
          <UploadImageRow
            idBoat={self.props.idBoat}
            data={response.data.data.results[i]}
            key={response.data.data.results[i].id}
          />
        );
      }

      self.setState({ rowImages: imgRows });
      self.loadButton();
    });
  };
  render() {
    return (
      <div style={{ width: "98%" }}>
        <div className="col-12 border border-info uploadFileRow">
          <input
            type="file"
            name="img"
            multiple
            className="uploadFileField"
            onChange={this.handleSubmitImages}
          />
          <hr className="mt-0 mb-0" />
          {this.state.uploadButton}
        </div>
        <h4 className="mt-3">
          Uploaded Images{" "}
          <span
            className="tx-14"
            style={{ cursor: "pointer" }}
            onClick={this.handleRowImages}
          >
            (<i className="fa fa-refresh"></i> Reload Images)
          </span>
        </h4>

        <div className="border border-info uploadFileRow ">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Image</th>
                <th scope="col">Order</th>
                <th scope="col">Alternate Text</th>
                <th scope="col">File Name</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>{this.state.rowImages}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default UploadForm;
