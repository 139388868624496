import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";
import { Editor } from "@tinymce/tinymce-react";

const axios = require("axios");

require("dotenv").config();

class UsersForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AlertMessage: false,
      handleError: false,
      showData: false,
      redirectPage: false,
      //dataFields
      firstName: "",
      lastName: "",
      emailAddress: "",
      password: "",
      signature: "",
      status: ""
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
  }

  updateData = () => {
    const { id } = this.props.match.params;
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}users/${localStorage.userDealerId}/${id}`,
      data: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        emailAddress: this.state.emailAddress,
        password: this.state.password,
        signature: this.state.signature,
        status: this.state.status === true ? 1 : 0
      }
    })
      .then(response => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          )
        });
      })
      .catch(function(response) {
        console.log(response);
        const { msg } = response.data;
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message={msg}
              unmountMe={this.handleCloseAlert}
            />
          )
        });
      });
  };

  insertData = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}users/${localStorage.userDealerId}`,
      data: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        emailAddress: this.state.emailAddress,
        password: this.state.password,
        signature: this.state.signature,
        status: this.state.status === true ? 1 : 0,
        idDealer: localStorage.userDealerId
      }
    })
      .then(response => {
        const { msg, success, id } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }

        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
          redirectPage: <Redirect to={`utilities/users/${id}`} />
        });
      })
      .catch(function(response) {
        console.log(response);
        const { msg } = response.data;
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message={msg}
              unmountMe={this.handleCloseAlert}
            />
          )
        });
      });
  };

  submitForm = () => {
    var msgErro = "";
    const { firstName, lastName, emailAddress, password } = this.state;
    if (firstName === "") {
      msgErro += "First Name, ";
    }
    if (lastName === "") {
      msgErro += "Last Name, ";
    }
    if (emailAddress === "") {
      msgErro += "Email Address, ";
    }
    if (password === "") {
      msgErro += "Password, ";
    }

    if (msgErro !== "") {
      msgErro = msgErro.substring(0, msgErro.length - 2);
      this.setState({
        AlertMessage: (
          <AlertMessageBootstrap
            messageType="danger"
            message={`Please fill the required fields: ${msgErro}`}
            unmountMe={this.handleCloseAlert}
          />
        )
      });
      return false;
    }

    if (!isNaN(this.props.match.params.id)) {
      this.updateData();
    } else {
      this.insertData();
    }
  };

  handleData = () => {
    const { id } = this.props.match.params;
    if (!isNaN(id)) {
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}users/${localStorage.userDealerId}/${id}`
      })
        .then(function(response) {
          if (response.data.success === false) {
            self.setState({ handleError: <Redirect to="/404Error" /> });
          }
          const {
            firstName,
            lastName,
            emailAddress,
            password,
            signature,
            status
          } = response.data.data.results[0];

          self.setState({
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress,
            password: password,
            signature: signature,
            status: status === 1 ? true : false
          });
          console.log("Status", status);
        })
        .catch(function(response) {
          console.log(response);
        });
    }
  };

  handleChange = e => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  handleChangeEditor = e => {
    const value = e.target.getContent();
    this.setState({ signature: value });
  };

  handleCheckBox = () => {
    if (this.state.status === true) {
      this.setState({ status: false });
    } else {
      this.setState({ status: true });
    }
  };

  componentDidMount() {
    if (this.props.match.params.id !== "new") this.handleData();
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }

  render() {
    return (
      <div className="br-mainpanel">
        {this.state.handleError}
        {this.state.redirectPage}
        <div className="br-pageheader pd-y-15 pd-l-20">
          <nav className="breadcrumb pd-0 mg-0 tx-12">
            <a className="breadcrumb-item" href="index.html">
              Boat Sync
            </a>
            <span className="breadcrumb-item active">Utilities</span>
            <span className="breadcrumb-item active">Users</span>
          </nav>
        </div>

        <div className="d-flex align-items-center justify-content-between mg-b-30 pd-x-30 pd-t-30">
          <div>
            <h4 className="tx-gray-800 mg-b-5">User</h4>
          </div>

          <div>
            <Link
              to="/utilities/users/"
              className="btn btn-primary btn-oblong tx-11 tx-uppercase tx-mont tx-medium tx-spacing-1 pd-x-30 bd-2"
            >
              Go Back
            </Link>
          </div>
        </div>

        <div className="br-pagebody">
          <div className="br-section-wrapper">
            <div className="table-wrapper">
              <div className="form-layout">
                {this.state.AlertMessage}
                <div className="row mg-b-25">
                  {/* First Name # */}
                  <div className="col-4">
                    <div className="form-group">
                      <label className="form-control-label d-block">
                        First Name <span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        maxLength="50"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                  {/* Last Name # */}
                  <div className="col-4">
                    <div className="form-group">
                      <label className="form-control-label d-block">
                        Last Name <span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="lastName"
                        maxLength="50"
                        value={this.state.lastName}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-4"></div>
                  {/* Email Address # */}
                  <div className="col-4">
                    <div className="form-group">
                      <label className="form-control-label d-block">
                        Email Address <span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="emailAddress"
                        maxLength="255"
                        value={this.state.emailAddress}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>

                  {/* Password # */}
                  <div className="col-4">
                    <div className="form-group">
                      <label className="form-control-label d-block">
                        Password <span className="tx-danger">*</span>
                      </label>
                      <input
                        type="password"
                        id="password"
                        maxLength="255"
                        value={this.state.password}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-4"></div>
                  {/* Signature # */}
                  <div className="col-12">
                    <div className="form-group">
                      <label className="form-control-label d-block">
                        Signature
                      </label>
                      <Editor
                        id="signature"
                        initialValue={this.state.signature}
                        onChange={this.handleChangeEditor}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount"
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | image | bullist numlist outdent indent | removeformat | help"
                        }}
                      />
                    </div>
                  </div>
                  {/* DefaultLocation */}
                  <div className="col-6 col-sm-4">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="status"
                        checked={this.state.status}
                        onChange={this.handleCheckBox}
                      />{" "}
                      Active
                    </div>
                  </div>

                  <div className="col-12">
                    <hr />
                    <input
                      type="submit"
                      className="btn btn-primary mb-4"
                      formAction="submit"
                      onClick={this.submitForm.bind(this)}
                    />

                    {this.state.AlertMessage}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UsersForm;
