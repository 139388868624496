import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import localStorage from "localStorage";

import Logo from "./components/Logo";
import SideMenu from "./components/SideMenu";
import Header from "./components/Header";
import LocationPanel from "./components/utilities/location/LocationPanel";
import LocationForm from "./components/utilities/location/LocationForm";
import ManufacturerPanel from "./components/utilities/manufacturer/ManufacturerPanel";
import ManufacturerForm from "./components/utilities/manufacturer/ManufacturerForm";
import CategoryPanel from "./components/utilities/category/CategoryPanel";
import CategoryForm from "./components/utilities/category/CategoryForm";
import UsersPanel from "./components/utilities/users/UsersPanel";
import UsersForm from "./components/utilities/users/UsersForm";
import BoatPanel from "./components/boats/BoatPanel";
import BoatForm from "./components/boats/BoatForm";
import Footer from "./components/geral/Footer";

class MainControl extends Component {
  constructor(props) {
    super(props);
    this.state = { RedirectPage: "" };
    this.loginAuthentication();
  }

  loginAuthentication() {
    if (localStorage.getItem("userFirstName") === null) {
      window.location.href = "/login";
    }
  }

  render() {
    return (
      <Router>
        <div className="App">
          <Logo />
          <SideMenu />
          <Header />
          <Switch>
            {/*Boat*/}
            <Route
              exact
              path="/admin/boats"
              component={() => (
                <BoatPanel userDetails={this.state.userDetails} />
              )}
            />
            <Route
              path="/admin/boats/:id"
              render={props => (
                <BoatForm {...props} userDetails={this.state.userDetails} />
              )}
            />

            {/*Utilities Location*/}
            <Route
              exact
              path="/utilities/locations"
              component={() => (
                <LocationPanel userDetails={this.state.userDetails} />
              )}
            />
            <Route
              path="/utilities/locations/:id"
              render={props => (
                <LocationForm {...props} userDetails={this.state.userDetails} />
              )}
            />

            {/*Utilities Manufacturer*/}
            <Route
              exact
              path="/utilities/manufacturer"
              component={() => (
                <ManufacturerPanel userDetails={this.state.userDetails} />
              )}
            />
            <Route
              path="/utilities/manufacturer/:id"
              render={props => (
                <ManufacturerForm
                  {...props}
                  userDetails={this.state.userDetails}
                />
              )}
            />
            {/*Utilities Category*/}
            <Route
              exact
              path="/utilities/category"
              component={() => (
                <CategoryPanel userDetails={this.state.userDetails} />
              )}
            />
            <Route
              path="/utilities/category/:id"
              render={props => (
                <CategoryForm {...props} userDetails={this.state.userDetails} />
              )}
            />
            {/*Utilities Users*/}
            <Route
              exact
              path="/utilities/users"
              component={() => (
                <UsersPanel userDetails={this.state.userDetails} />
              )}
            />
            <Route
              path="/utilities/users/:id"
              render={props => (
                <UsersForm {...props} userDetails={this.state.userDetails} />
              )}
            />
          </Switch>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default MainControl;
