import React, { Component } from "react";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";
import _ from "lodash";
import TextHelper from "../../utilities/textHelper";

const axios = require("axios");
require("dotenv").config();

class ServiceHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapse: "",
      AlertMessage: false,
      idTable: 0,
      // Fields
      lastEngineService: "",
      lastAntifouled: "",
      propspeed: "",
      serviceRecords: "",
      surveyorsReport: "",
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }
  handleChangeSelect = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  componentDidMount() {
    if (this.props.idBoat !== "new") this.handleData();
  }

  handleData = () => {
    if (!isNaN(this.props.idBoat)) {
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatServiceHistory/${this.props.idBoat}`,
      })
        .then(function (response) {
          if (response.data.success !== false) {
            const {
              lastEngineService,
              lastAntifouled,
              propspeed,
              serviceRecords,
              surveyorsReport,
              id,
            } = response.data.data.results[0];

            self.setState({
              lastEngineService: lastEngineService,
              lastAntifouled: lastAntifouled,
              propspeed: propspeed,
              serviceRecords: serviceRecords,
              surveyorsReport: surveyorsReport,
              idTable: id,
              showCollapse: "show",
            });
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    }
  };

  submitForm = () => {
    if (this.state.idTable !== 0) {
      this.updateData();
    } else {
      this.insertData();
    }
  };

  updateData = () => {
    var self = this;
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatServiceHistory/${this.props.idBoat}`,
      data: {
        lastEngineService: this.state.lastEngineService,
        lastAntifouled: this.state.lastAntifouled,
        propspeed: this.state.propspeed,
        serviceRecords: this.state.serviceRecords,
        surveyorsReport: this.state.surveyorsReport,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        self.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Erro!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  insertData = () => {
    //console.log("AddMe");
    //console.log(this.state);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatServiceHistory/${this.props.idBoat}`,
      data: {
        lastEngineService: this.state.lastEngineService,
        lastAntifouled: this.state.lastAntifouled,
        propspeed: this.state.propspeed,
        serviceRecords: this.state.serviceRecords,
        surveyorsReport: this.state.surveyorsReport,
        boatId: this.props.idBoat,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success, id } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }

        this.setState({
          idTable: id,
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Error!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  render() {
    if (this.props.idBoat === "new") {
      return null;
    }

    return (
      <div className="accordion mb-4 mt-4" id="accordion">
        <div className="card">
          <div className="card-header" id={`headingServiceHistory`}>
            <h2 className="mb-0">
              <button
                className="btn btn-secondary w-100"
                type="button"
                data-toggle="collapse"
                data-target={`#collapseServiceHistory`}
                aria-expanded="true"
                aria-controls="collapseServiceHistory"
              >
                Service History
              </button>
            </h2>
          </div>

          <div
            id="collapseServiceHistory"
            className={`collapse ${this.state.showCollapse}`}
            aria-labelledby="headingServiceHistory"
            data-parent="#accordion"
          >
            <div className="card-body mt-4">
              <div className="row mg-b-25">
                {/* Last Engine Service # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Last Engine Service
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (
                            o.fieldId === "servicehistory.lastengineservice"
                          ) {
                            return o;
                          }
                        })}
                        fieldId="servicehistory.lastengineservice"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="lastEngineService"
                      className="w-100"
                      maxLength="500"
                      value={this.state.lastEngineService}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Last Anti Fouled # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Last Anti Fouled
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "servicehistory.lastantifouled") {
                            return o;
                          }
                        })}
                        fieldId="servicehistory.lastantifouled"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="lastAntifouled"
                      className="w-100"
                      maxLength="500"
                      value={this.state.lastAntifouled}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/*Prop Speed# */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Prop Speed
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "servicehistory.propspeed") {
                            return o;
                          }
                        })}
                        fieldId="servicehistory.propspeed"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="propspeed"
                      className="w-100"
                      maxLength="500"
                      value={this.state.propspeed}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Service Records # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Service Records
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "servicehistory.servicerecords") {
                            return o;
                          }
                        })}
                        fieldId="servicehistory.servicerecords"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="serviceRecords"
                      className="w-100"
                      maxLength="500"
                      value={this.state.serviceRecords}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Surveyors Report # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Surveyors Report
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "servicehistory.surveyorsreport") {
                            return o;
                          }
                        })}
                        fieldId="servicehistory.surveyorsreport"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="surveyorsReport"
                      className="w-100"
                      maxLength="500"
                      value={this.state.surveyorsReport}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <input
                  type="submit"
                  className="btn btn-primary mb-4"
                  formAction="submit"
                  value="Submit Service History"
                  onClick={this.submitForm.bind(this)}
                />
                {this.state.AlertMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceHistory;
