import React, { Component } from "react";
import AlertMessageBootstrap from "../../geral/AlertMessageBootstrap";
import _ from "lodash";
import TextHelper from "../../utilities/textHelper";

const axios = require("axios");
require("dotenv").config();

class Awning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapse: "",
      AlertMessage: false,
      idTable: 0,
      // Fields
      biminiOrHardtop: "",
      sideCurtainClears: "",
      windowCovers: "",
      insectScreens: "",
      curtainsOrBlinds: "",
      otherAwningsCovers: "",
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }
  handleChangeSelect = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: value });
  };

  componentDidMount() {
    if (this.props.idBoat !== "new") this.handleData();
  }

  handleData = () => {
    if (!isNaN(this.props.idBoat)) {
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatAwning/${this.props.idBoat}`,
      })
        .then(function (response) {
          if (response.data.success !== false) {
            const {
              biminiOrHardtop,
              sideCurtainClears,
              windowCovers,
              insectScreens,
              curtainsOrBlinds,
              otherAwningsCovers,
              id,
            } = response.data.data.results[0];

            self.setState({
              biminiOrHardtop: biminiOrHardtop,
              sideCurtainClears: sideCurtainClears,
              windowCovers: windowCovers,
              insectScreens: insectScreens,
              curtainsOrBlinds: curtainsOrBlinds,
              otherAwningsCovers: otherAwningsCovers,
              idTable: id,
              showCollapse: "show",
            });
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    }
  };

  submitForm = () => {
    if (this.state.idTable !== 0) {
      this.updateData();
    } else {
      this.insertData();
    }
  };

  updateData = () => {
    var self = this;
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatAwning/${this.props.idBoat}`,
      data: {
        biminiOrHardtop: this.state.biminiOrHardtop,
        sideCurtainClears: this.state.sideCurtainClears,
        windowCovers: this.state.windowCovers,
        insectScreens: this.state.insectScreens,
        curtainsOrBlinds: this.state.curtainsOrBlinds,
        otherAwningsCovers: this.state.otherAwningsCovers,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }
        self.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Erro!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  insertData = () => {
    //console.log("AddMe");
    //console.log(this.state);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}boatDetails/tbName/boatAwning/${this.props.idBoat}`,
      data: {
        biminiOrHardtop: this.state.biminiOrHardtop,
        sideCurtainClears: this.state.sideCurtainClears,
        windowCovers: this.state.windowCovers,
        insectScreens: this.state.insectScreens,
        curtainsOrBlinds: this.state.curtainsOrBlinds,
        otherAwningsCovers: this.state.otherAwningsCovers,
        boatId: this.props.idBoat,
        userUpdated: localStorage.userId,
        dtUpdated: new Date(),
      },
    })
      .then((response) => {
        const { msg, success, id } = response.data;
        var messageType = "success";
        var messageDetail = msg;
        if (success === false) {
          messageType = "danger";
        }

        this.setState({
          idTable: id,
          AlertMessage: (
            <AlertMessageBootstrap
              messageType={messageType}
              message={messageDetail}
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          AlertMessage: (
            <AlertMessageBootstrap
              messageType="danger"
              message="Error!"
              unmountMe={this.handleCloseAlert}
            />
          ),
        });
      });
  };

  render() {
    if (this.props.idBoat === "new") {
      return null;
    }

    return (
      <div className="accordion mb-4 mt-4" id="accordion">
        <div className="card">
          <div className="card-header" id={`headingAwning`}>
            <h2 className="mb-0">
              <button
                className="btn btn-secondary w-100"
                type="button"
                data-toggle="collapse"
                data-target={`#collapseAwning`}
                aria-expanded="true"
                aria-controls="collapseAwning"
              >
                Awnings / Covers
              </button>
            </h2>
          </div>

          <div
            id="collapseAwning"
            className={`collapse ${this.state.showCollapse}`}
            aria-labelledby="headingAwning"
            data-parent="#accordion"
          >
            <div className="card-body mt-4">
              <div className="row mg-b-25">
                {/* Bimini or Hardtop # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Bimini or Hardtop
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "awning.bimini") {
                            return o;
                          }
                        })}
                        fieldId="awning.bimini"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="biminiOrHardtop"
                      className="w-100"
                      maxLength="500"
                      value={this.state.biminiOrHardtop}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Side Curtain Clears # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Side Curtain Clears
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "awning.sidecurtain") {
                            return o;
                          }
                        })}
                        fieldId="awning.sidecurtain"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="sideCurtainClears"
                      className="w-100"
                      maxLength="500"
                      value={this.state.sideCurtainClears}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/*Window Covers# */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Window Covers
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "awning.windowcovers") {
                            return o;
                          }
                        })}
                        fieldId="awning.windowcovers"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="windowCovers"
                      className="w-100"
                      maxLength="500"
                      value={this.state.windowCovers}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Insect Screens # */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Insect Screens
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "awning.insectscreens") {
                            return o;
                          }
                        })}
                        fieldId="awning.insectscreens"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="insectScreens"
                      className="w-100"
                      maxLength="500"
                      value={this.state.insectScreens}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Curtains or Blinds# */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Curtains or Blinds
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "awning.curtains") {
                            return o;
                          }
                        })}
                        fieldId="awning.curtains"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="curtainsOrBlinds"
                      className="w-100"
                      maxLength="500"
                      value={this.state.curtainsOrBlinds}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* Other */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-control-label d-block">
                      Other
                      <TextHelper
                        data={_.filter(this.props.tooltipData, function (o) {
                          if (o.fieldId === "awning.other") {
                            return o;
                          }
                        })}
                        fieldId="awning.other"
                        updateTooltip={this.props.updateTooltip}
                      />
                    </label>
                    <input
                      type="text"
                      id="otherAwningsCovers"
                      className="w-100"
                      maxLength="500"
                      value={this.state.otherAwningsCovers}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <input
                  type="submit"
                  className="btn btn-primary mb-4"
                  formAction="submit"
                  value="Submit Awnings / Covers"
                  onClick={this.submitForm.bind(this)}
                />
                {this.state.AlertMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Awning;
