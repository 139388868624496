import React from "react";
import Trix from "trix";

class Wysiwyg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      propscontent: this.props.mycontent,
    };
    this.trixInput = React.createRef();
  }

  nono() {
    return <Trix />;
  }

  componentDidMount() {
    this.trixInput.current.addEventListener("trix-change", (event) => {
      console.log("trix change event fired");
      console.log(event.target.innerHTML);
      this.props.handleChangeHTMLEditor(event.target.innerHTML);
    });
  }

  render() {
    return (
      <form>
        <input
          type="hidden"
          id="desc-boat"
          name="desc-boat"
          value={this.props.mycontent}
        />
        <trix-editor
          input="desc-boat"
          class="trix-content"
          ref={this.trixInput}
        ></trix-editor>
      </form>
    );
  }
}

export default Wysiwyg;
